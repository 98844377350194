import { TextInput, Select, Option } from '@contentful/forma-36-react-components';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import * as React from 'react';
import { useState, useEffect } from 'react';
import useConstant from 'use-constant';
import { Flex, Text } from 'rebass';

export type SearchableContentType = 'all' | 'journalSummary' | 'newsArticle' | 'longFormContent';
type SearchableColumn = 'contentId' | 'title';
export type SearchCondition = {
  searchTerm: string;
  contentType: SearchableContentType;
  searchColumn: SearchableColumn;
};

type SearchFieldParams = {
  onSubmit: (condition: SearchCondition) => void;
  debounceMillis?: number;
};

const defaultDebounceMillis = 500;

export const SearchField = ({ onSubmit, debounceMillis = defaultDebounceMillis }: SearchFieldParams) => {
  const [value, setValue] = useState('');
  const [searchColumnValue, setSearchColumnValue] = useState<SearchableColumn>('contentId');
  const [contentTypeValue, setContentTypeValue] = useState<SearchableContentType>('all');

  const debouncedOnSubmit = useConstant(() => AwesomeDebouncePromise(onSubmit, debounceMillis));

  useEffect(() => {
    debouncedOnSubmit({ searchTerm: value, contentType: contentTypeValue, searchColumn: searchColumnValue });
  }, [value, searchColumnValue, contentTypeValue, debouncedOnSubmit]);

  return (
    <Flex alignItems="center" mb="1rem">
      <TextInput
        style={{ marginRight: '1rem' }}
        id="search"
        name="search"
        width="large"
        placeholder={`Enter search term here`}
        value={value}
        onChange={e => setValue((e.target as any).value)}
      />
      <Flex alignItems="center">
        <Text mr="1rem">Search Column:</Text>
        <Select
          name="searchField"
          width="medium"
          id="searchField"
          onChange={e => setSearchColumnValue((e.target as any).value)}
        >
          <Option value="contentId">Content ID</Option>
          <Option value="title">Title</Option>
        </Select>
      </Flex>
      <Flex alignItems="center" ml="auto">
        <Text mr="1rem">Filter by Content Type:</Text>
        <Select
          name="contentTypeSelect"
          width="medium"
          id="contentTypeSelect"
          onChange={e => setContentTypeValue((e.target as any).value)}
        >
          <Option value="all">All</Option>
          <Option value="journalSummary">Journal Summary</Option>
          <Option value="longFormContent">Long Form Content</Option>
          <Option value="newsArticle">News Article</Option>
        </Select>
      </Flex>
    </Flex>
  );
};
