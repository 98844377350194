import { hasPendingChanges } from 'app/shared/entries';
import React, { useEffect, useState } from 'react';
import { useContentfulUIExtensionSDK } from 'lib/contentful-extension';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import {
  Note,
  Button,
  HelpText,
  Dropdown,
  DropdownList,
  DropdownListItem,
} from '@contentful/forma-36-react-components';
import { Box, Text, Flex } from 'rebass';
import '@contentful/forma-36-react-components/dist/styles.css';
import { DateTime } from 'luxon';
import { ContentType, SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';

export const TaxonomistPublishButton = () => {
  const sdkState = useContentfulUIExtensionSDK();
  const errorReporter = useErrorReporter();
  const [sys, setSys] = useState<ContentType['sys']>();
  const [isPublished, setIsPublished] = useState(null as null | boolean);
  const [isPending, setIsPending] = useState(null as null | boolean);
  const [isDropDownOpen, toggleDropDown] = useState(false);

  useEffect(() => {
    if (!sdkState.loading) {
      const sdk = sdkState.sdk as SidebarExtensionSDK;
      const sys = sdk.entry.getSys() as ContentType['sys'];
      setSys(sys);

      const unsubscribe = sdk.entry.onSysChanged((sysChanged: any) => {
        sdk.space.getPublishedEntries({ 'sys.id[in]': sys.id }).then(result => {
          setIsPublished(result.total > 0);
        });

        setIsPending(hasPendingChanges(sysChanged));
      });

      return () => unsubscribe();
    }
    return;
  }, [sdkState.loading]);

  if (!sys || sdkState.loading) {
    return null;
  }

  if (sdkState.type !== 'SIDEBAR') {
    return <Note noteType="negative">This UI Extension is only for &quot;sidebar&quot;.</Note>;
  }

  const handleClickPublish = () => {
    sdkState.sdk.space.getEntry(sys.id).then(entry => {
      sdkState.sdk.space
        .publishEntry(entry)
        .then(() => {
          sdkState.sdk.notifier.success('Published');
          setIsPending(false);
        })
        .catch(error => {
          sdkState.sdk.notifier.error(`${error.message} ${error.code}`);
          errorReporter.report(error);
        });
    });
  };

  const handleClickUnpublish = () => {
    sdkState.sdk.space.getEntry(sys.id).then(entry => {
      sdkState.sdk.space
        .unpublishEntry(entry)
        .then(() => {
          sdkState.sdk.notifier.success('Unpublished');
          setIsPending(false);
        })
        .catch(error => {
          sdkState.sdk.notifier.error(`${error.message} ${error.code}`);
          errorReporter.report(error);
        });
    });
  };

  return (
    <Box css={{ zIndex: 100 }}>
      <StatusIndicator published={isPublished} pending={isPending} />
      {isPublished && !isPending ? (
        <Button isFullWidth buttonType="negative" onClick={handleClickUnpublish}>
          Unpublish
        </Button>
      ) : (
        <Flex>
          <Button
            style={{ width: '100%', borderRightWidth: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            buttonType="positive"
            onClick={handleClickPublish}
          >
            Publish {isPending && 'changes'}
          </Button>
          {isPending && (
            <Dropdown
              isOpen={isDropDownOpen}
              onClose={() => toggleDropDown(false)}
              toggleElement={
                <Button
                  style={{
                    height: '2.5rem',
                    width: '100%',
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  buttonType="positive"
                  size="small"
                  indicateDropdown
                  onClick={() => toggleDropDown(!isDropDownOpen)}
                />
              }
            >
              <DropdownList border="bottom">
                <DropdownListItem isTitle>Change Status To</DropdownListItem>
                <DropdownListItem onClick={handleClickUnpublish}>Unpublish</DropdownListItem>
              </DropdownList>
            </Dropdown>
          )}
        </Flex>
      )}
      <Box mt="10px">
        <HelpText>
          Last saved {DateTime.fromISO(sys.updatedAt!).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
        </HelpText>
      </Box>
    </Box>
  );
};

const StatusIndicator: React.FC<{ published: null | boolean; pending: null | boolean }> = ({ published, pending }) => {
  if (published === null) {
    return null;
  }

  const indicatorColor = pending ? '#5b9fef' : published ? '#0eb87f' : '#ea9005';
  return (
    <Box mb="0.25rem" css={{ verticalAlign: 'baseline' }}>
      <Box
        height="14px"
        width="14px"
        bg={indicatorColor}
        mr="0.4rem"
        mb="3px"
        css={{
          borderRadius: '50%',
          border: `1px solid ${indicatorColor}`,
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      />
      <Text
        as="span"
        fontSize="0.875rem"
        fontWeight={700}
        fontFamily="var(--font-stack-primary)"
        css={{ verticalAlign: 'baseline' }}
      >
        Status:
      </Text>
      <Text as="span" fontWeight={400} fontSize="0.875rem" css={{ verticalAlign: 'baseline' }}>
        {published ? ' Published' : ' Draft'} {pending && '(pending changes)'}
      </Text>
    </Box>
  );
};
