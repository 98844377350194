import { Button, IconButton, Modal, TextLink } from '@contentful/forma-36-react-components';
import {
  entryCrawlDate,
  orderByCrawlDate,
  orderByCrawlDateReverse,
} from 'app/scenes/ui-extension/NewsSelection/common';
import { useNewsArticles } from 'app/scenes/ui-extension/NewsSelection/news-articles';
import { SortIcon } from 'app/shared/SortIcon';
import { Source } from 'app/shared/Source';
import { useSourceCandidates } from 'app/shared/source-candidates';
import { useSources } from 'app/shared/sources';
import { SourceSelector } from 'app/shared/SourceSelector';
import { TooltipSwitch } from 'app/shared/TooltipSwitch';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import { isPublished } from 'lib/entries';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { Pager } from '../../../shared/Pager';
import { EntryPreview } from './EntryPreview';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';

export const RecycleBin: React.FC = () => {
  const sources = useSources();
  const sdk = usePageExtensionSDK();
  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const { selectedSourceLabel, setSelectedSourceLabel, targetQuery } = useSourceCandidates(sources.all);
  const [previewEntryId, setPreviewEntryId] = useState<string | undefined>(undefined);
  const query = useMemo(
    () => ({
      'fields.workflowStatus': 'Reviewed - Not Selected',
      order,
      ...targetQuery,
    }),
    [order, targetQuery],
  );

  const {
    entries,
    totalPage,
    currentPage,
    setCurrentPage,
    isUpdating,
    isEntryUpdating,
    undelete,
    togglePriority,
    releaseToEditorial,
    deleteEntirely,
    reject,
  } = useNewsArticles(query);

  const previewEntry = useMemo(() => entries.find(e => e.sys.id === previewEntryId), [previewEntryId, entries]);

  return (
    <>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          {
            header: 'Title',
            value: ({ entry }) => (entry.fields.title ? entry.fields.title['en-US'] : ''),
          },
          {
            header: 'Preview Article',
            value: ({ entry }) => (
              <IconButton
                label={'Preview'}
                iconProps={{ icon: 'Entry' }}
                buttonType={'secondary'}
                onClick={() => setPreviewEntryId(entry.sys.id)}
              />
            ),
          },
          {
            header: (
              <SourceSelector
                value={selectedSourceLabel}
                onChange={value => {
                  setSelectedSourceLabel(value);
                }}
              />
            ),
            value: ({ entry }) => (entry.fields.source ? <Source id={entry.fields.source['en-US'].sys.id} /> : ''),
          },
          {
            header: (
              <>
                Crawl Date
                {order === orderByCrawlDate ? (
                  <SortIcon order={'asc'} />
                ) : order === orderByCrawlDateReverse ? (
                  <SortIcon order={'desc'} />
                ) : null}
              </>
            ),
            value: ({ entry }) => entryCrawlDate(entry),
            onClick() {
              if (order === orderByCrawlDateReverse) {
                setOrder(orderByCrawlDate);
              } else {
                setOrder(orderByCrawlDateReverse);
              }
            },
          },
          {
            header: 'Un-delete',
            value: ({ entry }) => (
              <Button
                buttonType={'positive'}
                onClick={() => undelete([entry.sys.id])}
                size={'small'}
                disabled={isEntryUpdating(entry.sys.id)}
              >
                Un-Delete
              </Button>
            ),
          },
          {
            header: 'Delete',
            value: ({ entry }) => (
              <TooltipSwitch show={isPublished(entry)} content={'Cannot delete published entries'}>
                <Button
                  buttonType={'negative'}
                  onClick={() => {
                    sdk.dialogs
                      .openConfirm({
                        intent: 'negative',
                        title: 'Delete News Article',
                        message: `Are you sure you want to delete "${
                          entry.fields.title ? entry.fields.title['en-US'] : '(no-title)?'
                        }" ? (this operation cannot be undone)`,
                        confirmLabel: `Yes, I'm sure.`,
                      })
                      .then(confirmed => {
                        if (confirmed) {
                          deleteEntirely([entry.sys.id]);
                        }
                      });
                  }}
                  size={'small'}
                  disabled={!!entry.sys.publishedAt || isEntryUpdating(entry.sys.id)}
                >
                  Delete
                </Button>
              </TooltipSwitch>
            ),
          },
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} article selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'positive'}
                onClick={() => undelete(selectedEntries.map(e => e.sys.id))}
                disabled={isUpdating}
              >
                Un-Delete
              </TextLink>
            </Box>
            <Box mx={1}>
              <TooltipSwitch show={entries.some(isPublished)} content={'Cannot delete published entries'}>
                <TextLink
                  linkType={'negative'}
                  onClick={() => {
                    sdk.dialogs
                      .openConfirm({
                        intent: 'negative',
                        title: 'Delete News Article',
                        message: `Are you sure you want to delete these ${selectedEntries.length} entries? (this operation cannot be undone)`,
                        confirmLabel: `Yes, I'm sure.`,
                      })
                      .then(confirmed => {
                        if (confirmed) {
                          deleteEntirely(selectedEntries.map(e => e.sys.id));
                        }
                      });
                  }}
                  disabled={entries.some(isPublished) || isUpdating}
                >
                  Delete
                </TextLink>
              </TooltipSwitch>
            </Box>
          </Flex>
        )}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
      <Modal
        title={`Preview: ${previewEntry && previewEntry.fields.title ? previewEntry.fields.title['en-US'] : ''}`}
        isShown={!!previewEntry}
        onClose={() => setPreviewEntryId(undefined)}
        size={'fullWidth'}
        shouldCloseOnEscapePress={true}
        shouldCloseOnOverlayClick={true}
      >
        {previewEntry ? (
          <EntryPreview
            entry={previewEntry}
            onTogglePriority={() => togglePriority(previewEntry.sys.id)}
            onReleaseToEditorial={() => releaseToEditorial([previewEntry.sys.id])}
            onReject={() => reject([previewEntry.sys.id])}
            updating={isEntryUpdating(previewEntry.sys.id)}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
