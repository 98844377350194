import { IconButton } from '@contentful/forma-36-react-components';
import { entryEditPageUrl, useContentfulUIExtensionSDK } from 'lib/contentful-extension';
import { openWindowInNewTab } from 'lib/window';
import React from 'react';

export const OpenInEditorIconButton: React.FC<{ entryId: string }> = ({ entryId }) => {
  const sdkState = useContentfulUIExtensionSDK();
  if (sdkState.loading) {
    return null;
  }
  const sdk = sdkState.sdk;

  return (
    <IconButton
      label={'Edit'}
      iconProps={{
        icon: 'ExternalLink',
      }}
      buttonType={'secondary'}
      onClick={() => {
        openWindowInNewTab(entryEditPageUrl(sdk.ids.space, sdk.ids.environment, entryId));
      }}
    />
  );
};
