import { Button } from '@contentful/forma-36-react-components';
import { AssigneeName } from 'app/scenes/ui-extension/JournalSummarySelection/AssigneeName';
import { SpecialtyLabel } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtyLabel';
import {
  SpecialtySelection,
  SpecialtySelector,
} from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtySelector';
import { ColumnDefinition } from 'app/shared/EntryTable';
import { OpenInEditorIconButton } from 'app/shared/OpenInEditorIconButton';
import { OpenOnMdlinxIconButton } from 'app/shared/OpenOnMdlinxIconButton';
import { PrimaryTopicTag } from 'app/shared/PrimaryTopicTag';
import { SortIcon } from 'app/shared/SortIcon';
import { Source } from 'app/shared/Source';
import { TooltipSwitch } from 'app/shared/TooltipSwitch';
import { ContentfulEntry } from 'contentful-management';
import { isPublished } from 'lib/entries';
import { SpecialtyDef } from 'mdlinx-contentful';
import React from 'react';
import {
  entryPublishDate,
  journalSummaryCrawlDate,
  JournalSummaryEntry,
  orderByCrawlDate,
  orderByCrawlDateReverse,
  SortOrder,
} from './common';
import { CurrentListsLabel } from './CurrentListsLabel';
import { ScimagoJournalRank } from './ScimagoJournalRank';

type SortColumnHandler<T> = {
  order: T;
  onChange: (order: T) => void;
};

export const titleColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Title',
  value: ({ entry }) => entry.fields.title?.['en-US'],
};

export function specialtyColumn({
  candidates,
  selection,
  onChange,
  singleSelectionOnly = false,
}: {
  selection: SpecialtySelection;
  onChange: (specialtySelection: SpecialtySelection) => void;
  candidates: ContentfulEntry<SpecialtyDef>[];
  singleSelectionOnly?: boolean;
}): ColumnDefinition<JournalSummaryEntry> {
  return {
    header: (
      <SpecialtySelector
        value={selection}
        onChange={onChange}
        candidates={candidates}
        singleSelectionOnly={singleSelectionOnly}
      />
    ),
    value: ({ entry }) => (
      <>
        {(entry.fields.specialties?.['en-US'] ?? []).map((link, index) => (
          <>
            {index !== 0 ? ', ' : ''}
            <SpecialtyLabel key={link.sys.id} id={link.sys.id} />
          </>
        ))}
      </>
    ),
  };
}

export const workflowStatusColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Workflow Status',
  value: ({ entry }) => entry.fields.workflowStatus?.['en-US'] ?? '-',
};

export const assigneeColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Assignee',
  value: ({ entry }) =>
    entry.fields.assigneeUserId ? <AssigneeName uid={entry.fields.assigneeUserId['en-US']} /> : '',
};

export const sourceColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Source',
  value: ({ entry }) => (entry.fields.source ? <Source id={entry.fields.source['en-US'].sys.id} /> : 'Unknown'),
};

export const sjrColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'SJR',
  value: ({ entry }) =>
    entry.fields.source ? <ScimagoJournalRank id={entry.fields.source['en-US'].sys.id} /> : 'Unknown',
};

export function sortableSjrColumn({
  order,
  onChange,
}: SortColumnHandler<SortOrder>): ColumnDefinition<JournalSummaryEntry> {
  return {
    header: (
      <>
        SJR
        <SortIcon order={order} />
      </>
    ),
    onClick: () => {
      if (order === 'desc') {
        onChange('asc');
      } else {
        onChange('desc');
      }
    },
    value: ({ entry }) =>
      entry.fields.source ? <ScimagoJournalRank id={entry.fields.source['en-US'].sys.id} /> : 'Unknown',
  };
}

export function crawlDateColumn({ order, onChange }: SortColumnHandler<string>): ColumnDefinition<JournalSummaryEntry> {
  return {
    header: (
      <>
        Crawl Date
        {order === orderByCrawlDate ? (
          <SortIcon order={'asc'} />
        ) : order === orderByCrawlDateReverse ? (
          <SortIcon order={'desc'} />
        ) : null}
      </>
    ),
    onClick: () => {
      if (order === orderByCrawlDateReverse) {
        onChange(orderByCrawlDate);
      } else {
        onChange(orderByCrawlDateReverse);
      }
    },
    value: ({ entry }) => journalSummaryCrawlDate(entry),
  };
}

export const openInEditorColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Open in Editor',
  value: ({ entry }) => <OpenInEditorIconButton entryId={entry.sys.id} />,
};

export const openOnMdlinxColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'View on MDLinx',
  value: ({ entry }) => <OpenOnMdlinxIconButton entryId={entry.sys.id} />,
};

export const viewAbstractColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'View Abstract',
  value: ({ entry }) => <OpenInEditorIconButton entryId={entry.sys.id} />,
};

export function unDeleteColumn({
  onClick,
  isEntryUpdating,
}: {
  onClick: (entry: JournalSummaryEntry) => void;
  isEntryUpdating: (entryId: string) => boolean;
}): ColumnDefinition<JournalSummaryEntry> {
  return {
    header: 'Un-delete',
    value: ({ entry }) => (
      <TooltipSwitch show={isPublished(entry)} content={'Cannot delete published entries'}>
        <Button
          buttonType={'muted'}
          onClick={() => onClick(entry)}
          size={'small'}
          disabled={isPublished(entry) || isEntryUpdating(entry.sys.id)}
        >
          Un-Delete
        </Button>
      </TooltipSwitch>
    ),
  };
}

export const publishDateColumn: ColumnDefinition<ContentfulEntry> = {
  header: 'Publish Date',
  value: ({ entry }) => entryPublishDate(entry),
};

export const primaryTopicTagColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Primary Topic Tag',
  value: ({ entry }) =>
    entry.fields.primaryTopicTag ? <PrimaryTopicTag id={entry.fields.primaryTopicTag['en-US'].sys.id} /> : 'Unknown',
};

export const currentListsColumn: ColumnDefinition<JournalSummaryEntry> = {
  header: 'Current Lists',
  value: ({ entry }) => <CurrentListsLabel entryId={entry.sys.id} />,
};
