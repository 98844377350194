import {
  Button,
  Dropdown,
  DropdownList,
  DropdownListItem,
  HelpText,
  TextLink,
  ValidationMessage,
} from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import { hasPendingChanges } from 'app/shared/entries';
import { findAllPublishedSpecialties } from 'app/shared/specialties';
import { StatusIndicator } from 'app/shared/StatusIndicator';
import { ContentfulEntry } from 'contentful-management';
import { SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import {
  CustomStaffRole,
  useBeginSummaryMutation,
  useSubmitSummaryMutation,
  useCancelSummaryMutation,
} from 'generated/graphql-client-query';
import { Me, useMe } from 'lib/auth';
import { useEntryField, useSidebarExtensionSDK } from 'lib/contentful-extension';
import { DateTime } from 'luxon';
import { JournalSummaryDef, JournalSummaryFields, Link, SpecialtyDef, EnUS, EnIN, RichText } from 'mdlinx-contentful';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';

const lastSaveTimeUpdateInterval = 60 * 1000;

function useAllSpecialties(): ContentfulEntry<SpecialtyDef>[] {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const [specialties, setSpecialties] = useState<ContentfulEntry<SpecialtyDef>[]>([]);
  useEffect(() => {
    findAllPublishedSpecialties(sdk).then(setSpecialties, e => {
      errorReporter.report(e);
      sdk.notifier.error('Failed to get specialty list. Workflow button may not work correctly.');
    });
  }, [sdk, setSpecialties, errorReporter]);

  return specialties;
}

function useAutoResizer(): void {
  const sdk = useSidebarExtensionSDK();
  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);
}

function useNeedsExtraReview(): boolean {
  const sdk = useSidebarExtensionSDK();

  const specialties: Link[] = sdk.entry.fields.specialties.getValue() || [];
  const [specialtyIds, setSpecialtyIds] = useState<string[]>(specialties.map(s => s.sys.id));
  const allSpecialties = useAllSpecialties();

  useEffect(() => {
    const cleanup = (sdk.entry.fields.specialties.onValueChanged as any)((specialties: Link[] | undefined) => {
      setSpecialtyIds((specialties || []).map(s => s.sys.id));
    });
    return () => cleanup();
  }, [sdk, setSpecialtyIds]);

  return useMemo(() => {
    const needsExtraReviewById = new Map(
      allSpecialties.map(sp => [sp.sys.id, sp.fields.needsExtraReview ? sp.fields.needsExtraReview['en-US'] : false]),
    );
    return specialtyIds.some(specialtyId => needsExtraReviewById.get(specialtyId) || false);
  }, [specialtyIds, allSpecialties]);
}

function useWorkflowStatus(): JournalSummaryFields['workflowStatus']['en-US'] {
  const sdk = useSidebarExtensionSDK();
  const [workflowStatus, setWorkflowStatus] = useState(sdk.entry.fields.workflowStatus.getValue());
  useEffect(() => {
    const clear = (sdk.entry.fields.workflowStatus.onValueChanged as any)((value: string) => {
      setWorkflowStatus(value);
    });
    return () => clear();
  }, [sdk, setWorkflowStatus]);
  return workflowStatus || 'Crawler Output';
}

function usePublished(): boolean {
  const sdk = useSidebarExtensionSDK();
  const [published, setPublished] = useState(!!(sdk.entry.getSys() as any).publishedAt);
  useEffect(() => {
    const cleanup = sdk.entry.onSysChanged(sys => {
      setPublished(!!(sys as any).publishedAt);
    });
    return () => cleanup();
  }, [sdk, setPublished]);
  return published;
}

function isPermitted(me: Me, expectedRole: CustomStaffRole): boolean {
  return expectedRole === CustomStaffRole.Admin
    ? me.customRole === CustomStaffRole.Admin
    : [CustomStaffRole.Admin, CustomStaffRole.Editor, expectedRole].includes(me.customRole);
}

type JournalSummaryUpdatableFields = {
  displayTitleMarkdown?: EnUS<string> | EnIN<string>;
  assigneeUserId?: EnUS<string>;
  workflowStatus?: EnUS<
    | 'Crawler Output'
    | 'Selected for Editorial'
    | 'In Progress'
    | 'Ready for Review'
    | 'Review in Progress'
    | 'Ready to Publish'
    | 'Reviewed - Not Selected'
  >;
  authors?: EnUS<string> | EnIN<string>;
  doi?: EnUS<string> | EnIN<string>;
  otherTopicTags?: EnUS<Link[]> | EnIN<Link[]>;
  specialties?: EnUS<Link[]> | EnIN<Link[]>;
  subSpecialties?: EnUS<Link[]> | EnIN<Link[]>;
  body?: EnUS<RichText> | EnIN<RichText>;
  title?: EnUS<string> | EnIN<string>;
};

async function updateEntry(
  sdk: SidebarExtensionSDK,
  attributes: JournalSummaryUpdatableFields,
): Promise<ContentfulEntry<JournalSummaryDef>> {
  const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
  const newEntryData = {
    ...entry,
    fields: {
      ...entry.fields,
      ...attributes,
    },
  };
  const updated = await sdk.space.updateEntry(newEntryData);
  return updated as ContentfulEntry<JournalSummaryDef>;
}

const journalSummaryTranslatableFields = [
  'displayTitleMarkdown',
  'authors',
  'doi',
  'otherTopicTags',
  'specialties',
  'subSpecialties',
  'body',
  'title',
];

function copyTranslatableFields(entry: ContentfulEntry<any>, fromLocale: 'en-US' | 'en-IN') {
  let fields: any = {};
  const toLocale = fromLocale === 'en-US' ? 'en-IN' : 'en-US';
  for (const field of journalSummaryTranslatableFields) {
    if (entry.fields[field]?.[fromLocale] !== undefined) {
      fields[field] = {};
      fields[field][toLocale] = entry.fields[field][fromLocale];
      fields[field][fromLocale] = entry.fields[field][fromLocale];
    }
  }
  return fields;
}

export const JournalSummaryWorkflowButton: React.FC = () => {
  useAutoResizer();
  const workflowStatus = useWorkflowStatus();
  const published = usePublished();
  const needsExtraReview = useNeedsExtraReview();

  if (published) {
    return <ReadyToPublish />;
  }

  switch (workflowStatus) {
    case 'Selected for Editorial':
      return <SelectedForEditorial />;
    case 'In Progress':
      return <InProgress needsExtraReview={needsExtraReview} />;
    case 'Ready for Review':
      return <ReadyForReview />;
    case 'Review in Progress':
      return <ReviewInProgress />;

    // Entries in this state should be published. If not, fallback to Review In Progress.
    case 'Ready to Publish':
      return <ReviewInProgress />;

    default:
      return <CrawlerOutput />;
  }
};

const CrawlerOutput: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();

  const [specialties] = useEntryField<Link[]>(sdk, 'specialties');
  const [primaryTopicTag] = useEntryField<Link>(sdk, 'primaryTopicTag');

  const validationMessage = useMemo(() => {
    const invalidFields = [
      ...((specialties?.length ?? 0) > 0 ? [] : ['Specialties']),
      ...(primaryTopicTag ? [] : ['Primary Topic Tag']),
    ];
    return invalidFields.length === 0 ? undefined : `${invalidFields.join(', ')} is required.`;
  }, [specialties, primaryTopicTag]);

  const releaseToEditorial = async () => {
    try {
      const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
      const fields = copyTranslatableFields(entry, 'en-US');
      await updateEntry(sdk, {
        workflowStatus: { 'en-US': 'Selected for Editorial' },
        ...fields,
      });
      sdk.notifier.success('Selected this entry for Editorial successfully.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to change workflow status.');
    }
  };

  return (
    <WorkflowButton
      label={'Release to Editorial'}
      statusText={'New'}
      statusColor={'warning'}
      expectedRole={CustomStaffRole.Editor}
      onClick={releaseToEditorial}
      validationMessage={validationMessage}
    />
  );
};

const SelectedForEditorial: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const [beginSummaryMutation] = useBeginSummaryMutation();
  const errorReporter = useErrorReporter();

  const beginSummary = async () => {
    try {
      const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
      const beginSummaryOptions = {
        variables: {
          contentId: entry.sys.id,
          assigneeUserId: sdk.user.sys.id,
        },
      };
      await beginSummaryMutation(beginSummaryOptions);
      sdk.notifier.success('Began summary.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to change workflow status.');
    }
  };

  return (
    <WorkflowButton
      label={'Begin Summary'}
      statusText={'Summary not Started'}
      statusColor={'warning'}
      expectedRole={CustomStaffRole.IndiaTeamWriter}
      onClick={beginSummary}
    />
  );
};

function useInProgressRequiredFieldValidation(
  sdk: SidebarExtensionSDK,
): { valid: true } | { valid: false; message: string } {
  const [authors] = useEntryField<string>(sdk, 'authors', 'en-IN');
  const [doi] = useEntryField<string>(sdk, 'doi', 'en-IN');
  const [subSpecialties] = useEntryField<Link[]>(sdk, 'subSpecialties', 'en-IN');
  const [body] = useEntryField<string>(sdk, 'body', 'en-IN');

  return useMemo(() => {
    const invalidFields = [
      authors ? [] : ['Authors'],
      doi ? [] : ['DOI'],
      (subSpecialties?.length ?? 0) > 0 ? [] : ['Sub Specialties'],
      body ? [] : ['Summary'],
    ].flat();
    return invalidFields.length === 0
      ? { valid: true }
      : { valid: false, message: `${invalidFields.join(', ')} is required.` };
  }, [authors, doi, subSpecialties, body]);
}

const InProgress: React.FC<{ needsExtraReview: boolean }> = ({ needsExtraReview }) => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const [copyFieldsAndPublishIfNecessary] = useSubmitSummaryMutation();
  const [cancelSummaryMutation] = useCancelSummaryMutation();
  const requiredFieldValidation = useInProgressRequiredFieldValidation(sdk);

  const submitForReviewOrPublish = (successMessage: string) => {
    return async () => {
      const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
      const submitSummaryOptions = {
        variables: {
          contentId: entry.sys.id,
          needsExtraReview: needsExtraReview,
        },
      };
      // TODO: process result
      await copyFieldsAndPublishIfNecessary(submitSummaryOptions);
      sdk.notifier.success(successMessage);
    };
  };

  const publish = submitForReviewOrPublish('Published this entry successfully.');

  const submitForReview = submitForReviewOrPublish('Submitted this entry for review successfully.');

  const action = needsExtraReview ? submitForReview : publish;

  const submitSummary = async () => {
    try {
      await action();
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to submit summary.');
    }
  };

  const cancelSummary = async () => {
    try {
      const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
      const cancelSummaryOptions = {
        variables: {
          contentId: entry.sys.id,
        },
      };
      await cancelSummaryMutation(cancelSummaryOptions);
      sdk.notifier.success('Canceled summary.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to cancel summary.');
    }
  };

  const validationMessage = !requiredFieldValidation.valid ? requiredFieldValidation.message : undefined;
  return (
    <WorkflowButton
      label={'Submit Summary'}
      statusText={'Draft in Progress'}
      statusColor={'warning'}
      onClick={submitSummary}
      options={[
        {
          label: 'Cancel Summary',
          onClick: cancelSummary,
        },
      ]}
      expectedRole={CustomStaffRole.IndiaTeamWriter}
      validationMessage={validationMessage}
    />
  );
};

const ReadyForReview: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();

  const beginReview = async () => {
    try {
      await updateEntry(sdk, {
        workflowStatus: { 'en-US': 'Review in Progress' },
        assigneeUserId: { 'en-US': sdk.user.sys.id },
      });
      sdk.notifier.success('Began review.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to change workflow status.');
    }
  };

  return (
    <WorkflowButton
      label={'Begin Review'}
      onClick={beginReview}
      statusText={'Ready for Review'}
      statusColor={'warning'}
      expectedRole={CustomStaffRole.Editor}
    />
  );
};

const ReviewInProgress: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();

  const publish = async () => {
    try {
      const updated = await updateEntry(sdk, {
        workflowStatus: { 'en-US': 'Ready to Publish' },
      });
      await sdk.space.publishEntry(updated);
      sdk.notifier.success('Published this entry successfully.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to publish.');
    }
  };

  const cancel = async () => {
    try {
      await updateEntry(sdk, {
        workflowStatus: { 'en-US': 'Ready for Review' },
        assigneeUserId: undefined,
      });
      sdk.notifier.success('Canceled.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to cancel summary.');
    }
  };

  return (
    <WorkflowButton
      label={'Publish Summary'}
      onClick={publish}
      statusText={'Review in Progress'}
      statusColor={'warning'}
      options={[{ label: 'Cancel Review', onClick: cancel }]}
      expectedRole={CustomStaffRole.Editor}
    />
  );
};

const ReadyToPublish: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const [changed, setChanged] = useState(hasPendingChanges(sdk.entry.getSys() as ContentfulEntry['sys']));

  useEffect(() => {
    const cleanup = sdk.entry.onSysChanged(sys => {
      setChanged(hasPendingChanges(sys as ContentfulEntry['sys']));
    });
    return () => cleanup();
  }, [sdk, setChanged]);

  if (changed) {
    return <Changed />;
  } else {
    return <Published />;
  }
};

const Published: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const unpublish = async () => {
    try {
      const entry = (await sdk.space.getEntry((sdk.entry.getSys() as any).id)) as ContentfulEntry<JournalSummaryDef>;
      const updated = await sdk.space.updateEntry({
        ...entry,
        fields: { ...entry.fields, workflowStatus: { 'en-US': 'Review in Progress' } },
      });
      await sdk.space.unpublishEntry(updated);
      sdk.notifier.success('Un-Published this entry successfully.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to unpublish.');
    }
  };

  return (
    <WorkflowButton
      label={'Un-publish'}
      onClick={unpublish}
      buttonType={'negative'}
      statusText={'Published'}
      statusColor={'positive'}
      expectedRole={CustomStaffRole.Editor}
    />
  );
};

const Changed: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const publishChanges = async () => {
    try {
      const entry = await sdk.space.getEntry((sdk.entry.getSys() as any).id);
      await sdk.space.publishEntry(entry);
      sdk.notifier.success('Published changes.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to publish.');
    }
  };

  return (
    <WorkflowButton
      label={'Publish changes'}
      onClick={publishChanges}
      buttonType={'positive'}
      statusText={'Changes pending'}
      statusColor={'primary'}
      expectedRole={CustomStaffRole.Editor}
    />
  );
};

type Option = {
  label: string;
  onClick?: () => void;
};

const WorkflowButton: React.FC<{
  label: string;
  expectedRole: CustomStaffRole;
  onClick?: () => void;
  statusText: string;
  statusColor?: 'positive' | 'warning' | 'primary';
  buttonType?: 'positive' | 'negative';
  options?: Option[];
  validationMessage?: string;
}> = ({
  label,
  onClick,
  statusText,
  expectedRole,
  statusColor = 'positive',
  buttonType = 'positive',
  options = [],
  validationMessage,
}) => {
  const me = useMe();
  const [isOpen, setIsOpen] = useState(false);
  const disabled = !!validationMessage || !isPermitted(me, expectedRole);
  return (
    <>
      <Box mb={2}>
        <StatusIndicator label={statusText} color={statusColor} />
      </Box>
      {options.length === 0 ? (
        <Button isFullWidth buttonType={buttonType} onClick={onClick} disabled={disabled}>
          {label}
        </Button>
      ) : (
        <Flex mb={options.length * 5 /* I know this is ugly. */}>
          <Button isFullWidth buttonType={buttonType} onClick={onClick} disabled={disabled}>
            {label}
          </Button>
          <Dropdown
            isOpen={isOpen}
            isAutoalignmentEnabled={true}
            position={'bottom-right'}
            toggleElement={<Button buttonType={buttonType} onClick={() => setIsOpen(!isOpen)} indicateDropdown />}
          >
            <DropdownList border="bottom">
              {options.map(option => (
                <DropdownListItem key={option.label} onClick={option.onClick}>
                  {option.label}
                </DropdownListItem>
              ))}
            </DropdownList>
          </Dropdown>
        </Flex>
      )}
      {validationMessage && (
        <Box mt={2}>
          <ValidationMessage>{validationMessage}</ValidationMessage>
        </Box>
      )}
      <Box mt={2}>
        <LastSaved />
      </Box>
    </>
  );
};

const LastSaved: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();

  // Update this state to reflect relative date
  const [, setTick] = useState(Math.random());
  const tick = useMemo(() => () => setTick(Math.random()), [setTick]);
  useEffect(() => {
    const interval = setInterval(tick, lastSaveTimeUpdateInterval);
    return () => clearInterval(interval);
  }, [tick]);

  const [original, setOriginal] = useState<ContentfulEntry<any> | undefined>(undefined);
  useEffect(() => {
    const id = (sdk.entry.getSys() as any).id;
    sdk.space.getEntry(id).then(entry => setOriginal(entry as ContentfulEntry<any>));
  }, [sdk, setOriginal]);

  const [changed, setChanged] = useState(false);
  useEffect(() => {
    const clear = sdk.entry.onSysChanged(() => {
      setChanged(original ? original.sys.updatedAt !== (sdk.entry.getSys() as any).updatedAt : false);
      tick();
    });
    return () => clear();
  }, [sdk, tick, original, setChanged]);

  const discardChanges = async (original: ContentfulEntry<any>) => {
    try {
      const id = (sdk.entry.getSys() as any).id;
      const current = (await sdk.space.getEntry(id)) as ContentfulEntry<any>;
      const updated = await sdk.space.updateEntry({ sys: current.sys, fields: original.fields });
      setOriginal(updated as ContentfulEntry<any>);
      setChanged(false);
      sdk.notifier.success('Discarded changes successfully.');
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error('Failed to discard changes');
    }
  };

  const updatedAt = DateTime.fromISO((sdk.entry.getSys() as any).updatedAt);
  const lastSaved = updatedAt.toRelative({
    base: DateTime.utc(),
    unit: 'minutes',
  });

  return (
    <Flex>
      <Box {...{ flexGrow: 1 }}>
        <HelpText>Last saved {lastSaved}</HelpText>
      </Box>
      {original && changed && (
        <HelpText>
          <TextLink onClick={() => discardChanges(original)}>Discard changes</TextLink>
        </HelpText>
      )}
    </Flex>
  );
};
