import { Button, Note } from '@contentful/forma-36-react-components';
import { openWindowInNewTab } from 'lib/window';
import React from 'react';
import { Box, Flex } from 'rebass';
import { isHighPriority, NewsArticleEntry } from './common';
import { EntryPriorityIcon } from './EntryPriorityIcon';
import sanitizeHtml from 'sanitize-html';

export const EntryPreview: React.FC<{
  entry: NewsArticleEntry;
  onTogglePriority: () => void;
  onReleaseToEditorial: () => void;
  onReject: () => void;
  updating: boolean;
}> = ({ entry, onTogglePriority, onReleaseToEditorial, onReject, updating }) => {
  return (
    <>
      <Flex>
        <Box>
          <EntryPriorityIcon isHighPriority={isHighPriority(entry)} onToggle={onTogglePriority} disabled={updating} />
        </Box>
        <Box mx={1}>
          <Button
            type={'button'}
            buttonType={'primary'}
            size={'small'}
            onClick={() => {
              if (entry.fields.url) {
                openWindowInNewTab(entry.fields.url['en-US']);
              }
            }}
            disabled={!entry.fields.url}
          >
            View Original
          </Button>
        </Box>
        <Box mx={1}>
          <Button
            type={'button'}
            buttonType={'positive'}
            size={'small'}
            onClick={onReleaseToEditorial}
            disabled={
              (entry.fields.workflowStatus && entry.fields.workflowStatus['en-US']) === 'Selected for Editorial'
            }
          >
            Release to Editorial
          </Button>
        </Box>
        <Box mx={1}>
          <Button
            type={'button'}
            buttonType={'negative'}
            size={'small'}
            onClick={onReject}
            disabled={
              (entry.fields.workflowStatus && entry.fields.workflowStatus['en-US']) === 'Reviewed - Not Selected'
            }
          >
            Reject
          </Button>
        </Box>
      </Flex>

      <Box py={2}>
        {entry.fields.bodyHtml ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(entry.fields.bodyHtml['en-US']),
            }}
          />
        ) : (
          <Note noteType="warning">Body (HTML) is empty</Note>
        )}
      </Box>
    </>
  );
};
