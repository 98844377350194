import { HelpText } from '@contentful/forma-36-react-components';
import {
  assigneeColumn,
  crawlDateColumn,
  openInEditorColumn,
  sourceColumn,
  specialtyColumn,
  titleColumn,
  workflowStatusColumn,
} from 'app/scenes/ui-extension/JournalSummarySelection/columns';
import {
  orderByCrawlDateReverse,
  queryForGeneralSpecialty,
  queryForSpecialtyIds,
  useSpecialtiesForJournalSummary,
} from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { useJournalSummaries } from 'app/scenes/ui-extension/JournalSummarySelection/journal-summaries';
import { NO_FILTER, SpecialtySelection } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtySelector';
import { EntryTable } from 'app/shared/EntryTable';
import { useLocalStorageState } from 'app/shared/local-storage';
import { Pager } from 'app/shared/Pager';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Box } from 'rebass';

function queryForSpecialtySelection(selection: SpecialtySelection): { [key: string]: any } {
  switch (selection.type) {
    case 'NO_FILTER':
      return {};
    case 'GENERAL':
      return queryForGeneralSpecialty;
    case 'SINGLE':
      return queryForSpecialtyIds([selection.specialtyId]);
  }
}

export const UnreviewedAbstracts: React.FC = () => {
  const [specialtySelection, setSpecialtySelection] = useLocalStorageState<SpecialtySelection>(
    'journal-summary-selection:unreviewed-abstracts',
    NO_FILTER,
  );
  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const query = useMemo(
    () => ({
      'fields.workflowStatus[in]': ['Selected for Editorial', 'In Progress'].join(','),
      order,
      ...queryForSpecialtySelection(specialtySelection),
    }),
    [order, specialtySelection],
  );
  const { entries, total, currentPage, totalPage, setCurrentPage } = useJournalSummaries(query);
  const candidates = useSpecialtiesForJournalSummary();

  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <EntryTable
        entries={entries}
        columnDefinitions={[
          titleColumn,
          specialtyColumn({
            selection: specialtySelection,
            onChange: setSpecialtySelection,
            candidates,
          }),
          workflowStatusColumn,
          assigneeColumn,
          sourceColumn,
          crawlDateColumn({ order, onChange: setOrder }),
          openInEditorColumn,
        ]}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
    </>
  );
};
