import { useErrorReporter } from '@mdlinx/frontend-shared';
import { ContentfulEntry } from 'contentful-management';
import { findAllEntries, usePageExtensionSDK } from 'lib/contentful-extension';
import { CuratedCollectionListDef } from 'mdlinx-contentful';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

type CuratedCollectionListEntry = ContentfulEntry<CuratedCollectionListDef>;

const CuratedCollectionListsContext = createContext<CuratedCollectionListEntry[]>([]);

export const CuratedCollectionListsProvider = ({ children }: PropsWithChildren) => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [curatedCollectionLists, setCuratedCollectionLists] = useState<CuratedCollectionListEntry[]>([]);

  useEffect(() => {
    findAllEntries<CuratedCollectionListEntry>(sdk, {
      content_type: 'curatedCollectionList',
      'fields.listType': 'Email',
    })
      .then(curatedCollectionLists => {
        setCuratedCollectionLists(curatedCollectionLists);
      })
      .catch(errorReporter.report);
  }, [sdk, errorReporter]);

  return (
    <CuratedCollectionListsContext.Provider value={curatedCollectionLists}>
      {children}
    </CuratedCollectionListsContext.Provider>
  );
};

export function useCuratedCollectionLists(): CuratedCollectionListEntry[] {
  return useContext(CuratedCollectionListsContext);
}
