import { TableCell } from '@contentful/forma-36-react-components';
import { TableCellProps } from '@contentful/forma-36-react-components/dist/components/Table/TableCell/TableCell/TableCell';
import React, { memo, MouseEventHandler, PropsWithChildren } from 'react';

const firstRowStickyCellStyle = {
  top: '-24px', // Negate Workbench.Content padding.
};

const secondRowStickyCellStyle = {
  top: '18px', // Negate Workbench.Content padding + first row
};

type SticktTableHeadCellProps = PropsWithChildren<{
  align?: TableCellProps['align'];
  colSpan?: number;
  secondRow?: boolean;
  onClick?: MouseEventHandler;
}>;

export const StickyTableHeadCell = memo(function StickyTableHeaderCell({
  children,
  align,
  colSpan,
  secondRow = false,
  onClick,
}: SticktTableHeadCellProps) {
  return (
    <TableCell
      align={align}
      style={
        {
          ...firstRowStickyCellStyle,
          ...(secondRow ? secondRowStickyCellStyle : firstRowStickyCellStyle),
          ...(align ? { textAlign: align } : []),
          ...(typeof onClick !== 'undefined' ? { cursor: 'pointer' } : {}),
        } as React.CSSProperties
      }
      {...{
        ...(colSpan ? { colSpan } : {}),
        onClick,
      }}
    >
      {children}
    </TableCell>
  );
});
