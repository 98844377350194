import { HelpText, TextLink } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import {
  crawlDateColumn,
  sjrColumn,
  sourceColumn,
  specialtyColumn,
  titleColumn,
  viewAbstractColumn,
  workflowStatusColumn,
} from 'app/scenes/ui-extension/JournalSummarySelection/columns';
import {
  orderByCrawlDateReverse,
  queryForGeneralSpecialty,
  queryForSpecialtyIds,
  useSpecialtiesForJournalSummary,
} from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { useJournalSummaries } from 'app/scenes/ui-extension/JournalSummarySelection/journal-summaries';
import { NO_FILTER, SpecialtySelection } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtySelector';
import { useLocalStorageState } from 'app/shared/local-storage';
import { Pager } from 'app/shared/Pager';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';

function queryForSpecialtySelection(selection: SpecialtySelection): { [key: string]: any } {
  switch (selection.type) {
    case 'NO_FILTER':
      return {};
    case 'GENERAL':
      return queryForGeneralSpecialty;
    case 'SINGLE':
      return queryForSpecialtyIds([selection.specialtyId]);
  }
}

export const UnreleasedArticles: React.FC = () => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const candidates = useSpecialtiesForJournalSummary();

  const [specialtySelection, setSpecialtySelection] = useLocalStorageState<SpecialtySelection>(
    'journal-summary-selection:unreleased-articles',
    NO_FILTER,
  );
  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const query = useMemo(
    () => ({
      'fields.workflowStatus[in]': ['Crawler Output'].join(','),
      order,
      ...queryForSpecialtySelection(specialtySelection),
    }),
    [order, specialtySelection],
  );
  const {
    entries,
    total,
    reload,
    isUpdating,
    rejectByIds,
    currentPage,
    setCurrentPage,
    totalPage,
  } = useJournalSummaries(query);
  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          titleColumn,
          specialtyColumn({
            selection: specialtySelection,
            onChange: setSpecialtySelection,
            candidates,
          }),
          workflowStatusColumn,
          viewAbstractColumn,
          sourceColumn,
          sjrColumn,
          crawlDateColumn({
            order,
            onChange: setOrder,
          }),
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} article selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'negative'}
                onClick={async () => {
                  try {
                    await rejectByIds(selectedEntries.map(e => e.sys.id));
                    sdk.notifier.success(`${selectedEntries.length} entries are rejected.`);
                    reload();
                  } catch (e) {
                    errorReporter.report(e);
                    sdk.notifier.error(`Failed to reject some of entries.`);
                    reload();
                  }
                }}
                disabled={isUpdating}
              >
                Reject
              </TextLink>
            </Box>
          </Flex>
        )}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
    </>
  );
};
