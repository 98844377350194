import { Button, Dropdown, DropdownList, DropdownListItem } from '@contentful/forma-36-react-components';
import { SelectableSourceLabel, selectableSourceLabels } from 'app/shared/source-candidates';
import React, { useState } from 'react';

export const SourceSelector: React.FC<{
  value: SelectableSourceLabel;
  onChange: (label: SelectableSourceLabel) => void;
}> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Dropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        toggleElement={
          <Button onClick={() => setIsOpen(true)} buttonType={'naked'} indicateDropdown={true} size={'small'}>
            Source: {value}
          </Button>
        }
      >
        <DropdownList>
          {selectableSourceLabels.map(label => (
            <DropdownListItem
              key={label}
              onClick={() => {
                onChange(label);
                setIsOpen(false);
              }}
            >
              {label}
            </DropdownListItem>
          ))}
        </DropdownList>
      </Dropdown>
    </>
  );
};
