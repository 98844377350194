import { JournalSummaryEntry } from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { usePagedEntries } from 'app/shared/paged-entries';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import { JournalSummaryDef, JournalSummaryFields } from 'mdlinx-contentful';
import { useMemo, useState } from 'react';

type JournalSummaryWorkflowStatus = JournalSummaryFields['workflowStatus']['en-US'];

export function useJournalSummaries(query: { [key: string]: string }) {
  const sdk = usePageExtensionSDK();

  const journalSummaryQuery = useMemo(
    () => ({
      ...query,
      content_type: 'journalSummary',
    }),
    [query],
  );
  const { entries, total, currentPage, totalPage, setCurrentPage, reload } = usePagedEntries<JournalSummaryDef>(
    journalSummaryQuery,
  );

  const [updatingEntryIds, setUpdatingEntryIds] = useState(new Set<string>());

  const lockIds = (ids: string[]) => setUpdatingEntryIds(new Set([...Array.from(updatingEntryIds), ...ids]));

  const unlockIds = (ids: string[]) => {
    const updatingIdsArray = [...Array.from(updatingEntryIds)];
    const idsSetToUnlock = new Set(ids);
    setUpdatingEntryIds(new Set(updatingIdsArray.filter(id => !idsSetToUnlock.has(id))));
  };

  const updateWorkflowStatusBy = (workflowStatus: JournalSummaryWorkflowStatus) => async (ids: string[]) => {
    lockIds(ids);
    try {
      const targetEntryIdsSet = new Set(ids);
      const targetEntries = entries.filter(entry => targetEntryIdsSet.has(entry.sys.id));

      await targetEntries.reduce(async (acc, targetEntry) => {
        await acc;
        await sdk.space.updateEntry({
          ...targetEntry,
          fields: {
            ...targetEntry.fields,
            workflowStatus: { 'en-US': workflowStatus },
          },
        });
      }, Promise.resolve());
    } finally {
      unlockIds(ids);
    }
  };

  const publishByIds = async (ids: string[]) => {
    await ids.reduce(async (acc, id) => {
      await acc;
      const entry = (await sdk.space.getEntry(id)) as JournalSummaryEntry;
      const updated = (await sdk.space.updateEntry({
        ...entry,
        fields: {
          ...entry.fields,
          workflowStatus: { 'en-US': 'Ready to Publish' },
        },
      })) as JournalSummaryEntry;
      try {
        await sdk.space.publishEntry(updated);
      } catch (e) {
        // Revert workflowStatus change if failed to publish.
        await sdk.space.updateEntry({
          ...updated,
          fields: { ...entry.fields },
        });
        throw e;
      }
    }, Promise.resolve());
  };

  return {
    entries,
    total,
    currentPage,
    totalPage,
    setCurrentPage,
    reload,

    isUpdating: 0 < updatingEntryIds.size,
    isEntryUpdating: (id: string) => updatingEntryIds.has(id),
    rejectByIds: updateWorkflowStatusBy('Reviewed - Not Selected'),
    unDeleteByIds: updateWorkflowStatusBy('Crawler Output'),
    publishByIds,
  };
}
