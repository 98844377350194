import {
  ActiveContentPreviewComponent,
  ActiveContentPreviewError,
  ActiveContentPreviewQuery,
} from 'generated/graphql-client-query';
import * as React from 'react';

type PreviewProps = { id: string };
export const Preview: React.FC<PreviewProps> = ({ id }) => {
  return (
    <ActiveContentPreviewComponent variables={{ id }}>
      {({ data, loading, error }) =>
        loading ? (
          <div>loading</div>
        ) : error ? (
          <Error message={`Failed to fetch.`} />
        ) : typeof data !== 'undefined' ? (
          <PreviewComponent data={data.activeContentPreview} />
        ) : (
          <Error message={'data not found'} />
        )
      }
    </ActiveContentPreviewComponent>
  );
};

const Error: React.FC<{ message: string }> = ({ message }) => <div>Error: {message}</div>;

const errorToMessage = (error: ActiveContentPreviewError): string => {
  switch (error) {
    case ActiveContentPreviewError.UnsupportedContentType:
      return 'Unsupported content type';
    case ActiveContentPreviewError.NotFound:
      return 'Entry not found';
  }
};

const PreviewComponent: React.FC<{ data: ActiveContentPreviewQuery['activeContentPreview'] }> = ({ data }) => {
  switch (data.__typename) {
    case 'ActiveContentPreviewFailure':
      return <Error message={errorToMessage(data.error)} />;
    default:
      return null;
  }
};
