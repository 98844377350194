import { Button } from '@contentful/forma-36-react-components';
import React, { memo } from 'react';
import { Flex } from 'rebass';
import { range } from '../../lib/numbers';

/**
 * Returns at most 5 pages nearby currentPage.
 * i.g.)
 * adjacentPages(5, 10)
 * => [3, 4, 5, 6, 7]
 *
 * adjacentPages(1, 10)
 * => [1, 2, 3, 4, 5]
 *
 * adjacentPages(1, 1)
 * => [1]
 *
 * @param currentPage
 * @param totalPage
 */
export function adjacentPages(currentPage: number, totalPage: number): number[] {
  const min = Math.max(1, currentPage + 2 <= totalPage ? currentPage - 2 : totalPage - 4);
  const max = Math.min(min + 4, totalPage);
  return range(min, max);
}

type PagerProps = {
  currentPage: number;
  totalPage: number;
  onChange: (page: number) => void;
};

export const Pager = memo(function Pager({ currentPage, totalPage, onChange }: PagerProps) {
  const pagesToShowAtCenter = adjacentPages(currentPage, totalPage);
  const minPageAtCenter = pagesToShowAtCenter[0];
  const maxPageAtCenter = pagesToShowAtCenter[pagesToShowAtCenter.length - 1];

  const showMinPage = minPageAtCenter > 1;
  const showLowerOmit = minPageAtCenter > 2;
  const hasPrev = currentPage > 1;

  const showMaxPage = maxPageAtCenter < totalPage;
  const showHigherOmit = maxPageAtCenter < totalPage - 1;
  const hasNext = currentPage < totalPage;

  // TODO: Beautiful UI
  return (
    <Flex justifyContent={'center'}>
      <Button buttonType={'naked'} disabled={!hasPrev} onClick={() => onChange(currentPage - 1)}>
        &lt; Prev
      </Button>

      {showMinPage && (
        <Button buttonType={'naked'} onClick={() => onChange(1)}>
          1
        </Button>
      )}

      {showLowerOmit && '…'}

      {pagesToShowAtCenter.map(page => (
        <Button key={page} buttonType={'naked'} isActive={page === currentPage} onClick={() => onChange(page)}>
          {page}
        </Button>
      ))}

      {showHigherOmit && '…'}

      {showMaxPage && (
        <Button buttonType={'naked'} onClick={() => onChange(totalPage)}>
          {totalPage}
        </Button>
      )}

      <Button buttonType={'naked'} disabled={!hasNext} onClick={() => onChange(currentPage + 1)}>
        Next &gt;
      </Button>
    </Flex>
  );
});
