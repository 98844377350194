import * as React from 'react';
import { useFieldExtensionSDK } from 'lib/contentful-extension';
import { TextInput } from '@contentful/forma-36-react-components';
import { useEffect, useState } from 'react';

export const DisabledTextInput: React.FC = () => {
  const sdk = useFieldExtensionSDK();
  const [value, setValue] = useState(sdk.field.getValue() || '');

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);

  useEffect(() => {
    const unsubscribe = sdk.field.onValueChanged(value => {
      setValue(value || '');
    });
    return () => unsubscribe();
  }, [sdk, setValue]);

  return <TextInput value={value} disabled={true} />;
};
