import { StaffMembersQuery, useStaffMembersQuery } from 'generated/graphql-client-query';
import React, { PropsWithChildren, useContext } from 'react';

type StaffMember = StaffMembersQuery['staffMembers'] extends Array<infer V> ? V : never;

const StaffMembersContext = React.createContext<StaffMember[]>([]);

export const StaffMembersProvider = ({ children }: PropsWithChildren) => {
  const { data } = useStaffMembersQuery();
  const staffMembers = data?.staffMembers ?? [];
  return <StaffMembersContext.Provider value={staffMembers}>{children}</StaffMembersContext.Provider>;
};

export function useStaffMembers(): StaffMember[] {
  return useContext(StaffMembersContext);
}
