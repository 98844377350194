import {
  Button,
  EmptyState,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@contentful/forma-36-react-components';
import Workbench from '@contentful/forma-36-react-components/dist/components/Workbench/Workbench';
import { SearchCondition, SearchField } from 'app/scenes/ui-extension/ArchivedContents/SearchField';
import { useUnarchiveContent } from 'app/scenes/ui-extension/ArchivedContents/useUnarchiveContent';
import { ArchivedContentQuery, useArchivedContentsQuery, ContentTypeId } from 'generated/graphql-client-query';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

type ArchivedContent = NonNullable<ArchivedContentQuery['archivedContent']>;

const ArchivedContentList: React.FC<{
  archivedContents: ArchivedContent[];
}> = ({ archivedContents }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Content Type</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Published At</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedContents.map(archivedContent => (
          <TableRow key={archivedContent.id}>
            <TableCell>{archivedContent.id}</TableCell>
            <TableCell>{archivedContent.contentType}</TableCell>
            <TableCell>{archivedContent.title}</TableCell>
            <TableCell>{DateTime.fromISO(archivedContent.publishedAt).toLocaleString()}</TableCell>
            <TableCell>
              <UnarchiveButton contentId={archivedContent.id} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const UnarchiveButton: React.FC<{ contentId: string }> = ({ contentId }) => {
  const unarchiveContent = useUnarchiveContent();
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    unarchiveContent(contentId).finally(() => setLoading(false));
  };

  return (
    <Button buttonType="muted" size="small" loading={loading} onClick={onClick}>
      Unarchive
    </Button>
  );
};

const contentTypeMap = {
  journalSummary: ContentTypeId.JournalSummary,
  newsArticle: ContentTypeId.NewsArticle,
  longFormContent: ContentTypeId.LongFormContent,
};
const SearchAll: React.FC<{ condition: SearchCondition }> = ({ condition }) => {
  const { contentType, searchColumn, searchTerm } = condition;

  const queryResult = useArchivedContentsQuery({
    variables: {
      filterParams: {
        contentType: (contentType !== 'all' && contentTypeMap[contentType]) || undefined,
        titleSearch: (searchColumn === 'title' && searchTerm) || undefined,
        contentId: (searchColumn === 'contentId' && searchTerm) || undefined,
      },
    },
  });

  return queryResult.data && queryResult.data.archivedContents ? (
    <ArchivedContentList archivedContents={queryResult.data.archivedContents.edges.map(({ node }) => node)} />
  ) : queryResult.error ? (
    <span>{JSON.stringify(queryResult.error)}</span>
  ) : queryResult.loading ? (
    <Spinner />
  ) : (
    <Empty />
  );
};

const Empty = () => (
  <EmptyState
    headingProps={{
      text: 'No search result?',
    }}
    descriptionProps={{
      text: 'Try a different content ID.',
    }}
  />
);

const SearchResultContainer: React.FC<{
  condition: SearchCondition;
}> = ({ condition }) => {
  return <SearchAll condition={condition} />;
};

export const ArchivedContents: React.FC = () => {
  const [condition, setCondition] = useState<SearchCondition>({
    searchTerm: '',
    contentType: 'all',
    searchColumn: 'contentId',
  });
  return (
    <Workbench>
      <Workbench.Header title="Archived Contents" />
      <Workbench.Content type="full">
        <SearchField onSubmit={setCondition} />
        <SearchResultContainer condition={condition} />
      </Workbench.Content>
    </Workbench>
  );
};
