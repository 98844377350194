import { Note } from '@contentful/forma-36-react-components';
import {
  DialogExtensionSDK,
  EditorExtensionSDK,
  FieldExtensionSDK,
  init as initContentfulExtension,
  KnownSDK,
  locations,
  PageExtensionSDK,
  SidebarExtensionSDK,
} from 'contentful-ui-extensions-sdk';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

type ContentfulUIExtensionSDK =
  | { type: 'FIELD'; sdk: FieldExtensionSDK }
  | { type: 'SIDEBAR'; sdk: SidebarExtensionSDK }
  | { type: 'DIALOG'; sdk: DialogExtensionSDK }
  | { type: 'EDITOR'; sdk: EditorExtensionSDK }
  | { type: 'PAGE'; sdk: PageExtensionSDK };

type State = { loading: true } | ({ loading: false } & ContentfulUIExtensionSDK);

function loaded(contentfulUIExtensionSDK: ContentfulUIExtensionSDK): State {
  return {
    loading: false,
    ...contentfulUIExtensionSDK,
  };
}

export function useContentfulUIExtensionSDK(): State {
  const [sdk, setSdk] = useState<undefined | KnownSDK>();
  useEffect(() => {
    initContentfulExtension(setSdk);
  }, []);

  if (!sdk) {
    return { loading: true };
  }
  if (sdk.location.is(locations.LOCATION_ENTRY_FIELD)) {
    return loaded({ type: 'FIELD', sdk: sdk as FieldExtensionSDK });
  }
  if (sdk.location.is(locations.LOCATION_ENTRY_EDITOR)) {
    return loaded({ type: 'EDITOR', sdk: sdk as EditorExtensionSDK });
  }
  if (sdk.location.is(locations.LOCATION_DIALOG)) {
    return loaded({ type: 'DIALOG', sdk: sdk as DialogExtensionSDK });
  }
  if (sdk.location.is(locations.LOCATION_ENTRY_SIDEBAR)) {
    return loaded({ type: 'SIDEBAR', sdk: sdk as SidebarExtensionSDK });
  }
  if (sdk.location.is(locations.LOCATION_PAGE)) {
    return loaded({ type: 'PAGE', sdk: sdk as PageExtensionSDK });
  }

  throw new Error(`Unexpected extension location.`);
}

const FieldExtensionSDKContext = React.createContext<FieldExtensionSDK | undefined>(undefined);
export function useFieldExtensionSDK(): FieldExtensionSDK {
  const sdk = useContext(FieldExtensionSDKContext);
  if (!sdk) {
    throw new Error(`FieldExtensionSDK was not provided`);
  }
  return sdk;
}

export const FieldExtensionSDKProvider = ({ children }: PropsWithChildren) => {
  const sdk = useContentfulUIExtensionSDK();
  if (sdk.loading) {
    return null;
  }

  if (sdk.type !== 'FIELD') {
    return <Note noteType="negative">This SDK is only for field extension.</Note>;
  }

  return <FieldExtensionSDKContext.Provider value={sdk.sdk}>{children}</FieldExtensionSDKContext.Provider>;
};

const SidebarExtensionSDKContext = React.createContext<SidebarExtensionSDK | undefined>(undefined);
export function useSidebarExtensionSDK(): SidebarExtensionSDK {
  const sdk = useContext(SidebarExtensionSDKContext);
  if (!sdk) {
    throw new Error(`SidebarExtensionSDK was not provided`);
  }
  return sdk;
}

export const SidebarExtensionSDKProvider = ({ children }: PropsWithChildren) => {
  const sdk = useContentfulUIExtensionSDK();
  if (sdk.loading) {
    return null;
  }

  if (sdk.type !== 'SIDEBAR') {
    return <Note noteType="negative">This SDK is only for sidebar extension.</Note>;
  }

  return <SidebarExtensionSDKContext.Provider value={sdk.sdk}>{children}</SidebarExtensionSDKContext.Provider>;
};

export const PageExtensionSDKContext = React.createContext<PageExtensionSDK | undefined>(undefined);
export function usePageExtensionSDK(): PageExtensionSDK {
  const sdk = useContext(PageExtensionSDKContext);
  if (!sdk) {
    throw new Error('PageExtensionSDK must be given.');
  }
  return sdk;
}

export const PageExtensionSDKProvider = ({ children }: PropsWithChildren) => {
  const sdk = useContentfulUIExtensionSDK();
  if (sdk.loading) {
    return null;
  }

  if (sdk.type !== 'PAGE') {
    return <Note noteType="negative">This SDK is only for page extension.</Note>;
  }

  return <PageExtensionSDKContext.Provider value={sdk.sdk}>{children}</PageExtensionSDKContext.Provider>;
};

export function useEntryField<T>(
  sdk: FieldExtensionSDK | SidebarExtensionSDK,
  fieldName: string,
  locale?: string,
): [T | undefined, (value: T | undefined) => void] {
  const [value, setValue] = useState<T | undefined>(sdk.entry.fields[fieldName].getValue(locale));

  useEffect(() => {
    const cleanup = (sdk.entry.fields[fieldName].onValueChanged as any)(locale, setValue);
    return () => cleanup();
  });

  return [value, setValue];
}

// For some reason, Contentful API returns Error when the item size is greater than 1000, even though the limit is set to 1000.
// It works with 500, 900 but it doesn't work with 990. I'm not sure the rule here, but set it to 500.
const batchSize = 500;

export async function findAllEntries<T>(
  sdk: FieldExtensionSDK | SidebarExtensionSDK | PageExtensionSDK,
  query: any,
): Promise<T[]> {
  const loop = async (skip: number = 0, entries: T[] = []): Promise<T[]> => {
    const response = await sdk.space.getEntries({
      order: 'sys.id',
      ...query,
      limit: batchSize,
      skip,
    });
    const acc = [...entries, ...(response.items as T[])];
    return response.items.length < batchSize ? acc : loop(skip + batchSize, acc);
  };

  return await loop();
}

export async function findAllPublishedEntries<T>(
  sdk: FieldExtensionSDK | SidebarExtensionSDK | PageExtensionSDK,
  query: any,
): Promise<T[]> {
  const loop = async (skip: number = 0, entries: T[] = []): Promise<T[]> => {
    const response = await sdk.space.getPublishedEntries({
      order: 'sys.id',
      ...query,
      limit: batchSize,
      skip,
    });
    const acc = [...entries, ...(response.items as T[])];
    return response.items.length < batchSize ? acc : loop(skip + batchSize, acc);
  };

  return await loop();
}

export function entryEditPageUrl(spaceId: string, environmentId: string, entryId: string) {
  if (environmentId.startsWith('master')) {
    return `https://app.contentful.com/spaces/${spaceId}/entries/${entryId}`;
  } else {
    return `https://app.contentful.com/spaces/${spaceId}/environments/${environmentId}/entries/${entryId}`;
  }
}

export function mdlinxUrl(entryId: string, contentTypeSlug: string) {
  const envOrDefault = process.env.REACT_APP_MDLINX_URL || 'https://www.mdlinx.com';
  return `${envOrDefault}/${contentTypeSlug}/${entryId}`;
}
