import { entryToTag, resolveParentName, specialtyNameSort } from 'app/scenes/ui-extension/TagSelector/functions';
import { TagSelectorFieldContainer } from 'app/scenes/ui-extension/TagSelector/TagSelectorField';
import { buildTreeNodes, getAllNodeValues, selectTreeNode } from 'app/scenes/ui-extension/TagSelector/tree';
import { SpecialtyEntry, TopicTagEntry } from 'app/scenes/ui-extension/TagSelector/types';
import { FieldExtensionSDK } from 'contentful-ui-extensions-sdk';
import { partition } from 'lib/arrays';
import { findAllEntries, findAllPublishedEntries, useFieldExtensionSDK } from 'lib/contentful-extension';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { CandidateTags, SuggestionStrategy } from './CandidateTags';

type SpecialtySelectorParams = {
  filteredSpecialtyNames?: string;
};

async function fetchAllEntriesByContentType<T>(
  sdk: FieldExtensionSDK,
  contentType: string,
  options: { allowUnpublished: boolean } = { allowUnpublished: false },
): Promise<T[]> {
  return (await options.allowUnpublished)
    ? findAllEntries(sdk, { content_type: contentType })
    : findAllPublishedEntries(sdk, { content_type: contentType });
}

function isTopLevelSpecialty(specialty: SpecialtyEntry): boolean {
  return typeof specialty.fields.parent === 'undefined';
}

function renameDuplicateSpecialties(
  subSpecialties: SpecialtyEntry[],
  setOfDuplicateNames: Set<string | undefined>,
  idToEntry: Map<string, SpecialtyEntry>,
): SpecialtyEntry[] {
  for (let s of subSpecialties) {
    if (setOfDuplicateNames.has(s.fields.name['en-US'])) {
      const parentId = s.fields?.parent?.['en-US'].sys.id;
      if (parentId) {
        const parentName = idToEntry.get(parentId)?.fields.name['en-US'];
        s.fields.name['en-US'] += ` (${parentName})`;
      } else {
        console.error('No parent ID found for specialty!', s);
      }
    }
  }

  return subSpecialties;
}

function buildIdToEntryMap(allSpecialtyEntries: SpecialtyEntry[]): Map<string, SpecialtyEntry> {
  const idToEntry: Map<string, SpecialtyEntry> = new Map();

  for (let s of allSpecialtyEntries) {
    idToEntry.set(s.sys.id, s);
  }

  return idToEntry;
}

function buildDuplicateNameSet(subSpecialties: SpecialtyEntry[], allSpecialtyEntries: SpecialtyEntry[]) {
  const duplicates: Array<Array<SpecialtyEntry | undefined>> = [];
  let sorted = subSpecialties.sort(specialtyNameSort);

  for (let i = 0; i < sorted.length - 1; i++) {
    if (sorted[i].fields.name['en-US'] === sorted[i + 1].fields.name['en-US']) {
      const dupeParent = allSpecialtyEntries.find(s => s.sys.id === sorted[i].fields?.parent?.['en-US'].sys.id);
      duplicates.push([sorted[i], dupeParent]);
    }
  }

  return new Set(duplicates.map(d => d[0]?.fields.name['en-US']));
}

export const TopicTagSelector: React.FC<{
  allowAdd: boolean;
  allowUnpublished: boolean;
  suggestionSize: number;
  suggestionStrategy: SuggestionStrategy;
  includeTopLevelSpecialties: boolean;
  includeSubSpecialties: boolean;
}> = ({
  allowAdd,
  allowUnpublished,
  suggestionSize,
  suggestionStrategy,
  includeTopLevelSpecialties,
  includeSubSpecialties,
}) => {
  const sdk = useFieldExtensionSDK();
  const [candidateTags, setCandidateTags] = useState<CandidateTags | null>(null);

  const reload = useMemo<() => Promise<void>>(
    () => async () => {
      await fetchAllEntriesByContentType(sdk, 'topicTag', { allowUnpublished });
      const willBeTopicTags = fetchAllEntriesByContentType<TopicTagEntry>(sdk, 'topicTag', { allowUnpublished });
      const willBeSpecialtyTags = fetchAllEntriesByContentType<SpecialtyEntry>(sdk, 'specialty', { allowUnpublished });
      const [topicTagEntries, specialtyEntries] = await Promise.all([willBeTopicTags, willBeSpecialtyTags]);
      const topicTagEntriesWithParentNames = resolveParentName(topicTagEntries);

      const [topLevelSpecialtyEntries, subSpecialtyEntries] = partition(specialtyEntries)(isTopLevelSpecialty);
      const tags = [
        ...topicTagEntriesWithParentNames.map(entryToTag),
        ...(includeTopLevelSpecialties ? topLevelSpecialtyEntries.map(entryToTag) : []),
        ...(includeSubSpecialties ? subSpecialtyEntries.map(entryToTag) : []),
      ];
      setCandidateTags(
        new CandidateTags(tags, {
          suggestionSize,
          strategy: suggestionStrategy,
        }),
      );
    },
    [sdk, setCandidateTags, allowUnpublished],
  );
  useEffect(() => {
    reload().catch(e => sdk.notifier.error(e.message));
  }, [reload, sdk]);

  return (
    candidateTags && <TagSelectorFieldContainer candidateTags={candidateTags} reload={reload} allowAdd={allowAdd} />
  );
};

export const SpecialtySelector: React.FC<{
  suggestionSize: number;
  suggestionStrategy: SuggestionStrategy;
}> = ({ suggestionSize, suggestionStrategy }) => {
  const sdk = useFieldExtensionSDK();
  const [candidateTags, setCandidateTags] = useState<CandidateTags | null>(null);

  const filteredSpecialtyNames =
    (sdk.parameters.instance as SpecialtySelectorParams).filteredSpecialtyNames?.split(',')?.map(s => s.trim()) ?? [];

  const reload = useMemo<() => Promise<void>>(
    () => async () => {
      const tags = await fetchAllEntriesByContentType<SpecialtyEntry>(sdk, 'specialty').then(entries =>
        entries
          .filter(
            item =>
              typeof item.fields.parent === 'undefined' && !filteredSpecialtyNames.includes(item.fields.name['en-US']),
          )
          .map(entryToTag),
      );
      setCandidateTags(
        new CandidateTags(tags, {
          suggestionSize,
          strategy: suggestionStrategy,
        }),
      );
    },
    [sdk, setCandidateTags],
  );

  useEffect(() => {
    reload().catch(e => sdk.notifier.error(e.message));
  }, [reload, sdk]);

  return candidateTags && <TagSelectorFieldContainer candidateTags={candidateTags} reload={reload} allowAdd={false} />;
};

export const SubSpecialtySelector: React.FC<{
  suggestionSize: number;
  suggestionStrategy: SuggestionStrategy;
}> = ({ suggestionSize, suggestionStrategy }) => {
  const sdk = useFieldExtensionSDK();

  const [selectedSpecialtyEntries, setSelectedSpecialtyEntries] = useState<SpecialtyEntry[]>(
    sdk.entry.fields.specialties.getValue() || [],
  );

  const [allSpecialtyEntries, setAllSpecialtyEntries] = useState<SpecialtyEntry[] | null>(null);

  const reload = useMemo<() => Promise<void>>(
    () => async () => {
      const entries = await fetchAllEntriesByContentType<SpecialtyEntry>(sdk, 'specialty');
      setAllSpecialtyEntries(entries);
    },
    [sdk, setAllSpecialtyEntries],
  );

  useEffect(() => {
    reload().catch(e => sdk.notifier.error(e.message));
  }, [reload, sdk]);

  useEffect(() => {
    const specialtiesField = sdk.entry.fields.specialties;
    const unsubscribe = (specialtiesField.onValueChanged as any)((specialties: SpecialtyEntry[] | undefined) => {
      setSelectedSpecialtyEntries(specialties || []);
    });

    return () => unsubscribe();
  }, [sdk, setSelectedSpecialtyEntries]);

  const candidateTags = useMemo(() => {
    if (!allSpecialtyEntries) {
      return null;
    }

    const selectedSpecialtyNodeIds = new Set(selectedSpecialtyEntries.map(s => s.sys.id));
    const treeNodes = buildTreeNodes(
      allSpecialtyEntries,
      entry => entry.sys.id,
      entry => {
        try {
          return entry.fields.parent ? entry.fields.parent['en-US'].sys.id : undefined;
        } catch (error) {
          console.error('Sub specialty entry has malformed data', entry, error);
          throw new Error(`Specialty ${entry.fields?.name?.['en-US']} has malformed data.`);
        }
      },
    );

    const subSpecialties = getAllNodeValues(
      selectTreeNode(treeNodes, topicTag => selectedSpecialtyNodeIds.has(topicTag.sys.id)),
    ).filter(topicTag => !selectedSpecialtyNodeIds.has(topicTag.sys.id));

    const idToEntry = buildIdToEntryMap(allSpecialtyEntries);
    const setOfDuplicateNames = buildDuplicateNameSet(subSpecialties, allSpecialtyEntries);
    const renamedSubSpecialties = renameDuplicateSpecialties(subSpecialties, setOfDuplicateNames, idToEntry);

    return new CandidateTags(renamedSubSpecialties.map(entryToTag), {
      suggestionSize,
      strategy: suggestionStrategy,
    });
  }, [selectedSpecialtyEntries, allSpecialtyEntries]);

  return candidateTags && <TagSelectorFieldContainer candidateTags={candidateTags} reload={reload} allowAdd={false} />;
};
