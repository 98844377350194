import { Icon } from '@contentful/forma-36-react-components';
import { SortOrder } from 'app/scenes/ui-extension/JournalSummarySelection/common';
import React from 'react';

export const SortIcon: React.FC<{
  order: SortOrder;
}> = ({ order }) => {
  switch (order) {
    case 'asc':
      return <Icon icon={'ArrowUp'} />;
    case 'desc':
      return <Icon icon={'ArrowDown'} />;
  }
};
