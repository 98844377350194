import { useErrorReporter } from '@mdlinx/frontend-shared';
import { ContentfulEntry } from 'contentful-management';
import { PageExtensionSDK } from 'contentful-ui-extensions-sdk';
import { findAllPublishedEntries } from 'lib/contentful-extension';
import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';

type TopicTag = ContentfulEntry<{
  contentType: 'topicTag';
  fields: {
    name: { 'en-US': string };
  };
}>;
export type PrimaryTopicTag = TopicTag;

type TopicTagId = string;
export function topicTagId(topicTag: TopicTag): TopicTagId {
  return topicTag.sys.id;
}
export function topicTagName(topicTag: TopicTag): string {
  return topicTag.fields.name['en-US'] ?? '(name undefined)';
}

export class TopicTags {
  all: TopicTag[];

  constructor(private readonly storage: Map<string, TopicTag>) {
    this.all = [...Array.from(storage.values())];
  }

  get(id: string): TopicTag | undefined {
    return this.storage.get(id);
  }
}

const TopicTagsContext = React.createContext<TopicTags>(new TopicTags(new Map()));

type TopicTagsProviderProps = PropsWithChildren<{
  sdk: PageExtensionSDK;
}>;

export const TopicTagsProvider = ({ sdk, children }: TopicTagsProviderProps) => {
  const [topicTags, setTopicTags] = useState<TopicTags>(new TopicTags(new Map()));
  const errorReporter = useErrorReporter();
  useEffect(() => {
    findAllPublishedEntries<TopicTag>(sdk, { content_type: 'topicTag' })
      .then(items => setTopicTags(new TopicTags(new Map(items.map(i => [i.sys.id, i])))))
      .catch(errorReporter.report);
  }, [sdk, setTopicTags]);

  return <TopicTagsContext.Provider value={topicTags}>{children}</TopicTagsContext.Provider>;
};

export function useTopicTags(): TopicTags {
  return useContext(TopicTagsContext);
}
