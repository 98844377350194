import { useMemo, useState } from 'react';
import { Source, sourceId, sourceTargetName } from './sources';

type TargetQuery = Record<string, string>;

export type SelectableSourceLabel =
  | 'All'
  | 'Healthline / Medical News Today'
  | 'Medical Xpress'
  | 'Newswise'
  | 'Reuters Health'
  | 'ScienceDaily'
  | 'Everything Else';
export const selectableSourceLabels: SelectableSourceLabel[] = [
  'All',
  'Healthline / Medical News Today',
  'Medical Xpress',
  'Newswise',
  'Reuters Health',
  'ScienceDaily',
  'Everything Else',
];

export function useSourceCandidates(sources: Source[]) {
  const [selectedSourceLabel, setSelectedSourceLabel] = useState<SelectableSourceLabel>('All');

  // https://jira.mdlinx.com/browse/MDLR-402, https://jira.mdlinx.com/browse/MDLR-413
  const targetQueries = useMemo<Record<SelectableSourceLabel, TargetQuery>>(() => {
    const healthLineMedicalNewsToday: Source[] = [];
    const medicalXpress: Source[] = [];
    const newswise: Source[] = [];
    const reutersHealth: Source[] = [];
    const scienceDaily: Source[] = [];

    sources.forEach(source => {
      const targetName = sourceTargetName(source);
      if (targetName === 'Reuters Health Medical News') {
        reutersHealth.push(source);
      } else if (targetName.startsWith('Healthline') || targetName.startsWith('Medical News Today')) {
        healthLineMedicalNewsToday.push(source);
      } else if (targetName.startsWith('Newswise')) {
        newswise.push(source);
      } else if (targetName.startsWith('MedicalXpress')) {
        medicalXpress.push(source);
      } else if (targetName.startsWith('ScienceDaily')) {
        scienceDaily.push(source);
      }
    });
    return {
      All: {},
      'Reuters Health': {
        'fields.source.sys.id[in]': reutersHealth.map(sourceId).join(','),
      },
      'Healthline / Medical News Today': {
        'fields.source.sys.id[in]': healthLineMedicalNewsToday.map(sourceId).join(','),
      },
      Newswise: {
        'fields.source.sys.id[in]': newswise.map(sourceId).join(','),
      },
      'Medical Xpress': {
        'fields.source.sys.id[in]': medicalXpress.map(sourceId).join(','),
      },
      ScienceDaily: {
        'fields.source.sys.id[in]': scienceDaily.map(sourceId).join(','),
      },
      'Everything Else': {
        'fields.source.sys.id[nin]': [
          ...reutersHealth,
          ...healthLineMedicalNewsToday,
          ...newswise,
          ...medicalXpress,
          ...scienceDaily,
        ]
          .map(sourceId)
          .join(','),
      },
    };
  }, [sources]);

  return useMemo(() => {
    return {
      selectedSourceLabel,
      setSelectedSourceLabel,
      targetQuery: targetQueries[selectedSourceLabel],
    };
  }, [selectedSourceLabel, setSelectedSourceLabel, targetQueries]);
}
