import { TextInput, HelpText } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import { useFieldExtensionSDK } from 'lib/contentful-extension';
import { marked } from 'marked';
import React, { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

function markdownToSafeHtml(markdown: string): string | undefined {
  try {
    const html = marked(markdown);
    return sanitizeHtml(html as string, {
      allowedTags: ['b', 'i', 'em', 'strong'],
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export const DisplayTitleEditor: React.FC<{ fieldName: string }> = ({ fieldName }) => {
  const sdk = useFieldExtensionSDK();
  const errorReporter = useErrorReporter();
  const [value, setValue] = useState(sdk.field.getValue() || '');
  const [html, setHtml] = useState(markdownToSafeHtml(value) || '');
  const [error, setError] = useState(sdk.field.validations.length > 0);

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);

  useEffect(() => {
    const dummyElement = document.createElement('div');
    function extractText(html: string): string {
      dummyElement.innerHTML = html;
      return dummyElement.innerText;
    }

    const unsubscribe = sdk.field.onValueChanged(changedValue => {
      const html = markdownToSafeHtml(changedValue || '');
      if (!html) {
        sdk.field.setInvalid(true);
        setError(true);
        return;
      }
      sdk.field.setInvalid(false);
      sdk.entry.fields[fieldName].setValue(extractText(html), sdk.field.locale).catch(errorReporter.report);
      setValue(changedValue);
      setHtml(html);
      setError(false);
    });
    return () => unsubscribe();
  }, [sdk, setValue, errorReporter, setHtml, setError]);

  function handleChange(changedValue: string) {
    setValue(changedValue);
    (sdk.field as any).setValue(changedValue, sdk.field.locale).catch(errorReporter.report);
  }

  return (
    <>
      <TextInput value={value} onChange={e => handleChange(e.target.value)} error={error} />
      <HelpText>
        preview: <span dangerouslySetInnerHTML={{ __html: html }} />
      </HelpText>
      <HelpText>
        <strong>**bold**</strong> <em>*italic*</em>
      </HelpText>
    </>
  );
};
