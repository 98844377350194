import { Button } from '@contentful/forma-36-react-components';
import { extractDataFromResponse } from '@mdlinx/frontend-shared';
import { CustomStaffRole, useRequestTopicTagMutation } from 'generated/graphql-client-query';
import { useMe } from 'lib/auth';
import { useFieldExtensionSDK } from 'lib/contentful-extension';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { CandidateTags } from './CandidateTags';
import { entryToTag, tagToEntryLink } from './functions';
import { Tag } from './Tag';
import { TagDTO } from './tag-dto';
import { TypeAheadInput } from './TypeAheadInput';
import { EntryLink, TopicTagEntry } from './types';

const customRolesWithoutEditPermission = new Set([CustomStaffRole.Guest, CustomStaffRole.IndiaTeamWriter]);

export const TagSelectorFieldContainer: React.FC<{
  candidateTags: CandidateTags;
  reload: () => Promise<void>;
  allowAdd: boolean;
}> = ({ candidateTags, reload, allowAdd }) => {
  const me = useMe();
  const sdk = useFieldExtensionSDK();
  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);
  const [requestTopicTag] = useRequestTopicTagMutation();

  const createNewTag = async (name: string) => {
    const response = await requestTopicTag({ variables: { input: { name } } }).then(extractDataFromResponse);
    const topicTagId = response.requestTopicTag.topicTagId;

    const editable = !customRolesWithoutEditPermission.has(me.customRole);
    const onRequest = editable
      ? sdk.navigator.openEntry(topicTagId, { slideIn: true })
      : (() => {
          sdk.notifier.success(`New Topic Tag (${name}) is successfully requested.`);
          return Promise.resolve();
        })();

    const topicTagPromise = sdk.space.getEntry(topicTagId);
    await Promise.all<any>([reload(), onRequest]);
    return entryToTag((await topicTagPromise) as TopicTagEntry);
  };

  return sdk.field.type === 'Link' ? (
    <SingleTagSelectorFieldContainer candidateTags={candidateTags} allowAdd={allowAdd} createNewTag={createNewTag} />
  ) : (
    <MultipleTagSelectorFieldContainer candidateTags={candidateTags} allowAdd={allowAdd} createNewTag={createNewTag} />
  );
};

const SingleTagSelectorFieldContainer: React.FC<{
  candidateTags: CandidateTags;
  allowAdd: boolean;
  createNewTag: (name: string) => Promise<TagDTO>;
}> = ({ candidateTags, allowAdd, createNewTag }) => {
  const sdk = useFieldExtensionSDK();
  const [entryLink, setEntryLink] = useState<EntryLink | undefined>(sdk.field.getValue());

  useEffect(() => {
    const cleanup = sdk.field.onValueChanged((newValue: EntryLink | undefined) => {
      setEntryLink(newValue);
    });
    return () => cleanup();
  }, [sdk, setEntryLink]);

  const handleChange = (tags: TagDTO[]) => {
    const tag = tags[0];
    if (tag) {
      sdk.field.setValue(tagToEntryLink(tag)).catch(e => sdk.notifier.error(e.message));
    } else {
      sdk.field.removeValue().catch(e => sdk.notifier.error(e.message));
    }
  };

  const selectedTag = entryLink && candidateTags.getTag(entryLink.sys.id);
  if (!selectedTag && !!entryLink) {
    handleChange([]);
  }

  return (
    <TagSelectorField
      selectedTags={selectedTag ? [selectedTag] : []}
      candidateTags={candidateTags}
      allowAdd={allowAdd}
      inputDisabled={!!selectedTag}
      createNewTag={createNewTag}
      onChange={handleChange}
    />
  );
};

const MultipleTagSelectorFieldContainer: React.FC<{
  candidateTags: CandidateTags;
  allowAdd: boolean;
  createNewTag: (name: string) => Promise<TagDTO>;
}> = ({ candidateTags, allowAdd, createNewTag }) => {
  const sdk = useFieldExtensionSDK();
  const values: EntryLink[] = sdk.field.getValue() || [];
  const [entryLinks = [], setEntryLinks] = useState<EntryLink[]>(values);

  useEffect(() => {
    const cleanup = sdk.field.onValueChanged((newValue: EntryLink[] | undefined) => {
      setEntryLinks(newValue || []);
    });
    return () => cleanup();
  }, [sdk, setEntryLinks]);

  const handleChange = (tags: TagDTO[]) => {
    sdk.field.setValue(tags.map(tagToEntryLink)).catch(e => sdk.notifier.error(e.message));
  };

  const selectedTags = entryLinks.flatMap(({ sys: { id } }) => {
    const tag = candidateTags.getTag(id);
    return tag ? [tag] : [];
  });
  if (selectedTags.length !== entryLinks.length) {
    handleChange(selectedTags);
  }

  return (
    <TagSelectorField
      selectedTags={selectedTags}
      candidateTags={candidateTags}
      allowAdd={allowAdd}
      inputDisabled={false}
      createNewTag={createNewTag}
      onChange={handleChange}
    />
  );
};

const TagSelectorField: React.FC<{
  selectedTags: TagDTO[];
  onChange: (tags: TagDTO[]) => void;
  candidateTags: CandidateTags;
  allowAdd: boolean;
  inputDisabled: boolean;
  createNewTag: (name: string) => Promise<TagDTO>;
}> = ({ onChange, selectedTags, candidateTags, inputDisabled, allowAdd, createNewTag }) => {
  const sdk = useFieldExtensionSDK();
  const [input, setInput] = useState('');
  const [adding, setAdding] = useState(false);

  const addTag = (tag: TagDTO) => {
    onChange([...selectedTags, tag]);
  };

  async function handleAddTag() {
    if (input === '') {
      sdk.notifier.error('Input tag name to add.');
    }
    setAdding(true);
    createNewTag(input)
      .then(addTag)
      .catch(e => sdk.notifier.error(`Failed to add new topic tag: ${e.message}`))
      .finally(() => setAdding(false));
  }

  return (
    <>
      {!inputDisabled && (
        <TypeAheadInput
          value={input}
          candidateTags={candidateTags}
          onChange={setInput}
          onSelect={addTag}
          excludeTagIds={new Set(selectedTags.map(({ id }) => id))}
          control={allowAdd && <AddButton onClick={handleAddTag} disabled={adding} />}
        />
      )}
      <Flex my={2} flexWrap="wrap">
        {selectedTags.map(tag => (
          <Box mr={2} mb={2} key={tag.id}>
            <Tag tag={tag} onClose={() => onChange(selectedTags.filter(({ id }) => id !== tag.id))} />
          </Box>
        ))}
      </Flex>
    </>
  );
};

export const AddButton: React.FC<{
  onClick: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled = false }) => {
  return <Button buttonType="muted" disabled={disabled} onClick={onClick} icon="Plus" />;
};
