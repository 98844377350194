type TreeNode<T> = {
  value: T;
  children: TreeNode<T>[];
};

export function buildTreeNodes<T>(
  list: T[],
  getId: (t: T) => string,
  getParentId: (t: T) => string | undefined,
): TreeNode<T>[] {
  const map = new Map<string, TreeNode<T>>();
  list.forEach(t =>
    map.set(getId(t), {
      value: t,
      children: [],
    }),
  );

  const rootNodes: TreeNode<T>[] = [];
  Array.from(map.values()).forEach(node => {
    const parentId = getParentId(node.value);
    if (!parentId) {
      rootNodes.push(node);
      return;
    }

    const parent = map.get(parentId);
    if (!parent) {
      console.error(`Parent ${getParentId(node.value)} of ${getId(node.value)} does not exist.`);
      rootNodes.push(node);
      return;
    }
    parent.children.push(node);
  });

  return rootNodes;
}

export function selectTreeNode<T>(nodes: TreeNode<T>[], f: (t: T) => boolean): TreeNode<T>[] {
  return nodes.flatMap(node => [...(f(node.value) ? [node] : []), ...selectTreeNode(node.children, f)]);
}

export function getAllNodeValues<T>(nodes: TreeNode<T>[]): T[] {
  return nodes.flatMap(({ value, children }) => [value, ...getAllNodeValues(children)]);
}
