import * as React from 'react';
import { useSidebarExtensionSDK } from 'lib/contentful-extension';
import { Button, Paragraph } from '@contentful/forma-36-react-components';
import { useEffect, useState } from 'react';
import { CrawlerTargetDef } from 'mdlinx-contentful';
import { ContentfulEntry } from 'contentful-management';
import { DateTime } from 'luxon';
import { openWindowInNewTab } from 'lib/window';

export const CrawlDetails: React.FC = () => {
  const sdk = useSidebarExtensionSDK();
  const [linkedCrawl, setLinkedCrawl] = useState({} as ContentfulEntry<CrawlerTargetDef>);
  const [sourceName, setSourceName] = useState('Loading...');

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);

  useEffect(() => {
    const crawl = sdk.entry.fields.source.getValue();

    if (!crawl) return;

    sdk.space.getEntry(crawl.sys.id).then(result => {
      const linkedCrawl = result as ContentfulEntry<CrawlerTargetDef>;
      setLinkedCrawl(linkedCrawl);
    });
  }, [sdk]);

  useEffect(() => {
    if (linkedCrawl.sys && linkedCrawl.fields.journalName) {
      setSourceName(linkedCrawl.fields.journalName['en-US']);
    }
  }, [linkedCrawl]);

  const originalUrl = sdk.entry.fields.url.getValue();
  const crawlJobID = sdk.entry.fields.scrapeJob.getValue() ?? 'None';
  const { createdAt } = sdk.entry.getSys() as any;
  const crawlDate = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_MED);

  return (
    <>
      <Paragraph>
        Source: {sourceName}
        <br />
        Crawl Date: {crawlDate}
        <br />
        Crawl Job ID: {crawlJobID}
        <br />
        <br />
      </Paragraph>
      <Button isFullWidth icon="ExternalLink" onClick={() => openWindowInNewTab(originalUrl)}>
        View Original Article
      </Button>
    </>
  );
};
