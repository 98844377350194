import { extractDataFromResponse, useErrorReporter } from '@mdlinx/frontend-shared';
import { UnarchiveContentError, useUnarchiveContentMutation } from 'generated/graphql-client-query';
import { usePageExtensionSDK } from 'lib/contentful-extension';

export function useUnarchiveContent(): (contentId: string) => Promise<void> {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [unarchiveContentMutation] = useUnarchiveContentMutation();

  return (contentId: string) => {
    function openEntry(entryId: string) {
      sdk.navigator.openEntry(entryId).catch(errorReporter.report);
    }

    function handleUnexpectedError(error: Error) {
      errorReporter.report(error);
      sdk.notifier.error('Oops! Something goes wrong.');
    }

    function handleFailure(error: UnarchiveContentError) {
      switch (error) {
        case UnarchiveContentError.ContentNotFound:
          return handleUnexpectedError(new Error(`Content ${contentId} does not exist.`));
        case UnarchiveContentError.UnsupportedContent:
          return handleUnexpectedError(new Error(`Can't unarchive this content ${contentId}`));
      }
    }

    return unarchiveContentMutation({ variables: { contentId } })
      .then(extractDataFromResponse)
      .then(({ unarchiveContent }) => {
        switch (unarchiveContent.__typename) {
          case 'UnarchiveContentSuccess':
            return openEntry(unarchiveContent.activeContentId);
          case 'UnarchiveContentFailure':
            return handleFailure(unarchiveContent.error);
        }
      });
  };
}
