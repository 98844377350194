import { HelpText, TextLink } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import {
  assigneeColumn,
  crawlDateColumn,
  openInEditorColumn,
  sourceColumn,
  specialtyColumn,
  titleColumn,
  workflowStatusColumn,
} from 'app/scenes/ui-extension/JournalSummarySelection/columns';
import {
  orderByCrawlDateReverse,
  queryForGeneralSpecialty,
  queryForSpecialtyIds,
  useSpecialtiesForJournalSummary,
} from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { useJournalSummaries } from 'app/scenes/ui-extension/JournalSummarySelection/journal-summaries';
import { NO_FILTER, SpecialtySelection } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtySelector';
import { useLocalStorageState } from 'app/shared/local-storage';
import { Pager } from 'app/shared/Pager';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';
import { ContentfulEntry } from 'contentful-management';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import { SpecialtyDef } from 'mdlinx-contentful';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';

// https://confluence.mdlinx.com/pages/viewpage.action?pageId=54037070
const targetSpecialtyNames = new Set([
  'Family Medicine',
  'Gastroenterology',
  'Internal Medicine',
  'Neurology',
  'Oncology',
  'Psychiatry',
]);

function isTargetSpecialty(specialty: ContentfulEntry<SpecialtyDef>): boolean {
  return targetSpecialtyNames.has(specialty.fields.name?.['en-US']);
}

export const SecondaryReview: React.FC = () => {
  const sdk = usePageExtensionSDK();
  const specialties = useSpecialtiesForJournalSummary();
  const errorReporter = useErrorReporter();
  const targetSpecialties = useMemo(() => specialties.filter(isTargetSpecialty), [specialties]);
  const [specialtySelection, setSpecialtySelection] = useLocalStorageState<SpecialtySelection>(
    'journal-summary-selection:secondary-review',
    NO_FILTER,
  );

  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const query = useMemo(
    () => ({
      'fields.workflowStatus[in]': ['Ready for Review', 'Review in Progress'].join(','),
      ...(() => {
        switch (specialtySelection.type) {
          case 'NO_FILTER':
            return queryForSpecialtyIds(targetSpecialties.map(s => s.sys.id));
          case 'GENERAL':
            return queryForGeneralSpecialty;
          case 'SINGLE':
            return queryForSpecialtyIds([specialtySelection.specialtyId]);
        }
      })(),
      order,
    }),
    [order, targetSpecialties, specialtySelection],
  );
  const {
    entries,
    total,
    currentPage,
    setCurrentPage,
    totalPage,
    isUpdating,
    reload,
    publishByIds,
  } = useJournalSummaries(query);

  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          titleColumn,
          specialtyColumn({
            selection: specialtySelection,
            onChange: setSpecialtySelection,
            candidates: targetSpecialties,
          }),
          workflowStatusColumn,
          assigneeColumn,
          sourceColumn,
          crawlDateColumn({ order, onChange: setOrder }),
          openInEditorColumn,
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} entries selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'positive'}
                onClick={async () => {
                  try {
                    await publishByIds(selectedEntries.map(e => e.sys.id));
                    sdk.notifier.success(`Published ${selectedEntries.length} entries`);
                  } catch (e) {
                    errorReporter.report(e);
                    sdk.notifier.error(`Failed to publish some entries`);
                  } finally {
                    reload();
                  }
                }}
                disabled={isUpdating}
              >
                Publish
              </TextLink>
            </Box>
          </Flex>
        )}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
    </>
  );
};
