import { Table, TableBody, TableCell, TableHead, TableRow } from '@contentful/forma-36-react-components';
import { EntryPriorityIcon } from 'app/scenes/ui-extension/NewsSelection/EntryPriorityIcon';
import { useNewsArticles } from 'app/scenes/ui-extension/NewsSelection/news-articles';
import { OpenInEditor } from 'app/scenes/ui-extension/NewsSelection/OpenInEditor';
import { SortIcon } from 'app/shared/SortIcon';
import { Source } from 'app/shared/Source';
import { useSourceCandidates } from 'app/shared/source-candidates';
import { useSources } from 'app/shared/sources';
import { SourceSelector } from 'app/shared/SourceSelector';
import { StickyTableHeadCell } from 'app/shared/StickyTableHeadCell';
import React, { useMemo, useState } from 'react';
import { Box } from 'rebass';
import { Pager } from '../../../shared/Pager';
import { entryCrawlDate, isHighPriority, orderByCrawlDate, orderByCrawlDateReverse } from './common';

const defaultOrder = '-fields.highPriority,fields.workflowStatus,-sys.createdAt';

export const ReviewAndPublishArticles: React.FC = () => {
  const sources = useSources();
  const [order, setOrder] = useState(defaultOrder);
  const { selectedSourceLabel, setSelectedSourceLabel, targetQuery } = useSourceCandidates(sources.all);
  const query = useMemo(
    () => ({
      'fields.workflowStatus[in]': ['Selected for Editorial', 'In Progress'].join(','),
      order,
      ...targetQuery,
    }),
    [order, targetQuery],
  );
  const { entries, currentPage, setCurrentPage, totalPage, togglePriority, isEntryUpdating } = useNewsArticles(query);

  return (
    <>
      <Table>
        <TableHead isSticky={true}>
          <TableRow>
            <StickyTableHeadCell align={'center'}>Priority</StickyTableHeadCell>
            <StickyTableHeadCell>Status</StickyTableHeadCell>
            <StickyTableHeadCell>Title</StickyTableHeadCell>
            <StickyTableHeadCell>
              <SourceSelector
                value={selectedSourceLabel}
                onChange={value => {
                  setSelectedSourceLabel(value);
                }}
              />
            </StickyTableHeadCell>
            <StickyTableHeadCell
              onClick={() => {
                if (order === orderByCrawlDateReverse) {
                  setOrder(orderByCrawlDate);
                } else {
                  setOrder(orderByCrawlDateReverse);
                }
              }}
            >
              Crawl Date
              {order === orderByCrawlDate ? (
                <SortIcon order={'asc'} />
              ) : order === orderByCrawlDateReverse ? (
                <SortIcon order={'desc'} />
              ) : null}
            </StickyTableHeadCell>
            <StickyTableHeadCell>Open in Editor</StickyTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map(entry => (
            <TableRow key={entry.sys.id}>
              <TableCell style={{ textAlign: 'center' }}>
                <EntryPriorityIcon
                  isHighPriority={isHighPriority(entry)}
                  onToggle={() => togglePriority(entry.sys.id)}
                  disabled={isEntryUpdating(entry.sys.id)}
                />
              </TableCell>
              <TableCell>{entry.fields.workflowStatus ? entry.fields.workflowStatus['en-US'] : ''}</TableCell>
              <TableCell>{entry.fields.title ? entry.fields.title['en-US'] : ''}</TableCell>
              <TableCell>{entry.fields.source ? <Source id={entry.fields.source['en-US'].sys.id} /> : ''}</TableCell>
              <TableCell>{entryCrawlDate(entry)}</TableCell>
              <TableCell>
                <OpenInEditor entry={entry} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
    </>
  );
};
