import { Card, TextInput } from '@contentful/forma-36-react-components';
import { CandidateTags } from 'app/scenes/ui-extension/TagSelector/CandidateTags';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import * as React from 'react';
import { ReactNode, useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { TagDTO } from './tag-dto';

export const TypeAheadInput: React.FC<{
  candidateTags: CandidateTags;
  value: string;
  onChange: (input: string) => void;
  onSelect: (tag: TagDTO) => void;
  excludeTagIds?: Set<string>;
  control?: ReactNode;
}> = ({ value, candidateTags, onChange, onSelect, control, excludeTagIds = new Set([]) }) => {
  const [focus, setFocus] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [suggestions, setSuggestions] = useState<TagDTO[]>([]);

  const suggest = useMemo(
    () =>
      AwesomeDebouncePromise((value: string) => {
        setSuggestions(candidateTags.suggest(value, excludeTagIds));
      }, 300),
    [candidateTags, excludeTagIds],
  );

  const handleInputChange = (value: string) => {
    onChange(value);
    setSelectedIndex(0);
    if (value === '') {
      setSuggestions([]);
    } else {
      suggest(value);
    }
  };

  const select = (index: number) => {
    const suggestion = suggestions[index];
    if (suggestion) {
      onSelect(suggestion);
      handleInputChange('');
    }
  };

  return (
    <>
      <Flex>
        <Box {...{ flexGrow: 1 }}>
          <TextInput
            value={value}
            onChange={e => handleInputChange((e.target as any).value)}
            onKeyDown={e => {
              switch (e.keyCode) {
                // Return
                case 13:
                  return select(selectedIndex);

                // Arrow Up
                case 38:
                  return setSelectedIndex(Math.max(selectedIndex - 1, 0));

                // Arrow Down
                case 40:
                  return setSelectedIndex(Math.min(selectedIndex + 1, candidateTags.suggestionSize() - 1));
              }
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        </Box>
        {control && <Box>{control}</Box>}
      </Flex>
      <div onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
        {(focus || mouseOver) &&
          suggestions.map((tag, index) => (
            <Card
              key={tag.id}
              selected={index === selectedIndex}
              onClick={e => {
                e.stopPropagation();
                select(index);
              }}
            >
              {tag.parentName ? `${tag.name} (${tag.parentName})` : tag.name}
            </Card>
          ))}
      </div>
    </>
  );
};
