/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  /**
   * RFC 3339 Date & Time string.
   * Example: 2002-10-02T10:00:00-05:00
   * Should have seconds field and time zone offset information.
   */
  DateTime: string;
  Unit: null;
};

export type ActiveContentPreview = ActiveContentPreviewFailure | LongFormContentPreview | NewsArticlePreview;

export enum ActiveContentPreviewError {
  NotFound = 'NOT_FOUND',
  UnsupportedContentType = 'UNSUPPORTED_CONTENT_TYPE'
}

export type ActiveContentPreviewFailure = {
  __typename?: 'ActiveContentPreviewFailure';
  error: ActiveContentPreviewError;
};

export enum ArchiveContentError {
  ActiveContentNotFound = 'ACTIVE_CONTENT_NOT_FOUND',
  ContentNotPublished = 'CONTENT_NOT_PUBLISHED',
  EnvironmentMismatch = 'ENVIRONMENT_MISMATCH'
}

export type ArchiveContentFailure = {
  __typename?: 'ArchiveContentFailure';
  error: ArchiveContentError;
};

export type ArchiveContentsResult = {
  __typename?: 'ArchiveContentsResult';
  archived: Scalars['Int'];
  deleted: Scalars['Int'];
};

/** Represents any content. */
export type ArchivedContent = {
  archivedAt: Scalars['DateTime'];
  body: Scalars['JSON'];
  contentType: ContentTypeId;
  /** URL path to the content. Must be unique. */
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  /** Required by resolvers */
  source: ArchivedContentSource;
  tagIDs: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type ArchivedContentEdge = {
  __typename?: 'ArchivedContentEdge';
  cursor: Scalars['String'];
  node: ArchivedContent;
};

export type ArchivedContentsFilterParams = {
  contentId?: Maybe<Scalars['ID']>;
  contentType?: Maybe<ContentTypeId>;
  titleSearch?: Maybe<Scalars['String']>;
};

export enum ArchivedContentSource {
  Contentful = 'contentful',
  Migration = 'migration'
}

export type ArchivedContentsResult = {
  __typename?: 'ArchivedContentsResult';
  edges: Array<ArchivedContentEdge>;
};

export enum ContentsOrdering {
  PublishedAt = 'PublishedAt'
}

export enum ContentTypeId {
  JournalSummary = 'journalSummary',
  LongFormContent = 'longFormContent',
  NewsArticle = 'newsArticle'
}

export enum CustomStaffRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Guest = 'Guest',
  IndiaTeamWriter = 'IndiaTeamWriter',
  Taxonomist = 'Taxonomist'
}


export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export enum IssueGoogleDocUrlError {
  AlreadyIssued = 'AlreadyIssued',
  ContentNotFound = 'ContentNotFound',
  InvalidContentType = 'InvalidContentType'
}

export type JournalSummary = ArchivedContent & {
  __typename?: 'JournalSummary';
  archivedAt: Scalars['DateTime'];
  body: Scalars['JSON'];
  contentType: ContentTypeId;
  /** URL path to the content. Must be unique. */
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  /** Required by resolvers */
  source: ArchivedContentSource;
  tagIDs: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type JournalSummaryPreview = {
  __typename?: 'JournalSummaryPreview';
  body?: Maybe<Scalars['JSON']>;
  contentType: ContentTypeId;
  title?: Maybe<Scalars['String']>;
};


export type LongFormContent = ArchivedContent & {
  __typename?: 'LongFormContent';
  archivedAt: Scalars['DateTime'];
  body: Scalars['JSON'];
  contentType: ContentTypeId;
  /** URL path to the content. Must be unique. */
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  /** Required by resolvers */
  source: ArchivedContentSource;
  tagIDs: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type LongFormContentPreview = {
  __typename?: 'LongFormContentPreview';
  body?: Maybe<Scalars['JSON']>;
  contentType: ContentTypeId;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  beginSummary?: Maybe<Scalars['Unit']>;
  cancelSummary?: Maybe<Scalars['Unit']>;
  deleteStaffMember?: Maybe<Scalars['Unit']>;
  requestTopicTag: RequestTopicTagResult;
  /**
   * Allow summary submission from India team translators. Workaround to let them:
   * 1) change workflow status and translatable fields in en-US since they normally only
   * have permissions to change the en-IN translation of translatable fields, and
   * 2) publish, when the specialty for the journal summary does not require extra
   * review.
   */
  submitSummary?: Maybe<Scalars['Unit']>;
  /** Restore archived content in contentful. */
  unarchiveContent: UnarchiveContentResult;
  updateStaffMember: UpdateStaffMemberResult;
};


export type MutationBeginSummaryArgs = {
  assigneeUserId: Scalars['String'];
  contentId: Scalars['ID'];
};


export type MutationCancelSummaryArgs = {
  contentId: Scalars['ID'];
};


export type MutationDeleteStaffMemberArgs = {
  uid: Scalars['ID'];
};


export type MutationRequestTopicTagArgs = {
  input: RequestTopicTagInput;
};


export type MutationSubmitSummaryArgs = {
  contentId: Scalars['ID'];
  needsExtraReview?: Maybe<Scalars['Boolean']>;
};


export type MutationUnarchiveContentArgs = {
  contentId: Scalars['ID'];
};


export type MutationUpdateStaffMemberArgs = {
  input: UpdateStaffMemberInput;
};

export type NewsArticle = ArchivedContent & {
  __typename?: 'NewsArticle';
  archivedAt: Scalars['DateTime'];
  body: Scalars['JSON'];
  contentType: ContentTypeId;
  /** URL path to the content. Must be unique. */
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  /** Required by resolvers */
  source: ArchivedContentSource;
  sourceUrl: Scalars['String'];
  tagIDs: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type NewsArticlePreview = {
  __typename?: 'NewsArticlePreview';
  body?: Maybe<Scalars['JSON']>;
  contentType: ContentTypeId;
  sourceUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PublicSchema = {
  __typename?: 'PublicSchema';
  mutation: Array<Scalars['String']>;
  query: Array<Scalars['String']>;
  subscription: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeContentPreview: ActiveContentPreview;
  /** Get one content by path of URL. */
  archivedContent?: Maybe<ArchivedContent>;
  /** Search contents by set of tags. Resulted content should contains all tags. */
  archivedContents: ArchivedContentsResult;
  me: StaffMember;
  publicSchema: PublicSchema;
  slackUsers: Array<SlackUser>;
  staffMembers: Array<StaffMember>;
};


export type QueryActiveContentPreviewArgs = {
  id: Scalars['ID'];
};


export type QueryArchivedContentArgs = {
  id: Scalars['ID'];
};


export type QueryArchivedContentsArgs = {
  cursor?: Maybe<Scalars['String']>;
  filterParams?: Maybe<ArchivedContentsFilterParams>;
  first?: Maybe<Scalars['Int']>;
  order?: Maybe<ContentsOrdering>;
  tagIDs?: Maybe<Array<Scalars['ID']>>;
};

export type RequestTopicTagInput = {
  name: Scalars['String'];
};

export type RequestTopicTagResult = {
  __typename?: 'RequestTopicTagResult';
  topicTagId: Scalars['ID'];
};

export type SlackUser = {
  __typename?: 'SlackUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type StaffMember = {
  __typename?: 'StaffMember';
  customRole: CustomStaffRole;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  slackUser?: Maybe<SlackUser>;
  uid: Scalars['ID'];
};

export enum UnarchiveContentError {
  ContentNotFound = 'CONTENT_NOT_FOUND',
  UnsupportedContent = 'UNSUPPORTED_CONTENT'
}

export type UnarchiveContentFailure = {
  __typename?: 'UnarchiveContentFailure';
  error: UnarchiveContentError;
};

export type UnarchiveContentResult = UnarchiveContentFailure | UnarchiveContentSuccess;

export type UnarchiveContentSuccess = {
  __typename?: 'UnarchiveContentSuccess';
  activeContentId: Scalars['String'];
  editPageUrl: Scalars['String'];
};


export type UpdateStaffMemberInput = {
  customRole: CustomStaffRole;
  slackUserId?: Maybe<Scalars['ID']>;
  uid: Scalars['ID'];
};

export enum UpdateStaffMemberResult {
  StaffMemberNotFound = 'StaffMemberNotFound',
  Succeeded = 'Succeeded'
}

export type UnarchiveContentMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type UnarchiveContentMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveContent: (
    { __typename?: 'UnarchiveContentFailure' }
    & Pick<UnarchiveContentFailure, 'error'>
  ) | (
    { __typename?: 'UnarchiveContentSuccess' }
    & Pick<UnarchiveContentSuccess, 'activeContentId' | 'editPageUrl'>
  ) }
);

export type UpdateStaffMemberMutationVariables = Exact<{
  input: UpdateStaffMemberInput;
}>;


export type UpdateStaffMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStaffMember'>
);

export type DeleteStaffMemberMutationVariables = Exact<{
  uid: Scalars['ID'];
}>;


export type DeleteStaffMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStaffMember'>
);

export type RequestTopicTagMutationVariables = Exact<{
  input: RequestTopicTagInput;
}>;


export type RequestTopicTagMutation = (
  { __typename?: 'Mutation' }
  & { requestTopicTag: (
    { __typename?: 'RequestTopicTagResult' }
    & Pick<RequestTopicTagResult, 'topicTagId'>
  ) }
);

export type SubmitSummaryMutationVariables = Exact<{
  contentId: Scalars['ID'];
  needsExtraReview?: Maybe<Scalars['Boolean']>;
}>;


export type SubmitSummaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitSummary'>
);

export type BeginSummaryMutationVariables = Exact<{
  contentId: Scalars['ID'];
  assigneeUserId: Scalars['String'];
}>;


export type BeginSummaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'beginSummary'>
);

export type CancelSummaryMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type CancelSummaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSummary'>
);

export type ActiveContentPreviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActiveContentPreviewQuery = (
  { __typename?: 'Query' }
  & { activeContentPreview: (
    { __typename?: 'ActiveContentPreviewFailure' }
    & Pick<ActiveContentPreviewFailure, 'error'>
  ) | (
    { __typename?: 'LongFormContentPreview' }
    & Pick<LongFormContentPreview, 'title' | 'body'>
  ) | (
    { __typename?: 'NewsArticlePreview' }
    & Pick<NewsArticlePreview, 'title' | 'sourceUrl' | 'body'>
  ) }
);

export type ArchivedContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchivedContentQuery = (
  { __typename?: 'Query' }
  & { archivedContent?: Maybe<(
    { __typename?: 'JournalSummary' }
    & Pick<JournalSummary, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
  ) | (
    { __typename?: 'LongFormContent' }
    & Pick<LongFormContent, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
  ) | (
    { __typename?: 'NewsArticle' }
    & Pick<NewsArticle, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
  )> }
);

export type ArchivedContentsQueryVariables = Exact<{
  filterParams?: Maybe<ArchivedContentsFilterParams>;
}>;


export type ArchivedContentsQuery = (
  { __typename?: 'Query' }
  & { archivedContents: (
    { __typename?: 'ArchivedContentsResult' }
    & { edges: Array<(
      { __typename?: 'ArchivedContentEdge' }
      & { node: (
        { __typename?: 'JournalSummary' }
        & Pick<JournalSummary, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
      ) | (
        { __typename?: 'LongFormContent' }
        & Pick<LongFormContent, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
      ) | (
        { __typename?: 'NewsArticle' }
        & Pick<NewsArticle, 'id' | 'source' | 'title' | 'contentType' | 'publishedAt' | 'archivedAt'>
      ) }
    )> }
  ) }
);

export type StaffMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffMembersQuery = (
  { __typename?: 'Query' }
  & { staffMembers: Array<(
    { __typename?: 'StaffMember' }
    & Pick<StaffMember, 'uid' | 'firstName' | 'lastName' | 'email' | 'role' | 'customRole'>
    & { slackUser?: Maybe<(
      { __typename?: 'SlackUser' }
      & Pick<SlackUser, 'id' | 'name'>
    )> }
  )> }
);

export type SlackUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type SlackUsersQuery = (
  { __typename?: 'Query' }
  & { slackUsers: Array<(
    { __typename?: 'SlackUser' }
    & Pick<SlackUser, 'id' | 'name'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'StaffMember' }
    & Pick<StaffMember, 'uid' | 'firstName' | 'lastName' | 'email' | 'role' | 'customRole'>
  ) }
);


export const UnarchiveContentDocument = gql`
    mutation UnarchiveContent($contentId: ID!) {
  unarchiveContent(contentId: $contentId) {
    ... on UnarchiveContentSuccess {
      activeContentId
      editPageUrl
    }
    ... on UnarchiveContentFailure {
      error
    }
  }
}
    `;
export type UnarchiveContentMutationFn = Apollo.MutationFunction<UnarchiveContentMutation, UnarchiveContentMutationVariables>;
export type UnarchiveContentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnarchiveContentMutation, UnarchiveContentMutationVariables>, 'mutation'>;

    export const UnarchiveContentComponent = (props: UnarchiveContentComponentProps) => (
      <ApolloReactComponents.Mutation<UnarchiveContentMutation, UnarchiveContentMutationVariables> mutation={UnarchiveContentDocument} {...props} />
    );
    

/**
 * __useUnarchiveContentMutation__
 *
 * To run a mutation, you first call `useUnarchiveContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveContentMutation, { data, loading, error }] = useUnarchiveContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useUnarchiveContentMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveContentMutation, UnarchiveContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveContentMutation, UnarchiveContentMutationVariables>(UnarchiveContentDocument, options);
      }
export type UnarchiveContentMutationHookResult = ReturnType<typeof useUnarchiveContentMutation>;
export type UnarchiveContentMutationResult = Apollo.MutationResult<UnarchiveContentMutation>;
export type UnarchiveContentMutationOptions = Apollo.BaseMutationOptions<UnarchiveContentMutation, UnarchiveContentMutationVariables>;
export const UpdateStaffMemberDocument = gql`
    mutation UpdateStaffMember($input: UpdateStaffMemberInput!) {
  updateStaffMember(input: $input)
}
    `;
export type UpdateStaffMemberMutationFn = Apollo.MutationFunction<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables>;
export type UpdateStaffMemberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables>, 'mutation'>;

    export const UpdateStaffMemberComponent = (props: UpdateStaffMemberComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables> mutation={UpdateStaffMemberDocument} {...props} />
    );
    

/**
 * __useUpdateStaffMemberMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMemberMutation, { data, loading, error }] = useUpdateStaffMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables>(UpdateStaffMemberDocument, options);
      }
export type UpdateStaffMemberMutationHookResult = ReturnType<typeof useUpdateStaffMemberMutation>;
export type UpdateStaffMemberMutationResult = Apollo.MutationResult<UpdateStaffMemberMutation>;
export type UpdateStaffMemberMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMemberMutation, UpdateStaffMemberMutationVariables>;
export const DeleteStaffMemberDocument = gql`
    mutation DeleteStaffMember($uid: ID!) {
  deleteStaffMember(uid: $uid)
}
    `;
export type DeleteStaffMemberMutationFn = Apollo.MutationFunction<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables>;
export type DeleteStaffMemberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables>, 'mutation'>;

    export const DeleteStaffMemberComponent = (props: DeleteStaffMemberComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables> mutation={DeleteStaffMemberDocument} {...props} />
    );
    

/**
 * __useDeleteStaffMemberMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMemberMutation, { data, loading, error }] = useDeleteStaffMemberMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteStaffMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables>(DeleteStaffMemberDocument, options);
      }
export type DeleteStaffMemberMutationHookResult = ReturnType<typeof useDeleteStaffMemberMutation>;
export type DeleteStaffMemberMutationResult = Apollo.MutationResult<DeleteStaffMemberMutation>;
export type DeleteStaffMemberMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMemberMutation, DeleteStaffMemberMutationVariables>;
export const RequestTopicTagDocument = gql`
    mutation RequestTopicTag($input: RequestTopicTagInput!) {
  requestTopicTag(input: $input) {
    topicTagId
  }
}
    `;
export type RequestTopicTagMutationFn = Apollo.MutationFunction<RequestTopicTagMutation, RequestTopicTagMutationVariables>;
export type RequestTopicTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RequestTopicTagMutation, RequestTopicTagMutationVariables>, 'mutation'>;

    export const RequestTopicTagComponent = (props: RequestTopicTagComponentProps) => (
      <ApolloReactComponents.Mutation<RequestTopicTagMutation, RequestTopicTagMutationVariables> mutation={RequestTopicTagDocument} {...props} />
    );
    

/**
 * __useRequestTopicTagMutation__
 *
 * To run a mutation, you first call `useRequestTopicTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTopicTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTopicTagMutation, { data, loading, error }] = useRequestTopicTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTopicTagMutation(baseOptions?: Apollo.MutationHookOptions<RequestTopicTagMutation, RequestTopicTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestTopicTagMutation, RequestTopicTagMutationVariables>(RequestTopicTagDocument, options);
      }
export type RequestTopicTagMutationHookResult = ReturnType<typeof useRequestTopicTagMutation>;
export type RequestTopicTagMutationResult = Apollo.MutationResult<RequestTopicTagMutation>;
export type RequestTopicTagMutationOptions = Apollo.BaseMutationOptions<RequestTopicTagMutation, RequestTopicTagMutationVariables>;
export const SubmitSummaryDocument = gql`
    mutation SubmitSummary($contentId: ID!, $needsExtraReview: Boolean) {
  submitSummary(contentId: $contentId, needsExtraReview: $needsExtraReview)
}
    `;
export type SubmitSummaryMutationFn = Apollo.MutationFunction<SubmitSummaryMutation, SubmitSummaryMutationVariables>;
export type SubmitSummaryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitSummaryMutation, SubmitSummaryMutationVariables>, 'mutation'>;

    export const SubmitSummaryComponent = (props: SubmitSummaryComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitSummaryMutation, SubmitSummaryMutationVariables> mutation={SubmitSummaryDocument} {...props} />
    );
    

/**
 * __useSubmitSummaryMutation__
 *
 * To run a mutation, you first call `useSubmitSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSummaryMutation, { data, loading, error }] = useSubmitSummaryMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      needsExtraReview: // value for 'needsExtraReview'
 *   },
 * });
 */
export function useSubmitSummaryMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSummaryMutation, SubmitSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSummaryMutation, SubmitSummaryMutationVariables>(SubmitSummaryDocument, options);
      }
export type SubmitSummaryMutationHookResult = ReturnType<typeof useSubmitSummaryMutation>;
export type SubmitSummaryMutationResult = Apollo.MutationResult<SubmitSummaryMutation>;
export type SubmitSummaryMutationOptions = Apollo.BaseMutationOptions<SubmitSummaryMutation, SubmitSummaryMutationVariables>;
export const BeginSummaryDocument = gql`
    mutation BeginSummary($contentId: ID!, $assigneeUserId: String!) {
  beginSummary(contentId: $contentId, assigneeUserId: $assigneeUserId)
}
    `;
export type BeginSummaryMutationFn = Apollo.MutationFunction<BeginSummaryMutation, BeginSummaryMutationVariables>;
export type BeginSummaryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BeginSummaryMutation, BeginSummaryMutationVariables>, 'mutation'>;

    export const BeginSummaryComponent = (props: BeginSummaryComponentProps) => (
      <ApolloReactComponents.Mutation<BeginSummaryMutation, BeginSummaryMutationVariables> mutation={BeginSummaryDocument} {...props} />
    );
    

/**
 * __useBeginSummaryMutation__
 *
 * To run a mutation, you first call `useBeginSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginSummaryMutation, { data, loading, error }] = useBeginSummaryMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      assigneeUserId: // value for 'assigneeUserId'
 *   },
 * });
 */
export function useBeginSummaryMutation(baseOptions?: Apollo.MutationHookOptions<BeginSummaryMutation, BeginSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginSummaryMutation, BeginSummaryMutationVariables>(BeginSummaryDocument, options);
      }
export type BeginSummaryMutationHookResult = ReturnType<typeof useBeginSummaryMutation>;
export type BeginSummaryMutationResult = Apollo.MutationResult<BeginSummaryMutation>;
export type BeginSummaryMutationOptions = Apollo.BaseMutationOptions<BeginSummaryMutation, BeginSummaryMutationVariables>;
export const CancelSummaryDocument = gql`
    mutation CancelSummary($contentId: ID!) {
  cancelSummary(contentId: $contentId)
}
    `;
export type CancelSummaryMutationFn = Apollo.MutationFunction<CancelSummaryMutation, CancelSummaryMutationVariables>;
export type CancelSummaryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelSummaryMutation, CancelSummaryMutationVariables>, 'mutation'>;

    export const CancelSummaryComponent = (props: CancelSummaryComponentProps) => (
      <ApolloReactComponents.Mutation<CancelSummaryMutation, CancelSummaryMutationVariables> mutation={CancelSummaryDocument} {...props} />
    );
    

/**
 * __useCancelSummaryMutation__
 *
 * To run a mutation, you first call `useCancelSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSummaryMutation, { data, loading, error }] = useCancelSummaryMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useCancelSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CancelSummaryMutation, CancelSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSummaryMutation, CancelSummaryMutationVariables>(CancelSummaryDocument, options);
      }
export type CancelSummaryMutationHookResult = ReturnType<typeof useCancelSummaryMutation>;
export type CancelSummaryMutationResult = Apollo.MutationResult<CancelSummaryMutation>;
export type CancelSummaryMutationOptions = Apollo.BaseMutationOptions<CancelSummaryMutation, CancelSummaryMutationVariables>;
export const ActiveContentPreviewDocument = gql`
    query ActiveContentPreview($id: ID!) {
  activeContentPreview(id: $id) {
    ... on NewsArticlePreview {
      title
      sourceUrl
      body
    }
    ... on LongFormContentPreview {
      title
      body
    }
    ... on ActiveContentPreviewFailure {
      error
    }
  }
}
    `;
export type ActiveContentPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>, 'query'> & ({ variables: ActiveContentPreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActiveContentPreviewComponent = (props: ActiveContentPreviewComponentProps) => (
      <ApolloReactComponents.Query<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables> query={ActiveContentPreviewDocument} {...props} />
    );
    

/**
 * __useActiveContentPreviewQuery__
 *
 * To run a query within a React component, call `useActiveContentPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveContentPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveContentPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveContentPreviewQuery(baseOptions: Apollo.QueryHookOptions<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>(ActiveContentPreviewDocument, options);
      }
export function useActiveContentPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>(ActiveContentPreviewDocument, options);
        }
export type ActiveContentPreviewQueryHookResult = ReturnType<typeof useActiveContentPreviewQuery>;
export type ActiveContentPreviewLazyQueryHookResult = ReturnType<typeof useActiveContentPreviewLazyQuery>;
export type ActiveContentPreviewQueryResult = Apollo.QueryResult<ActiveContentPreviewQuery, ActiveContentPreviewQueryVariables>;
export const ArchivedContentDocument = gql`
    query ArchivedContent($id: ID!) {
  archivedContent(id: $id) {
    id
    source
    title
    contentType
    publishedAt
    archivedAt
  }
}
    `;
export type ArchivedContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArchivedContentQuery, ArchivedContentQueryVariables>, 'query'> & ({ variables: ArchivedContentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArchivedContentComponent = (props: ArchivedContentComponentProps) => (
      <ApolloReactComponents.Query<ArchivedContentQuery, ArchivedContentQueryVariables> query={ArchivedContentDocument} {...props} />
    );
    

/**
 * __useArchivedContentQuery__
 *
 * To run a query within a React component, call `useArchivedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivedContentQuery(baseOptions: Apollo.QueryHookOptions<ArchivedContentQuery, ArchivedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivedContentQuery, ArchivedContentQueryVariables>(ArchivedContentDocument, options);
      }
export function useArchivedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivedContentQuery, ArchivedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivedContentQuery, ArchivedContentQueryVariables>(ArchivedContentDocument, options);
        }
export type ArchivedContentQueryHookResult = ReturnType<typeof useArchivedContentQuery>;
export type ArchivedContentLazyQueryHookResult = ReturnType<typeof useArchivedContentLazyQuery>;
export type ArchivedContentQueryResult = Apollo.QueryResult<ArchivedContentQuery, ArchivedContentQueryVariables>;
export const ArchivedContentsDocument = gql`
    query ArchivedContents($filterParams: ArchivedContentsFilterParams) {
  archivedContents(order: PublishedAt, filterParams: $filterParams) {
    edges {
      node {
        id
        source
        title
        contentType
        publishedAt
        archivedAt
      }
    }
  }
}
    `;
export type ArchivedContentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArchivedContentsQuery, ArchivedContentsQueryVariables>, 'query'>;

    export const ArchivedContentsComponent = (props: ArchivedContentsComponentProps) => (
      <ApolloReactComponents.Query<ArchivedContentsQuery, ArchivedContentsQueryVariables> query={ArchivedContentsDocument} {...props} />
    );
    

/**
 * __useArchivedContentsQuery__
 *
 * To run a query within a React component, call `useArchivedContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedContentsQuery({
 *   variables: {
 *      filterParams: // value for 'filterParams'
 *   },
 * });
 */
export function useArchivedContentsQuery(baseOptions?: Apollo.QueryHookOptions<ArchivedContentsQuery, ArchivedContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivedContentsQuery, ArchivedContentsQueryVariables>(ArchivedContentsDocument, options);
      }
export function useArchivedContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivedContentsQuery, ArchivedContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivedContentsQuery, ArchivedContentsQueryVariables>(ArchivedContentsDocument, options);
        }
export type ArchivedContentsQueryHookResult = ReturnType<typeof useArchivedContentsQuery>;
export type ArchivedContentsLazyQueryHookResult = ReturnType<typeof useArchivedContentsLazyQuery>;
export type ArchivedContentsQueryResult = Apollo.QueryResult<ArchivedContentsQuery, ArchivedContentsQueryVariables>;
export const StaffMembersDocument = gql`
    query StaffMembers {
  staffMembers {
    uid
    firstName
    lastName
    email
    role
    customRole
    slackUser {
      id
      name
    }
  }
}
    `;
export type StaffMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffMembersQuery, StaffMembersQueryVariables>, 'query'>;

    export const StaffMembersComponent = (props: StaffMembersComponentProps) => (
      <ApolloReactComponents.Query<StaffMembersQuery, StaffMembersQueryVariables> query={StaffMembersDocument} {...props} />
    );
    

/**
 * __useStaffMembersQuery__
 *
 * To run a query within a React component, call `useStaffMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffMembersQuery(baseOptions?: Apollo.QueryHookOptions<StaffMembersQuery, StaffMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffMembersQuery, StaffMembersQueryVariables>(StaffMembersDocument, options);
      }
export function useStaffMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffMembersQuery, StaffMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffMembersQuery, StaffMembersQueryVariables>(StaffMembersDocument, options);
        }
export type StaffMembersQueryHookResult = ReturnType<typeof useStaffMembersQuery>;
export type StaffMembersLazyQueryHookResult = ReturnType<typeof useStaffMembersLazyQuery>;
export type StaffMembersQueryResult = Apollo.QueryResult<StaffMembersQuery, StaffMembersQueryVariables>;
export const SlackUsersDocument = gql`
    query SlackUsers {
  slackUsers {
    id
    name
  }
}
    `;
export type SlackUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SlackUsersQuery, SlackUsersQueryVariables>, 'query'>;

    export const SlackUsersComponent = (props: SlackUsersComponentProps) => (
      <ApolloReactComponents.Query<SlackUsersQuery, SlackUsersQueryVariables> query={SlackUsersDocument} {...props} />
    );
    

/**
 * __useSlackUsersQuery__
 *
 * To run a query within a React component, call `useSlackUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSlackUsersQuery(baseOptions?: Apollo.QueryHookOptions<SlackUsersQuery, SlackUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlackUsersQuery, SlackUsersQueryVariables>(SlackUsersDocument, options);
      }
export function useSlackUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlackUsersQuery, SlackUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlackUsersQuery, SlackUsersQueryVariables>(SlackUsersDocument, options);
        }
export type SlackUsersQueryHookResult = ReturnType<typeof useSlackUsersQuery>;
export type SlackUsersLazyQueryHookResult = ReturnType<typeof useSlackUsersLazyQuery>;
export type SlackUsersQueryResult = Apollo.QueryResult<SlackUsersQuery, SlackUsersQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    uid
    firstName
    lastName
    email
    role
    customRole
  }
}
    `;
export type MeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>, 'query'>;

    export const MeComponent = (props: MeComponentProps) => (
      <ApolloReactComponents.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
    );
    

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;