import axios from 'axios';
import { Button } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import { useSidebarExtensionSDK } from 'lib/contentful-extension';
import { entryIdToLink, formatEntryForAutotagAPI } from 'lib/entries';
import React, { useEffect, useState } from 'react';

const workflowStatus = 'Selected for Editorial';
type RecommendState = 'RECOMMENDED' | 'RECOMMENDING' | 'FAILED';

const contentTypeMap: { [key: string]: string } = {
  newsArticle: 'news',
  journalSummary: 'journal_summaries',
  longFormContent: 'longform_content',
};

const getTopicTagRecommendations = async (sdkEntry: any, sdkContentType: string): Promise<any> => {
  const entry = formatEntryForAutotagAPI(sdkEntry, sdkContentType);
  const contentType = contentTypeMap[sdkContentType];

  const response = await axios(`${process.env.REACT_APP_AUTOTAG_API_BASE_URL}/content/${contentType}/autotag`, {
    method: 'post',
    data: entry,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `APIKEY ${process.env.REACT_APP_AUTOTAG_API_KEY}`,
    },
  });
  if (response.status === 200) {
    const data = await response.data;
    return data;
  }

  throw new Error('Tag recommender failed!');
};

export const TagRecommenderButton = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const [recommendState, setRecommendState] = useState<RecommendState>('RECOMMENDED');

  const handleRecommendTopicTags = async () => {
    try {
      setRecommendState('RECOMMENDING');
      sdk.notifier.success('Setting topic tag fields according to recommender...');
      const data = await getTopicTagRecommendations(sdk.entry, sdk.contentType.sys.id);
      if (data) {
        sdk.notifier.success(data.message);

        const primaryTopicTagId = data.primary_topic_tag;
        if (primaryTopicTagId) {
          sdk.entry.fields.primaryTopicTag.setValue(entryIdToLink(primaryTopicTagId));
        }
        const otherTopicTagIds = data.other_topic_tags;
        const otherTopicTags = [];
        for (const topicTagId of otherTopicTagIds) {
          if (topicTagId !== primaryTopicTagId) {
            otherTopicTags.push(entryIdToLink(topicTagId));
          }
        }
        if (otherTopicTags) {
          sdk.entry.fields.otherTopicTags.setValue(otherTopicTags);
        }
        sdk.entry.fields.isAutotagged.setValue(true);
        setRecommendState('RECOMMENDED');
      } else {
        // Re-enable the button under certain circumstances?
        setRecommendState('FAILED');
      }
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error(`System error: ${e.message}`);
      setRecommendState('FAILED');
    }
  };

  // If tags have already been recommended, don't do it again:
  const autotagged = sdk.entry.fields.isAutotagged.getValue();
  return (
    <Button
      onClick={handleRecommendTopicTags}
      isFullWidth
      buttonType={autotagged ? 'muted' : 'positive'}
      disabled={autotagged || recommendState === 'RECOMMENDING'}
      loading={recommendState === 'RECOMMENDING'}
    >
      {autotagged ? 'Topic Tags Recommended' : 'Recommend Topic Tags'}
    </Button>
  );
};
