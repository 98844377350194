import { HelpText, IconButton, Modal, TextLink } from '@contentful/forma-36-react-components';
import { EntryPriorityIcon } from 'app/scenes/ui-extension/NewsSelection/EntryPriorityIcon';
import { useNewsArticles } from 'app/scenes/ui-extension/NewsSelection/news-articles';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';
import { SortIcon } from 'app/shared/SortIcon';
import { Source } from 'app/shared/Source';
import { useSourceCandidates } from 'app/shared/source-candidates';
import { useSources } from 'app/shared/sources';
import { SourceSelector } from 'app/shared/SourceSelector';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { Pager } from '../../../shared/Pager';

import { entryCrawlDate, isHighPriority, orderByCrawlDate, orderByCrawlDateReverse } from './common';
import { EntryPreview } from './EntryPreview';
import { OpenInEditor } from './OpenInEditor';

export const UnreleasedArticles: React.FC = () => {
  const sources = useSources();
  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const { selectedSourceLabel, setSelectedSourceLabel, targetQuery } = useSourceCandidates(sources.all);
  const query = useMemo(
    () => ({
      'fields.workflowStatus': 'Crawler Output',
      order,
      ...targetQuery,
    }),
    [order, targetQuery],
  );
  const {
    total,
    currentPage,
    totalPage,
    setCurrentPage,
    entries,
    isUpdating,
    isEntryUpdating,
    reject,
    releaseToEditorial,
    togglePriority,
  } = useNewsArticles(query);

  const [previewEntryId, setPreviewEntryId] = useState<string | undefined>(undefined);

  const previewEntry = useMemo(() => {
    return entries.find(e => e.sys.id === previewEntryId);
  }, [previewEntryId, entries]);

  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          {
            header: 'Priority',
            value: ({ entry }) => (
              <EntryPriorityIcon
                isHighPriority={isHighPriority(entry)}
                onToggle={() => togglePriority(entry.sys.id)}
                disabled={isEntryUpdating(entry.sys.id)}
              />
            ),
          },
          {
            header: 'Title',
            value: ({ entry }) => (entry.fields.title ? entry.fields.title['en-US'] : ''),
          },
          {
            header: 'Preview Article',
            value: ({ entry }) => (
              <IconButton
                label={'Preview'}
                iconProps={{ icon: 'Entry' }}
                buttonType={'secondary'}
                onClick={() => setPreviewEntryId(entry.sys.id)}
              />
            ),
          },
          {
            header: (
              <SourceSelector
                value={selectedSourceLabel}
                onChange={value => {
                  setSelectedSourceLabel(value);
                }}
              />
            ),
            value: ({ entry }) => (entry.fields.source ? <Source id={entry.fields.source['en-US'].sys.id} /> : ''),
          },
          {
            header: (
              <>
                Crawl Date
                {order === orderByCrawlDate ? (
                  <SortIcon order={'asc'} />
                ) : order === orderByCrawlDateReverse ? (
                  <SortIcon order={'desc'} />
                ) : null}
              </>
            ),
            value: ({ entry }) => entryCrawlDate(entry),
            onClick() {
              if (order === orderByCrawlDateReverse) {
                setOrder(orderByCrawlDate);
              } else {
                setOrder(orderByCrawlDateReverse);
              }
            },
          },
          {
            header: 'View in Editor',
            value: ({ entry }) => <OpenInEditor entry={entry} />,
          },
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} article selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'positive'}
                onClick={() => releaseToEditorial(selectedEntries.map(e => e.sys.id))}
                disabled={isUpdating}
              >
                Release to Editorial
              </TextLink>
            </Box>
            <Box mx={1}>
              <TextLink
                linkType={'negative'}
                onClick={() => reject(selectedEntries.map(e => e.sys.id))}
                disabled={isUpdating}
              >
                Reject
              </TextLink>
            </Box>
          </Flex>
        )}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
      <Modal
        title={`Preview: ${previewEntry && previewEntry.fields.title ? previewEntry.fields.title['en-US'] : ''}`}
        isShown={!!previewEntry}
        onClose={() => setPreviewEntryId(undefined)}
        size={'fullWidth'}
        shouldCloseOnEscapePress={true}
        shouldCloseOnOverlayClick={true}
      >
        {previewEntry ? (
          <EntryPreview
            entry={previewEntry}
            onTogglePriority={() => togglePriority(previewEntry.sys.id)}
            onReleaseToEditorial={() => releaseToEditorial([previewEntry.sys.id])}
            onReject={() => reject([previewEntry.sys.id])}
            updating={isEntryUpdating(previewEntry.sys.id)}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
