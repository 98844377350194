import { useErrorReporter } from '@mdlinx/frontend-shared';
import { ContentfulDef, ContentfulEntry } from 'contentful-management';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import { entryId } from 'lib/entries';
import { useEffect, useState } from 'react';

export type PagedEntries<T extends ContentfulDef> = {
  entries: ContentfulEntry<T>[];

  replaceEntry: (t: ContentfulEntry<T>) => void;

  total: number | undefined;

  currentPage: number;

  totalPage: number;

  setCurrentPage: (page: number) => void;

  reload: () => void;
};

const pageSize = 40;

export function usePagedEntries<T extends ContentfulDef>(query: { [key: string]: string }): PagedEntries<T> {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [entries, setEntries] = useState<ContentfulEntry<T>[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [reloadCount, setReloadCount] = useState(0);

  useEffect(() => {
    sdk.space
      .getEntries({
        ...query,
        skip: (currentPage - 1) * pageSize,
        limit: pageSize,
      })
      .then(({ items, total }) => {
        setEntries(items as ContentfulEntry<T>[]);
        setTotal(total);
      })
      .catch(e => {
        errorReporter.report(e);
        sdk.notifier.error('Failed to load entries for some reason. Try reload.');
      });
  }, [sdk, query, errorReporter, currentPage, setEntries, setTotal, reloadCount]);

  const reload = () => setReloadCount(reloadCount + 1);

  const replaceEntry = (t: ContentfulEntry<T>) => {
    const targetEntryId = entryId(t);
    setEntries(entries.map(e => (entryId(e) === targetEntryId ? t : e)));
  };

  return {
    entries,
    total,
    currentPage,
    totalPage: Math.ceil((total || 1) / pageSize),
    setCurrentPage,
    reload,
    replaceEntry,
  };
}
