import React, { useMemo } from 'react';
import { useCuratedCollectionLists } from './CuratedCollectionListsProvider';
import { useSpecialties } from './SpecialtiesProvider';

export const CurrentListsLabel: React.FC<{ entryId: string }> = ({ entryId }) => {
  const specialties = useSpecialties();
  const curatedCollectionLists = useCuratedCollectionLists();

  const findSpecialtyNameFromTitle = (title: string) => {
    for (const specialty of specialties) {
      const specialtyName = specialty.fields.name['en-US'];
      if (title.includes(specialtyName)) {
        return specialtyName;
      }
    }

    return 'Unknown';
  };

  const entryToListSpecialties = useMemo(() => {
    const mappedEntries = new Map<string, Set<string>>();
    for (const list of curatedCollectionLists) {
      const listSpecialtyName =
        findSpecialtyNameFromTitle(list.fields.title['en-US']) + (list.sys.firstPublishedAt ? '' : '*');

      for (const entry of list.fields.entries['en-US']) {
        const entryId = entry.sys.id;
        if (mappedEntries.has(entryId)) {
          mappedEntries.get(entryId)?.add(listSpecialtyName);
        } else {
          const titleSet = new Set<string>();
          titleSet.add(listSpecialtyName);
          mappedEntries.set(entryId, titleSet);
        }
      }
    }

    return mappedEntries;
  }, [curatedCollectionLists, specialties]);

  const specialtiesForEntry = Array.from(entryToListSpecialties.get(entryId) ?? []);

  return <>{specialtiesForEntry.join(', ')}</>;
};
