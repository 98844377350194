import { Tab, Tabs } from '@contentful/forma-36-react-components';
import Workbench from '@contentful/forma-36-react-components/dist/components/Workbench/Workbench';
import { useLocalStorage } from 'app/shared/local-storage';
import { SourcesProvider } from 'app/shared/sources';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import React, { useState } from 'react';
import { Box } from 'rebass';
import { RecycleBin } from './RecycleBin';
import { ReviewAndPublishArticles } from './ReviewAndPublishArticles';
import { UnreleasedArticles } from './UnreleasedArticles';

const tabs = [
  { id: 'Unreleased Articles', component: UnreleasedArticles },
  { id: 'Review & Publish Articles', component: ReviewAndPublishArticles },
  { id: 'Recycle Bin', component: RecycleBin },
];

export const NewsSelection: React.FC = () => {
  const sdk = usePageExtensionSDK();

  const selectedTabIdStorage = useLocalStorage<string>('news-selection:selected-tab-id');

  const [selectedTabId, setSelectedTabId] = useState(selectedTabIdStorage.get() ?? tabs[0].id);
  const selectTab = (id: string) => {
    setSelectedTabId(id);
    selectedTabIdStorage.set(id);
  };

  const SelectedTab = tabs.find(tab => tab.id === selectedTabId)!.component;
  return (
    <SourcesProvider sdk={sdk}>
      <Workbench>
        <Workbench.Header title="News Selection" />
        <Workbench.Content type="full">
          <Box mb={3}>
            <Tabs>
              {tabs.map(({ id }) => (
                <Tab id={id} key={id} selected={selectedTabId === id} onSelect={selectTab}>
                  {id}
                </Tab>
              ))}
            </Tabs>
          </Box>
          <Box pb={5}>
            <SelectedTab />
          </Box>
        </Workbench.Content>
      </Workbench>
    </SourcesProvider>
  );
};
