import { normalizeTagName, TagDTO } from './tag-dto';
import { EntryLink, SpecialtyEntry, TopicTagEntry } from './types';

export function entryToTag(entry: TopicTagEntry | SpecialtyEntry): TagDTO {
  const name = entry.fields.name ? entry.fields.name['en-US'] : '(no name)';
  return {
    id: entry.sys.id,
    name,
    normalizedName: normalizeTagName(name),
    published: !!entry.sys.publishedAt,
    parentName: entry.fields.parentName,
  };
}

export function tagToEntryLink(tag: TagDTO): EntryLink {
  return {
    sys: {
      id: tag.id,
      linkType: 'Entry',
      type: 'Link',
    },
  };
}

export function specialtyNameSort(a: SpecialtyEntry, b: SpecialtyEntry) {
  const nameA = a.fields.name['en-US'].toUpperCase();
  const nameB = b.fields.name['en-US'].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

function buildIdToEntryMap(topicTagEntries: TopicTagEntry[]): Map<string, TopicTagEntry> {
  const idToEntry: Map<string, TopicTagEntry> = new Map();

  for (let entry of topicTagEntries) {
    idToEntry.set(entry.sys.id, entry);
  }

  return idToEntry;
}

export function resolveParentName(topicTagEntries: TopicTagEntry[]): TopicTagEntry[] {
  const idToEntry = buildIdToEntryMap(topicTagEntries);

  for (let entry of topicTagEntries) {
    const parentId = entry.fields?.parent?.['en-US'].sys.id;

    if (parentId) {
      const parentName = idToEntry.get(parentId)?.fields.name['en-US'];
      entry.fields.parentName = parentName;
    }
  }
  return topicTagEntries;
}
