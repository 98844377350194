import { ApolloProvider } from '@apollo/client';
import {
  ConsoleErrorReporter,
  createApolloClient,
  ErrorReporter,
  ErrorReporterContext,
  SentryErrorReporter,
} from '@mdlinx/frontend-shared';
import { defaults } from '@mdlinx/shared/dist/defaults';
import { init as initSentry } from '@sentry/browser';
import { Preview } from 'app/scenes/Preview';
import { ArchivedContents } from 'app/scenes/ui-extension/ArchivedContents';
import { AssigneeField } from 'app/scenes/ui-extension/AssigneeField';
import { DisabledTextInput } from 'app/scenes/ui-extension/DisabledTextInput';
import { DisplayTitleEditor } from 'app/scenes/ui-extension/DisplayTitleEditor';
import { JournalSummaryWorkflowButton } from 'app/scenes/ui-extension/JournalSummaryWorkflowButton';
import { JournalSummarySelection } from 'app/scenes/ui-extension/JournalSummarySelection/JournalSummarySelection';
import { NewsSelection } from 'app/scenes/ui-extension/NewsSelection';
import { StaffMembers } from 'app/scenes/ui-extension/StaffMembers';
import { SpecialtySelector, SubSpecialtySelector, TopicTagSelector } from 'app/scenes/ui-extension/TagSelector';
import {
  FuzzySearch,
  PrefixSearch,
  SuggestionStrategy,
  FUZZY_SEARCH_DEFAULT_THRESHOLD,
  FUZZY_SEARCH_DEFAULT_MIN_MATCH_CHAR_LENGTH,
} from 'app/scenes/ui-extension/TagSelector/CandidateTags';
import { TagRecommenderButton } from 'app/scenes/ui-extension/TagRecommenderButton';
import { TaxonomistPublishButton } from 'app/scenes/ui-extension/TaxonomistPublishButton';
import { CrawlDetails } from 'app/scenes/ui-extension/CrawlDetails';
import introspectionResult from 'generated/possible-types';
import { Authenticate, Authorize } from 'lib/auth';
import {
  FieldExtensionSDKProvider,
  PageExtensionSDKProvider,
  SidebarExtensionSDKProvider,
} from 'lib/contentful-extension';
import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const DEFAULT_SUGGESTION_SIZE = 5;
export const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:${defaults.api.editorialDesk.port}`
    : `https://editorial-desk-api.${process.env.REACT_APP_ROOT_DOMAIN_NAME}`;

const client = createApolloClient<any>({
  baseUrl: API_BASE_URL,
  possibleTypes: introspectionResult.possibleTypes,
  authorization: process.env.REACT_APP_ENVNAME === 'dev',
});

function initErrorReporter(): ErrorReporter {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  if (!dsn) {
    return new ConsoleErrorReporter();
  }
  initSentry({
    dsn,
    environment: process.env.REACT_APP_ENVNAME || 'unknown',
    attachStacktrace: true,
  });
  return new SentryErrorReporter();
}
const errorReporter = initErrorReporter();

function buildSuggestionStrategy(params: URLSearchParams, defaultStrategy: SuggestionStrategy): SuggestionStrategy {
  const strategyParam = params.get('suggestion_strategy');
  switch (strategyParam) {
    case 'prefix':
      return new PrefixSearch();
    case 'fuzzy': {
      const thresholdParam = params.get('fuzzy_search_threshold');
      const threshold = thresholdParam ? parseFloat(thresholdParam) : 0.4;
      return new FuzzySearch({ threshold });
    }
    case null:
      return defaultStrategy;
    default:
      throw new Error(`"${strategyParam}" is not a valid suggestion strategy. (prefix | fuzzy).`);
  }
}

const Providers = ({ children }: PropsWithChildren) => (
  <ApolloProvider client={client}>
    <ErrorReporterContext.Provider value={errorReporter}>
      <Authenticate>{children}</Authenticate>
    </ErrorReporterContext.Provider>
  </ApolloProvider>
);

const App = () => {
  return (
    <Providers>
      <BrowserRouter>
        <Switch>
          <Route
            path="/ui-extensions"
            render={({ match }) => (
              <Switch>
                <Route
                  path={`${match.path}/staffs`}
                  render={() => (
                    <Authorize taxonomist>
                      <PageExtensionSDKProvider>
                        <StaffMembers />
                      </PageExtensionSDKProvider>
                    </Authorize>
                  )}
                />
                <Route
                  path={`${match.path}/archived-contents`}
                  render={() => (
                    <Authorize taxonomist>
                      <PageExtensionSDKProvider>
                        <ArchivedContents />
                      </PageExtensionSDKProvider>
                    </Authorize>
                  )}
                />
                <Route
                  path={`${match.path}/taxonomist-publish-button`}
                  render={() => (
                    <Authorize taxonomist>
                      <TaxonomistPublishButton />
                    </Authorize>
                  )}
                />
                <Route
                  path={`${match.path}/tag-recommender-button`}
                  render={() => (
                    <SidebarExtensionSDKProvider>
                      <TagRecommenderButton />
                    </SidebarExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/assignee-field`}
                  render={() => (
                    <SidebarExtensionSDKProvider>
                      <AssigneeField />
                    </SidebarExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/disabled-text-input`}
                  render={() => (
                    <FieldExtensionSDKProvider>
                      <DisabledTextInput />
                    </FieldExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/display-title-editor`}
                  render={({ location }) => {
                    const fieldName = new window.URLSearchParams(location.search).get('fieldName') ?? 'title';
                    return (
                      <FieldExtensionSDKProvider>
                        <DisplayTitleEditor fieldName={fieldName} />
                      </FieldExtensionSDKProvider>
                    );
                  }}
                />
                <Route
                  path={`${match.path}/tag-selector`}
                  render={({ location }) => {
                    const params = new window.URLSearchParams(location.search);
                    const allowAdd = params.get('allow_add') === 'true';
                    const allowUnpublished = params.get('allow_unpublished') === 'true';
                    const includeTopLevelSpecialties = params.get('include_top_level_specialties') === 'true';
                    const includeSubSpecialties = params.get('include_sub_specialties') === 'true';
                    const suggestionSizeParam = params.get('suggestion_size');
                    const suggestionSize = suggestionSizeParam
                      ? parseInt(suggestionSizeParam)
                      : DEFAULT_SUGGESTION_SIZE;
                    const thresholdParam = params.get('threshold');
                    const threshold = thresholdParam ? parseFloat(thresholdParam) : FUZZY_SEARCH_DEFAULT_THRESHOLD;
                    const minMatchCharLengthParam = params.get('min_match_length');
                    const minMatchCharLength = minMatchCharLengthParam
                      ? parseInt(minMatchCharLengthParam)
                      : FUZZY_SEARCH_DEFAULT_MIN_MATCH_CHAR_LENGTH;
                    return (
                      <FieldExtensionSDKProvider>
                        <TopicTagSelector
                          allowAdd={allowAdd}
                          allowUnpublished={allowUnpublished}
                          includeTopLevelSpecialties={includeTopLevelSpecialties}
                          includeSubSpecialties={includeSubSpecialties}
                          suggestionSize={suggestionSize}
                          suggestionStrategy={buildSuggestionStrategy(
                            params,
                            new FuzzySearch({ threshold, minMatchCharLength }),
                          )}
                        />
                      </FieldExtensionSDKProvider>
                    );
                  }}
                />
                <Route
                  path={`${match.path}/specialty-selector`}
                  render={({ location }) => {
                    const params = new window.URLSearchParams(location.search);
                    const suggestionSizeParam = params.get('suggestion_size');
                    const suggestionSize = suggestionSizeParam
                      ? parseInt(suggestionSizeParam)
                      : DEFAULT_SUGGESTION_SIZE;
                    const suggestionStrategy = buildSuggestionStrategy(params, new PrefixSearch());
                    return (
                      <FieldExtensionSDKProvider>
                        <SpecialtySelector suggestionSize={suggestionSize} suggestionStrategy={suggestionStrategy} />
                      </FieldExtensionSDKProvider>
                    );
                  }}
                />
                <Route
                  path={`${match.path}/sub-specialty-selector`}
                  render={({ location }) => {
                    const params = new window.URLSearchParams(location.search);
                    const suggestionSizeParam = params.get('suggestion_size');
                    const suggestionSize = suggestionSizeParam
                      ? parseInt(suggestionSizeParam)
                      : DEFAULT_SUGGESTION_SIZE;
                    const suggestionStrategy = buildSuggestionStrategy(params, new PrefixSearch());
                    return (
                      <FieldExtensionSDKProvider>
                        <SubSpecialtySelector suggestionSize={suggestionSize} suggestionStrategy={suggestionStrategy} />
                      </FieldExtensionSDKProvider>
                    );
                  }}
                />
                <Route
                  path={`${match.path}/news-selection`}
                  render={() => (
                    <PageExtensionSDKProvider>
                      <NewsSelection />
                    </PageExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/journal-summary-workflow-button`}
                  render={() => (
                    <SidebarExtensionSDKProvider>
                      <JournalSummaryWorkflowButton />
                    </SidebarExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/journal-summary-selection`}
                  render={() => (
                    <PageExtensionSDKProvider>
                      <JournalSummarySelection />
                    </PageExtensionSDKProvider>
                  )}
                />
                <Route
                  path={`${match.path}/crawl-details`}
                  render={() => {
                    return (
                      <SidebarExtensionSDKProvider>
                        <CrawlDetails />
                      </SidebarExtensionSDKProvider>
                    );
                  }}
                />
              </Switch>
            )}
          />
          <Route path="/entries/:id/preview" render={({ match }) => <Preview id={match.params.id} />} />
        </Switch>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
