
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActiveContentPreview": [
      "ActiveContentPreviewFailure",
      "LongFormContentPreview",
      "NewsArticlePreview"
    ],
    "ArchivedContent": [
      "JournalSummary",
      "LongFormContent",
      "NewsArticle"
    ],
    "UnarchiveContentResult": [
      "UnarchiveContentFailure",
      "UnarchiveContentSuccess"
    ]
  }
};
      export default result;
    