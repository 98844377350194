import { DATE_TIME_FORMAT_FOR_TABLE_COLUMN } from 'app/shared/entries';
import { Sources } from 'app/shared/sources';
import { ContentfulEntry } from 'contentful-management';
import { DateTime } from 'luxon';
import { NewsArticleDef } from 'mdlinx-contentful';

export type NewsArticleEntry = ContentfulEntry<NewsArticleDef>;

export type Sorting<K extends string> =
  | undefined
  | {
      key: K;
      reverse: boolean;
    };
export type NewsArticleSorting = Sorting<'source' | 'crawlDate'>;

export function toggleSorting<K extends string>(sorting: Sorting<K>, key: K | undefined): Sorting<K> {
  if (key === undefined) {
    return undefined;
  }
  if (sorting && sorting.key === key) {
    return { key, reverse: !sorting.reverse };
  }
  return { key, reverse: false };
}

export function entryCrawlDate(entry: NewsArticleEntry): string {
  return DateTime.fromISO(entry.sys.createdAt).toFormat(DATE_TIME_FORMAT_FOR_TABLE_COLUMN);
}

export function isHighPriority(entry: NewsArticleEntry): boolean {
  return !!(entry.fields.highPriority && entry.fields.highPriority['en-US']);
}

export function sortNewsArticle(
  entries: NewsArticleEntry[],
  sorting: NewsArticleSorting,
  sources: Sources,
): NewsArticleEntry[] {
  const sortingEntries = [...entries];
  if (!sorting) {
    return sortingEntries;
  }

  const { key, reverse } = sorting;
  const unit = reverse ? -1 : 1;
  return sortingEntries.sort((a, b) => {
    switch (key) {
      case 'source': {
        const sourceTargetName = (na: NewsArticleEntry) => {
          const source = na.fields.source ? sources.get(na.fields.source['en-US'].sys.id) : undefined;
          return source && source.fields.targetName ? source.fields.targetName['en-US'] : '';
        };
        const sourceA = sourceTargetName(a);
        const sourceB = sourceTargetName(b);
        return (sourceA === sourceB ? 0 : sourceA < sourceB ? -1 : 1) * unit;
      }
      case 'crawlDate':
        const crawlDateA = a.sys.createdAt;
        const crawlDateB = b.sys.createdAt;
        return (crawlDateA === crawlDateB ? 0 : crawlDateA < crawlDateB ? -1 : 1) * unit;
    }
  });
}

export const firstRowStickyCellStyle = {
  top: '-24px', // Negate Workbench.Content padding.
};
export const secondRowStickyCellStyle = {
  top: '18px', // Negate Workbench.Content padding + first row
};

export const orderByCrawlDate = 'sys.createdAt';
export const orderByCrawlDateReverse = '-sys.createdAt';
