import { Table, TableBody, TableCell, TableHead, TableRow } from '@contentful/forma-36-react-components';
import { StickyTableHeadCell } from 'app/shared/StickyTableHeadCell';
import { ContentfulEntry } from 'contentful-management';
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';

export type ColumnDefinition<T> = {
  header: ReactElement | string;
  value: (params: { entry: T }) => ReactElement | string;
  onClick?: MouseEventHandler;
};

export function EntryTable<T extends ContentfulEntry>({
  entries,
  columnDefinitions,
  additionalHeader,
}: {
  entries: T[];
  columnDefinitions: ColumnDefinition<T>[];
  additionalHeader?: ReactNode;
}): ReactElement | null {
  return (
    <Table>
      <TableHead isSticky={true}>
        <TableRow>
          {columnDefinitions.map(({ header, onClick }, index) => (
            <StickyTableHeadCell key={index} onClick={onClick}>
              {header}
            </StickyTableHeadCell>
          ))}
        </TableRow>
        {additionalHeader && (
          <TableRow>
            <StickyTableHeadCell colSpan={columnDefinitions.length} secondRow={false}>
              {additionalHeader}
            </StickyTableHeadCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {entries.map(entry => (
          <TableRow key={entry.sys.id}>
            {columnDefinitions.map(({ value }, index) => (
              <TableCell key={index}>{value({ entry })}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
