import { IconButton } from '@contentful/forma-36-react-components';
import { entryEditPageUrl, mdlinxUrl, useContentfulUIExtensionSDK } from 'lib/contentful-extension';
import { openWindowInNewTab } from 'lib/window';
import React from 'react';

export const OpenOnMdlinxIconButton: React.FC<{ entryId: string }> = ({ entryId }) => {
  const sdkState = useContentfulUIExtensionSDK();
  if (sdkState.loading) {
    return null;
  }

  return (
    <IconButton
      label={'Open'}
      iconProps={{
        icon: 'ExternalLink',
      }}
      buttonType={'secondary'}
      onClick={() => {
        openWindowInNewTab(mdlinxUrl(entryId, 'journal-summary'));
      }}
    />
  );
};
