import { HelpText, Tag } from '@contentful/forma-36-react-components';
import React from 'react';
import { Box, Flex } from 'rebass';

export const StatusIndicator: React.FC<{
  label: string;
  color?: 'positive' | 'warning' | 'primary';
}> = ({ label, color = 'positive' }) => (
  <Flex>
    <Box {...{ flexGrow: 1 }}>
      <HelpText>Status</HelpText>
    </Box>
    <Tag tagType={color}>{label}</Tag>
  </Flex>
);
