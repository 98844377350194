export type TagDTO = {
  name: string;
  normalizedName: string;
  id: string;
  published: boolean;
  parentName?: string;
};

export function normalizeTagName(str: string): string {
  return str.toUpperCase();
}
