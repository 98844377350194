import { ContentfulEntry } from 'contentful-management';
import { FieldExtensionSDK, PageExtensionSDK, SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import { findAllPublishedEntries } from 'lib/contentful-extension';
import { SpecialtyDef } from 'mdlinx-contentful';

export async function findAllPublishedSpecialties(
  sdk: FieldExtensionSDK | SidebarExtensionSDK | PageExtensionSDK,
): Promise<ContentfulEntry<SpecialtyDef>[]> {
  return await findAllPublishedEntries<ContentfulEntry<SpecialtyDef>>(sdk, { content_type: 'specialty' });
}
