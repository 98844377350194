import { Tab, Tabs } from '@contentful/forma-36-react-components';
import Workbench from '@contentful/forma-36-react-components/dist/components/Workbench/Workbench';
import { SpecialtiesProvider } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtiesProvider';
import { StaffMembersProvider } from 'app/scenes/ui-extension/JournalSummarySelection/staff-members';
import { UnreviewedAbstracts } from 'app/scenes/ui-extension/JournalSummarySelection/UnreviewedAbstracts';
import { useLocalStorage } from 'app/shared/local-storage';
import { SourcesProvider } from 'app/shared/sources';
import { TopicTagsProvider } from 'app/shared/topic-tags';
import { CustomStaffRole } from 'generated/graphql-client-query';
import { useMe } from 'lib/auth';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import React, { useState } from 'react';
import { Box } from 'rebass';
import { CuratedCollectionListsProvider } from './CuratedCollectionListsProvider';
import { CurateWeeklyEmail } from './CurateWeeklyEmail';
import { RecycleBin } from './RecycleBin';
import { SecondaryReview } from './SecondaryReview';
import { UnreleasedArticles } from './UnreleasedArticles';

const tabs = [
  { id: 'Unreleased Articles', component: UnreleasedArticles, forEditor: true },
  { id: 'Unreviewed Abstracts', component: UnreviewedAbstracts, forEditor: false },
  { id: 'Secondary Review', component: SecondaryReview, forEditor: true },
  { id: 'Curate Weekly Email', component: CurateWeeklyEmail, forEditor: true },
  { id: 'Recycle Bin', component: RecycleBin, forEditor: true },
];

export const JournalSummarySelection: React.FC = () => {
  const sdk = usePageExtensionSDK();
  const selectedTabIdStorage = useLocalStorage<string>('journal-summary-selection:selected-tab-id');

  const me = useMe();
  const isEditor = [CustomStaffRole.Admin, CustomStaffRole.Editor, CustomStaffRole.Taxonomist].includes(me.customRole);
  const permittedTabs = tabs.filter(tab => !tab.forEditor || isEditor);

  const [selectedTabId, setSelectedTabId] = useState(selectedTabIdStorage.get() || permittedTabs[0].id);
  const selectTab = (id: string) => {
    selectedTabIdStorage.set(id);
    setSelectedTabId(id);
  };

  const SelectedTab = permittedTabs.find(tab => tab.id === selectedTabId)!.component;

  return (
    <SpecialtiesProvider>
      <StaffMembersProvider>
        <SourcesProvider sdk={sdk}>
          <TopicTagsProvider sdk={sdk}>
            <CuratedCollectionListsProvider>
              <Workbench>
                <Workbench.Header title="Journal Summary Selection" />
                <Workbench.Content type="full">
                  <Box mb={3}>
                    <Tabs>
                      {permittedTabs.map(({ id }) => (
                        <Tab id={id} key={id} selected={selectedTabId === id} onSelect={selectTab}>
                          {id}
                        </Tab>
                      ))}
                    </Tabs>
                  </Box>
                  <Box pb={5}>
                    <SelectedTab />
                  </Box>
                </Workbench.Content>
              </Workbench>
            </CuratedCollectionListsProvider>
          </TopicTagsProvider>
        </SourcesProvider>
      </StaffMembersProvider>
    </SpecialtiesProvider>
  );
};
