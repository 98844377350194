/**
 * Returns integer array between min and max
 *
 * i.g.
 * range(3, 5)
 * => [3, 4, 5]
 *
 * range(5, 3)
 * => []
 *
 * @param min
 * @param max
 */
export function range(min: number, max: number): number[] {
  return [...new Array(Math.max(max - min + 1, 0))].map((_, index) => min + index);
}
