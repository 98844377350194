import { HelpText, TextLink } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import {
  crawlDateColumn,
  sourceColumn,
  titleColumn,
  unDeleteColumn,
  viewAbstractColumn,
} from 'app/scenes/ui-extension/JournalSummarySelection/columns';
import { orderByCrawlDateReverse } from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { useJournalSummaries } from 'app/scenes/ui-extension/JournalSummarySelection/journal-summaries';
import { Pager } from 'app/shared/Pager';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';

export const RecycleBin: React.FC = () => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [order, setOrder] = useState(orderByCrawlDateReverse);
  const query = useMemo(
    () => ({
      'fields.workflowStatus[in]': ['Reviewed - Not Selected'].join(','),
      order,
    }),
    [order],
  );
  const {
    entries,
    total,
    isUpdating,
    isEntryUpdating,
    unDeleteByIds,
    reload,
    currentPage,
    setCurrentPage,
    totalPage,
  } = useJournalSummaries(query);
  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          titleColumn,
          viewAbstractColumn,
          sourceColumn,
          crawlDateColumn({
            order,
            onChange: setOrder,
          }),
          unDeleteColumn({
            onClick: async entry => {
              try {
                await unDeleteByIds([entry.sys.id]);
                sdk.notifier.success(`Un-delete "${entry.fields.title?.['en-US']}"`);
              } catch (e) {
                errorReporter.report(e);
                sdk.notifier.error(`Failed to Un-delete entry`);
              } finally {
                reload();
              }
            },
            isEntryUpdating,
          }),
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} entries selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'muted'}
                onClick={async () => {
                  try {
                    await unDeleteByIds(selectedEntries.map(e => e.sys.id));
                    sdk.notifier.success(`Un-deleted ${selectedEntries.length} entries`);
                  } catch (e) {
                    errorReporter.report(e);
                    sdk.notifier.error(`Failed to Un-delete some entries`);
                  } finally {
                    reload();
                  }
                }}
                disabled={isUpdating}
              >
                Un-Delete
              </TextLink>
            </Box>
          </Flex>
        )}
      />
      <Box m={3}>
        <Pager currentPage={currentPage} totalPage={totalPage} onChange={setCurrentPage} />
      </Box>
    </>
  );
};
