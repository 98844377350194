import { Icon, Pill } from '@contentful/forma-36-react-components';
import { TooltipSwitch } from 'app/shared/TooltipSwitch';
import { TagDTO } from './tag-dto';
import * as React from 'react';

export const Tag: React.FC<{
  tag: TagDTO;
  onClose?: () => void;
}> = ({ tag, onClose }) => (
  <TooltipSwitch content={'This tag is not published yet.'} show={!tag.published}>
    <Pill
      label={tag.parentName ? `${tag.name} (${tag.parentName})` : tag.name}
      onClose={onClose}
      onDrag={tag.published ? undefined : () => {}}
      dragHandleComponent={
        <Icon
          icon="Warning"
          color="negative"
          style={{
            paddingTop: '0.475rem',
            paddingLeft: '0.375rem',
          }}
        />
      }
    />
  </TooltipSwitch>
);
