import { Button, Dropdown, DropdownList, DropdownListItem } from '@contentful/forma-36-react-components';
import { ContentfulEntry } from 'contentful-management';
import { sortBy } from 'lib/arrays';
import { SpecialtyDef } from 'mdlinx-contentful';
import React, { useMemo, useState } from 'react';

export type SpecialtySelection = { type: 'NO_FILTER' } | { type: 'GENERAL' } | { type: 'SINGLE'; specialtyId: string };
export const NO_FILTER: SpecialtySelection = { type: 'NO_FILTER' };
export const GENERAL: SpecialtySelection = { type: 'GENERAL' };

export const SpecialtySelector: React.FC<{
  value: SpecialtySelection;
  onChange: (value: SpecialtySelection) => void;
  candidates: ContentfulEntry<SpecialtyDef>[];
  singleSelectionOnly: boolean;
}> = ({ value, onChange, candidates, singleSelectionOnly }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sortedCandidates = useMemo(() => sortBy(candidates)(c => c.fields.name?.['en-US']), [candidates]);
  const label = useMemo(() => {
    switch (value.type) {
      case 'NO_FILTER':
        return 'All';
      case 'GENERAL':
        return 'General';
      case 'SINGLE': {
        const selected = sortedCandidates.find(sp => sp.sys.id === value.specialtyId);
        return selected?.fields?.name?.['en-US'] ?? 'Unkonwn';
      }
    }
  }, [value, sortedCandidates]);
  return (
    <>
      <Dropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        position={'bottom-left'}
        toggleElement={
          <Button onClick={() => setIsOpen(true)} buttonType={'naked'} indicateDropdown={true} size={'small'}>
            Specialty: {label}
          </Button>
        }
      >
        <DropdownList maxHeight={200}>
          {!singleSelectionOnly && (
            <>
              <DropdownListItem
                onClick={() => {
                  onChange(NO_FILTER);
                  setIsOpen(false);
                }}
              >
                All
              </DropdownListItem>
              <DropdownListItem
                onClick={() => {
                  onChange(GENERAL);
                  setIsOpen(false);
                }}
              >
                General
              </DropdownListItem>
            </>
          )}
          {sortedCandidates.map(specialty => (
            <DropdownListItem
              key={specialty.sys.id}
              onClick={() => {
                onChange({ type: 'SINGLE', specialtyId: specialty.sys.id });
                setIsOpen(false);
              }}
            >
              {specialty.fields.name?.['en-US']}
            </DropdownListItem>
          ))}
        </DropdownList>
      </Dropdown>
    </>
  );
};
