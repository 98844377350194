import { useErrorReporter } from '@mdlinx/frontend-shared';
import { ContentfulEntry } from 'contentful-management';
import { PageExtensionSDK } from 'contentful-ui-extensions-sdk';
import { findAllEntries } from 'lib/contentful-extension';
import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';

// Todo: generate from Contentful when crawlerTarget is created in MDLinx space.
type CrawlerTarget = ContentfulEntry<{
  contentType: 'crawlerTarget';
  fields: {
    targetName: { 'en-US': string };
    journalImpactFactor: { 'en-US': number };
    scimagoJournalRank: { 'en-US': number };
  };
}>;
export type Source = CrawlerTarget;

type SourceId = string;
export function sourceId(source: Source): SourceId {
  return source.sys.id;
}
export function sourceTargetName(source: Source): string {
  return (source.fields.targetName && source.fields.targetName['en-US']) || '(name undefined)';
}

export class Sources {
  all: Source[];

  constructor(private readonly storage: Map<string, Source>) {
    this.all = [...Array.from(storage.values())];
  }

  get(id: string): Source | undefined {
    return this.storage.get(id);
  }
}

const SourcesContext = React.createContext<Sources>(new Sources(new Map()));

type SourcesProviderProps = PropsWithChildren<{
  sdk: PageExtensionSDK;
}>;

export const SourcesProvider = ({ sdk, children }: SourcesProviderProps) => {
  const [sources, setSources] = useState<Sources>(new Sources(new Map()));
  const errorReporter = useErrorReporter();
  useEffect(() => {
    findAllEntries<Source>(sdk, { content_type: 'crawlerTarget' })
      .then(items => setSources(new Sources(new Map(items.map(i => [i.sys.id, i])))))
      .catch(errorReporter.report);
  }, [sdk]);

  return <SourcesContext.Provider value={sources}>{children}</SourcesContext.Provider>;
};

export function useSources(): Sources {
  return useContext(SourcesContext);
}
