import * as React from 'react';
import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';
import { Note, Spinner } from '@contentful/forma-36-react-components';

import { CustomStaffRole, MeQuery, useMeQuery } from 'generated/graphql-client-query';
import { API_BASE_URL } from 'App';

export type Me = MeQuery['me'];

const MeContext = createContext<Me | undefined>(undefined);

export const Authenticate = ({ children }: PropsWithChildren) => {
  const { loading, error, data, startPolling, stopPolling } = useMeQuery();
  const [firstError, setFirstError] = React.useState<any>();
  const [_oauthWindow, setOAuthWindow] = React.useState<Window | null>(null);

  React.useEffect(() => {
    if (!firstError && error) {
      setFirstError(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (!!firstError) {
      const executeOAuth = () => {
        const url = `${API_BASE_URL}/oauth/initiate`;

        setOAuthWindow(window.open(url, '', 'left=150,top=10,width=800,height=900'));
        startPolling(500);
        setInterval(() => stopPolling(), 8100);
      };

      executeOAuth();
    }
  }, [firstError]);

  React.useEffect(() => {
    if (data) {
      stopPolling();
    }
  }, [data]);

  return loading ? (
    <Spinner />
  ) : error ? (
    <Note title="System error" noteType="negative">
      {error.message}
    </Note>
  ) : data ? (
    <MeContext.Provider value={data.me}>{children}</MeContext.Provider>
  ) : null;
};

export function useMe(): Me {
  const me = useContext(MeContext);
  if (!me) {
    throw new Error(`Me should be loaded.`);
  }
  return me;
}

type AuthorizeProps = PropsWithChildren<{
  editor?: boolean;
  taxonomist?: boolean;
  indiaTeamWriter?: boolean;
  guest?: boolean;
  fallback?: () => ReactNode;
}>;

export const Authorize = ({
  children,
  editor = false,
  taxonomist = false,
  indiaTeamWriter = false,
  guest = false,
  fallback = () => <PermissionDenied />,
}: AuthorizeProps) => {
  const me = useMe();
  const permitted = (customRole: CustomStaffRole) => {
    switch (customRole) {
      case CustomStaffRole.Admin:
        return true;
      case CustomStaffRole.Taxonomist:
        return taxonomist;
      case CustomStaffRole.Editor:
        return editor;
      case CustomStaffRole.IndiaTeamWriter:
        return indiaTeamWriter;
      case CustomStaffRole.Guest:
        return guest;
    }
  };
  return <>{permitted(me.customRole) ? children : fallback()}</>;
};

const PermissionDenied: React.FC = () => (
  <Note title="Permission denied" noteType="negative">
    You are not allowed to show this content.
  </Note>
);
