import { ContentfulEntry } from 'contentful-management';

export function entryId(entry: ContentfulEntry<any>): string {
  return entry.sys.id;
}

export function isPublished(entry: ContentfulEntry<any>): boolean {
  return !!entry.sys.publishedAt;
}

export function entryIdToLink(entryId: string) {
  return {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id: entryId,
    },
  };
}

export function formatEntryForAutotagAPI(entry: any, contentType: string, locale = 'en-US') {
  return {
    sys: {
      id: entry.getSys().id,
      contentType: {
        sys: {
          id: contentType,
        },
      },
    },
    fields: {
      title: {
        [locale]: entry.fields.title.getValue(locale),
      },
      bodyHtml: {
        [locale]: entry.fields.bodyHtml.getValue(locale),
      },
    },
  };
}
