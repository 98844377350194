import { useErrorReporter } from '@mdlinx/frontend-shared';
import { PagedEntries, usePagedEntries } from 'app/shared/paged-entries';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import { NewsArticleDef, NewsArticleFields } from 'mdlinx-contentful';
import { useMemo, useState } from 'react';
import { NewsArticleEntry } from './common';

export type NewsArticles = Pick<
  PagedEntries<NewsArticleDef>,
  'entries' | 'total' | 'currentPage' | 'totalPage' | 'setCurrentPage'
> & {
  isUpdating: boolean;

  isEntryUpdating(entryId: string): boolean;

  togglePriority(entryId: string): void;

  releaseToEditorial(entryIds: string[]): void;

  reject(entryIds: string[]): void;

  undelete(entryIds: string[]): void;

  deleteEntirely(entryIds: string[]): void;
};

function addItems<T>(set: Set<T>, items: Set<T>): Set<T> {
  return new Set([...Array.from(set), ...Array.from(items)]);
}

function removeItems<T>(set: Set<T>, items: Set<T>): Set<T> {
  return new Set([...Array.from(set).filter(i => !items.has(i))]);
}

const distinct = <T>(array: Array<T>) => <U>(f: (t: T) => U): Array<T> => {
  const [, uniqueItems] = array.reduce(
    ([set, items], t) => {
      const u = f(t);
      if (set.has(u)) {
        return [set, items];
      }
      return [set.add(u), [...items, t]];
    },
    [new Set<U>(), [] as T[]],
  );
  return uniqueItems;
};

type WorkflowStatusUpdateParams = {
  workflowStatus: NewsArticleFields['workflowStatus']['en-US'];
  onSuccess: (entryIds: string[]) => void;
  onFailure: (errors: Error[]) => void;
};

export function useNewsArticles(query: { [key: string]: any }): NewsArticles {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [priorityUpdatingEntryIds, setPriorityUpdatingEntryIds] = useState(new Set<string>([]));
  const [workflowUpdatingEntryIds, setWorkflowUpdatingEntryIds] = useState(new Set<string>([]));
  const [deletingEntryIds, setDeletingEntryIds] = useState(new Set<string>([]));

  const newsArticleQuery = useMemo(
    () => ({
      ...query,
      content_type: 'newsArticle',
    }),
    [query],
  );
  const { entries, total, setCurrentPage, currentPage, totalPage, reload, replaceEntry } = usePagedEntries<
    NewsArticleDef
  >(newsArticleQuery);

  const isEntryUpdating = (entryId: string) => {
    const isPriorityUpdating = priorityUpdatingEntryIds.has(entryId);
    const isWorkflowUpdating = workflowUpdatingEntryIds.has(entryId);
    const isDeleting = deletingEntryIds.has(entryId);
    return isPriorityUpdating || isWorkflowUpdating || isDeleting;
  };

  const updateEntriesSequentially = (entries: NewsArticleEntry[]) => (
    f: (entry: NewsArticleEntry) => NewsArticleEntry,
  ): Promise<Error[]> => {
    return entries.reduce(async (acc, entry) => {
      const errors = await acc;
      try {
        await sdk.space.updateEntry(f(entry));
        return errors;
      } catch (e) {
        return [...errors, e];
      }
    }, Promise.resolve<Error[]>([]));
  };

  const updateWorkflowStatus = ({ workflowStatus, onSuccess, onFailure }: WorkflowStatusUpdateParams) => async (
    entryIds: string[],
  ): Promise<void> => {
    const entryIdSet = new Set(entryIds);
    try {
      setWorkflowUpdatingEntryIds(addItems(workflowUpdatingEntryIds, entryIdSet));

      const targetEntries = entries.filter(e => entryIdSet.has(e.sys.id));
      const errors = await updateEntriesSequentially(targetEntries)(e => ({
        ...e,
        fields: {
          ...e.fields,
          workflowStatus: { 'en-US': workflowStatus },
        },
      }));
      if (errors.length === 0) {
        onSuccess(entryIds);
      } else {
        const distinctErrors = distinct(errors)(e => e.message);
        onFailure(distinctErrors);
        distinctErrors.forEach(errorReporter.report);
      }
    } catch (e) {
      errorReporter.report(e);
      onFailure([e]);
    } finally {
      // Reload anyway because failure can happen after some of items are successfully updated.
      reload();
      setWorkflowUpdatingEntryIds(ids => removeItems(ids, entryIdSet));
    }
  };

  const deleteEntirely = async (entryIds: string[]): Promise<void> => {
    const entryIdSet = new Set(entryIds);
    try {
      setDeletingEntryIds(addItems(deletingEntryIds, entryIdSet));

      const errors = await entries.reduce(async (acc, entry) => {
        const errors = await acc;
        if (!entryIdSet.has(entry.sys.id)) {
          return errors;
        }
        try {
          await sdk.space.deleteEntry(entry);
          return errors;
        } catch (e) {
          return [...errors, e];
        }
      }, Promise.resolve<Error[]>([]));
      if (errors.length === 0) {
        sdk.notifier.success(`${entryIds.length} entries are deleted.`);
      } else {
        const distinctErrors = distinct(errors)(e => e.message);

        sdk.notifier.error('Failed to delete some of selected entries.');
        distinctErrors.forEach(e => {
          sdk.notifier.error(e.message);
          errorReporter.report(e);
        });
      }
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error(`Failed to delete some of selected entries: ${e.message}`);
    } finally {
      reload();
      setDeletingEntryIds(ids => removeItems(ids, entryIdSet));
    }
  };

  const togglePriority = async (entryId: string): Promise<void> => {
    try {
      const entry = entries.find(e => e.sys.id === entryId);
      const updating = isEntryUpdating(entryId);
      if (!entry || updating) {
        return;
      }

      setPriorityUpdatingEntryIds(addItems(priorityUpdatingEntryIds, new Set([entryId])));

      const isHighPriority = !!(entry.fields.highPriority && entry.fields.highPriority['en-US']);
      const toggled = !isHighPriority;
      const updated = (await sdk.space.updateEntry({
        ...entry,
        fields: {
          ...entry.fields,
          highPriority: { 'en-US': toggled },
        },
      })) as NewsArticleEntry;
      replaceEntry(updated);
    } catch (e) {
      errorReporter.report(e);
      sdk.notifier.error(`Failed to update priority: ${e.message}`);
    } finally {
      setPriorityUpdatingEntryIds(ids => removeItems(ids, new Set([entryId])));
    }
  };

  return {
    total,
    entries,

    currentPage,
    totalPage,
    setCurrentPage,

    isUpdating: priorityUpdatingEntryIds.size > 0 || workflowUpdatingEntryIds.size > 0 || deletingEntryIds.size > 0,

    isEntryUpdating,

    togglePriority,

    releaseToEditorial: updateWorkflowStatus({
      workflowStatus: 'Selected for Editorial',
      onSuccess: ids => sdk.notifier.success(`${ids.length} entries are sent for editorial review.`),
      onFailure: errors => {
        sdk.notifier.error('Failed to update some of selected entries.');
        errors.forEach(e => {
          sdk.notifier.error(e.message);
        });
      },
    }),

    reject: updateWorkflowStatus({
      workflowStatus: 'Reviewed - Not Selected',
      onSuccess: ids => sdk.notifier.success(`${ids.length} entries are rejected.`),
      onFailure: errors => {
        sdk.notifier.error('Failed to reject some of selected entries.');
        errors.forEach(e => {
          sdk.notifier.error(e.message);
        });
      },
    }),

    undelete: updateWorkflowStatus({
      workflowStatus: 'Crawler Output',
      onSuccess: ids => sdk.notifier.success(`${ids.length} entries are un-deleted.`),
      onFailure: errors => {
        sdk.notifier.error('Failed to un-delete some of selected entries.');
        errors.forEach(e => {
          sdk.notifier.error(e.message);
        });
      },
    }),

    deleteEntirely,
  };
}
