import { IconButton } from '@contentful/forma-36-react-components';
import { usePageExtensionSDK, entryEditPageUrl } from 'lib/contentful-extension';
import { openWindowInNewTab } from 'lib/window';
import React from 'react';
import { NewsArticleEntry } from './common';

export const OpenInEditor: React.FC<{
  entry: NewsArticleEntry;
}> = ({ entry }) => {
  const sdk = usePageExtensionSDK();
  const openEditPage = () => {
    openWindowInNewTab(entryEditPageUrl(sdk.ids.space, sdk.ids.environment, entry.sys.id));
  };

  return (
    <IconButton
      label={'Edit'}
      iconProps={{
        icon: 'ExternalLink',
      }}
      buttonType={'secondary'}
      onClick={openEditPage}
    />
  );
};
