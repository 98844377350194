import { useSpecialties } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtiesProvider';
import { DATE_TIME_FORMAT_FOR_TABLE_COLUMN } from 'app/shared/entries';
import { ContentfulEntry } from 'contentful-management';
import { DateTime } from 'luxon';
import { JournalSummaryDef, SpecialtyDef } from 'mdlinx-contentful';
import { useMemo } from 'react';

export type JournalSummaryEntry = ContentfulEntry<JournalSummaryDef>;
export type SortOrder = 'asc' | 'desc';
export const orderByCrawlDate = 'sys.createdAt';
export const orderByCrawlDateReverse = '-sys.createdAt';
export const orderByPublicationDateReverse = '-sys.publishedAt';

export function journalSummaryCrawlDate(entry: JournalSummaryEntry): string {
  return DateTime.fromISO(entry.sys.createdAt).toFormat(DATE_TIME_FORMAT_FOR_TABLE_COLUMN);
}

function isTargetSpecialtyForJournalSummary(entry: ContentfulEntry<SpecialtyDef>): boolean {
  return entry.fields.name?.['en-US'] !== 'All Specialties';
}

export function useSpecialtiesForJournalSummary(): ContentfulEntry<SpecialtyDef>[] {
  const specialties = useSpecialties();
  const targets = useMemo(() => specialties.filter(isTargetSpecialtyForJournalSummary), [specialties]);
  return targets;
}

export function queryForSpecialtyIds(ids: string[]): { [key: string]: any } {
  return { 'fields.specialties.sys.id[in]': ids.join(',') };
}

export const queryForGeneralSpecialty: { [key: string]: any } = {
  'fields.specialties[exists]': false,
};

export function entryPublishDate(entry: ContentfulEntry): string {
  return entry.sys.publishedAt
    ? DateTime.fromISO(entry.sys.publishedAt).toFormat(DATE_TIME_FORMAT_FOR_TABLE_COLUMN)
    : 'Unpublished';
}
