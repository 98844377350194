import { ContentfulEntry } from 'contentful-management';
import { useMemo, useState } from 'react';

export function useEntriesSelection<T extends ContentfulEntry>(entries: T[]) {
  const [selectedEntryIds, setSelectedEntryIds] = useState(new Set<string>());

  return useMemo(() => {
    const selectedEntries = entries.filter(e => selectedEntryIds.has(e.sys.id));
    const selectedEntryIdSet = new Set(selectedEntries.map(e => e.sys.id));
    const isAllEntriesSelected = entries.length > 0 && selectedEntries.length === entries.length;
    return {
      selectedEntries,
      isAllEntriesSelected,
      isEntrySelected(id: string) {
        return selectedEntryIdSet.has(id);
      },
      toggleSelectedEntryId(id: string) {
        if (selectedEntryIdSet.has(id)) {
          setSelectedEntryIds(new Set([...Array.from(selectedEntryIdSet)].filter(i => i !== id)));
        } else {
          setSelectedEntryIds(new Set([...Array.from(selectedEntryIdSet), id]));
        }
      },
      toggleAllEntries() {
        if (isAllEntriesSelected) {
          setSelectedEntryIds(new Set([]));
        } else {
          setSelectedEntryIds(new Set(entries.map(e => e.sys.id)));
        }
      },
    };
  }, [entries, selectedEntryIds]);
}
