import { useErrorReporter } from '@mdlinx/frontend-shared';
import { useState } from 'react';

type LocalStorage<T> = {
  get(): T | undefined;

  set(value: T): void;
};

export function useLocalStorageState<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const localStorage = useLocalStorage<T>(key);
  const [value, setValue] = useState(localStorage.get() ?? defaultValue);
  return [
    value,
    (value: T) => {
      setValue(value);
      localStorage.set(value);
    },
  ];
}

export function useLocalStorage<T>(key: string): LocalStorage<T> {
  const errorReporter = useErrorReporter();

  return {
    get(): T | undefined {
      try {
        const storedValue = localStorage.getItem(key);
        if (storedValue === null) {
          return undefined;
        }
        return JSON.parse(storedValue);
      } catch (e) {
        errorReporter.report(e);
        return undefined;
      }
    },

    set(value: T): void {
      localStorage.setItem(key, JSON.stringify(value));
    },
  };
}
