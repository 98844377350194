import { HelpText, TextLink } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import {
  currentListsColumn,
  sortableSjrColumn,
  openOnMdlinxColumn,
  primaryTopicTagColumn,
  publishDateColumn,
  sourceColumn,
  specialtyColumn,
  titleColumn,
} from 'app/scenes/ui-extension/JournalSummarySelection/columns';
import {
  orderByPublicationDateReverse,
  queryForSpecialtyIds,
  SortOrder,
  useSpecialtiesForJournalSummary,
} from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { NO_FILTER, SpecialtySelection } from 'app/scenes/ui-extension/JournalSummarySelection/SpecialtySelector';
import { useLocalStorageState } from 'app/shared/local-storage';
import { SelectableEntryTable } from 'app/shared/SelectableEntryTable';
import { entryEditPageUrl, usePageExtensionSDK } from 'lib/contentful-extension';
import { openWindowInNewTab } from 'lib/window';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { SpecialtyEntry } from '../TagSelector/types';
import { useCuratedJournalSummaries } from './curated-journal-summaries';

const includedSpecialtyNames = new Set([
  'Cardiology',
  'Dermatology',
  'Endocrinology',
  'Family Medicine',
  'Gastroenterology',
  'Infectious Disease',
  'Internal Medicine',
  'Nephrology',
  'Neurology',
  'Oncology',
  'Pain Management',
  'Pediatrics',
  'Psychiatry',
  'Rheumatology',
  'Urology',
]);

function isTargetSpecialty(specialty: SpecialtyEntry): boolean {
  return includedSpecialtyNames.has(specialty.fields.name?.['en-US']);
}

export const CurateWeeklyEmail: React.FC = () => {
  const sdk = usePageExtensionSDK();
  const specialties = useSpecialtiesForJournalSummary();
  const targetSpecialties = useMemo(() => specialties.filter(isTargetSpecialty), [specialties]);
  const errorReporter = useErrorReporter();
  const [specialtySelection, setSpecialtySelection] = useLocalStorageState<SpecialtySelection>(
    'journal-summary-selection:curate-weekly-email',
    NO_FILTER,
  );

  const [order, setOrder] = useState<SortOrder>('desc');

  const query = useMemo(() => {
    const cutoffDays = parseInt(process.env.REACT_APP_WEEKLY_CURATION_CUTOFF_DAYS ?? '') || 7;
    const cutoffDate = DateTime.local()
      .minus({ days: cutoffDays })
      .toFormat('yyyy-MM-dd');

    return {
      'sys.publishedAt[gte]': cutoffDate,
      ...(specialtySelection.type === 'SINGLE' && queryForSpecialtyIds([specialtySelection.specialtyId])),
    };
  }, [orderByPublicationDateReverse, specialtySelection]);
  const { entries, total, createCuratedCollectionByIds } = useCuratedJournalSummaries(query, order);

  return (
    <>
      <Box mb={3}>
        <HelpText>{total ? `${total} entries found` : <>&nbsp;</>}</HelpText>
      </Box>
      <SelectableEntryTable
        entries={entries}
        columnDefinitions={[
          titleColumn,
          specialtyColumn({
            selection: specialtySelection,
            onChange: setSpecialtySelection,
            candidates: targetSpecialties,
            singleSelectionOnly: true,
          }),
          sourceColumn,
          sortableSjrColumn({
            order,
            onChange: setOrder,
          }),
          publishDateColumn,
          primaryTopicTagColumn,
          currentListsColumn,
          openOnMdlinxColumn,
        ]}
        renderSelectionControl={({ selectedEntries }) => (
          <Flex>
            <Box>{selectedEntries.length} entries selected:</Box>
            <Box mx={1}>
              <TextLink
                linkType={'positive'}
                onClick={async () => {
                  try {
                    const specialtyName =
                      (specialtySelection.type === 'SINGLE' &&
                        targetSpecialties.find(specialty => specialty.sys.id === specialtySelection.specialtyId)?.fields
                          .name?.['en-US']) ||
                      'Undefined';

                    const createdEntry = await createCuratedCollectionByIds(
                      selectedEntries.map(e => e.sys.id),
                      specialtyName,
                    );
                    sdk.notifier.success(`Added ${selectedEntries.length} entries to curated collection list`);
                    openWindowInNewTab(entryEditPageUrl(sdk.ids.space, sdk.ids.environment, createdEntry.sys.id));
                  } catch (e) {
                    errorReporter.report(e);
                    sdk.notifier.error(`Failed to publish some entries`);
                  }
                }}
              >
                Add to Curated List
              </TextLink>
            </Box>
          </Flex>
        )}
      />
    </>
  );
};
