import { useErrorReporter } from '@mdlinx/frontend-shared';
import { JournalSummaryEntry, SortOrder } from 'app/scenes/ui-extension/JournalSummarySelection/common';
import { useSources } from 'app/shared/sources';
import { findAllEntries, usePageExtensionSDK } from 'lib/contentful-extension';
import { useEffect, useMemo, useState } from 'react';

export function useCuratedJournalSummaries(query: { [key: string]: string }, order: SortOrder) {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const sources = useSources();

  const journalSummaryQuery = useMemo(
    () => ({
      ...query,
      content_type: 'journalSummary',
    }),
    [query],
  );

  const [entries, setEntries] = useState<JournalSummaryEntry[]>([]);
  const [sortedEntries, setSortedEntries] = useState<JournalSummaryEntry[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // In order to avoid working with selecting articles across multiple pages just load everything.
        const data = await findAllEntries<JournalSummaryEntry>(sdk, journalSummaryQuery);

        setEntries(data);
        setTotal(data.length);
      } catch (e) {
        errorReporter.report(e);
        sdk.notifier.error('Failed to load entries for some reason. Try reload.');
      }
    };

    fetchData();
  }, [sdk, journalSummaryQuery, errorReporter, setEntries, setTotal]);

  useEffect(() => {
    const extractSjr = (entry: JournalSummaryEntry) =>
      (entry.fields.source && sources.get(entry.fields.source['en-US'].sys.id)?.fields.scimagoJournalRank?.['en-US']) ??
      0;
    const sorted = [...entries].sort((a, b) => {
      const aImpact = extractSjr(a);
      const bImpact = extractSjr(b);

      if (aImpact === bImpact) {
        return (a.sys.publishedAt ?? '') > (b.sys.publishedAt ?? '') ? 1 : -1;
      }

      const descendingFactor = order === 'asc' ? 1 : -1;
      return (aImpact > bImpact ? 1 : -1) * descendingFactor;
    });

    setSortedEntries(sorted);
  }, [order, sources, entries, setSortedEntries]);

  const createCuratedCollectionByIds = async (ids: string[], specialtyName: string) => {
    const dateString = new Date().toISOString().split('T')[0];

    const entry = await sdk.space.createEntry('curatedCollectionList', {
      fields: {
        title: {
          'en-US': `${specialtyName} weekly journal recap ${dateString}`,
        },
        entries: {
          'en-US': ids.map(id => ({
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id,
            },
          })),
        },
        listType: {
          'en-US': 'Email',
        },
      },
    });

    return entry as JournalSummaryEntry;
  };

  return {
    entries: sortedEntries,
    total,
    createCuratedCollectionByIds,
  };
}
