/**
 * partition an array by each result of predicate function.
 *
 * i.g.
 * partition([1, 2, 3, 4, 5, 6])(n => n < 4)
 * => [[1, 2, 3], [4, 5, 6]]
 *
 * @param ts
 */
export const partition = <T>(ts: T[]) => (p: (t: T) => boolean): [T[], T[]] => {
  const matched: T[] = [];
  const unmatched: T[] = [];
  ts.forEach(t => {
    if (p(t)) {
      matched.push(t);
    } else {
      unmatched.push(t);
    }
  });
  return [matched, unmatched];
};

/**
 * A pure function to return an array sorted by the result of given function f.
 * @param ts
 */
export const sortBy = <T>(ts: T[]) => (f: (t: T) => any): T[] =>
  [...ts].sort((a, b) => {
    const valueA = f(a);
    const valueB = f(b);
    return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
  });
