import Fuse from 'fuse.js';
import { normalizeTagName, TagDTO } from './tag-dto';

export const FUZZY_SEARCH_DEFAULT_THRESHOLD = 0.4;
export const FUZZY_SEARCH_DEFAULT_MIN_MATCH_CHAR_LENGTH = 3;

export interface SuggestionStrategy {
  suggest(input: string, candidates: TagDTO[], suggestionSize: number): TagDTO[];
}

export class FuzzySearch implements SuggestionStrategy {
  constructor(private readonly options: { threshold: number; minMatchCharLength?: number }) {}

  suggest(input: string, candidates: TagDTO[], suggestionSize: number): TagDTO[] {
    const { threshold, minMatchCharLength } = this.options;

    if (input.length < (minMatchCharLength ?? 1)) return [];

    const fuse = new Fuse(candidates, {
      threshold,
      shouldSort: true,
      keys: ['normalizedName'],
      includeMatch: true,
      minMatchCharLength,
    });
    const result = fuse.search(normalizeTagName(input));
    return result.slice(0, suggestionSize).map(match => match.item);
  }
}

export class PrefixSearch implements SuggestionStrategy {
  suggest(input: string, candidates: TagDTO[], suggestionSize: number): TagDTO[] {
    const normalizedInput = normalizeTagName(input);
    const matched = candidates.filter(tag => tag.normalizedName.startsWith(normalizedInput));
    return matched.sort((a, b) => a.normalizedName.length - b.normalizedName.length).slice(0, suggestionSize);
  }
}

export class CandidateTags {
  private readonly tagById: Map<string, TagDTO>;

  constructor(
    private readonly list: TagDTO[],
    private readonly options: {
      suggestionSize: number;
      strategy: SuggestionStrategy;
    },
  ) {
    this.tagById = new Map(list.map(tag => [tag.id, tag]));
  }

  suggestionSize(): number {
    return this.options.suggestionSize;
  }

  getTag(id: string): TagDTO | undefined {
    return this.tagById.get(id);
  }

  suggest(input: string, excludeIds: Set<string> = new Set([])): TagDTO[] {
    const candidates = this.list.filter(({ id }) => !excludeIds.has(id));
    return this.options.strategy.suggest(input, candidates, this.suggestionSize());
  }

  isEmpty(): boolean {
    return this.list.length === 0;
  }
}
