import { Checkbox } from '@contentful/forma-36-react-components';
import { useEntriesSelection } from 'app/scenes/ui-extension/NewsSelection/entries-selection';
import { ColumnDefinition, EntryTable } from 'app/shared/EntryTable';
import { ContentfulEntry } from 'contentful-management';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

export function SelectableEntryTable<T extends ContentfulEntry>(
  params: PropsWithChildren<{
    entries: T[];
    columnDefinitions: ColumnDefinition<T>[];
    renderSelectionControl: (params: { selectedEntries: T[] }) => ReactNode;
  }>,
): ReactElement | null {
  const { entries, columnDefinitions, renderSelectionControl } = params;
  const {
    selectedEntries,
    toggleAllEntries,
    toggleSelectedEntryId,
    isAllEntriesSelected,
    isEntrySelected,
  } = useEntriesSelection<T>(entries);

  return (
    <EntryTable
      entries={entries}
      columnDefinitions={[
        {
          header: <Checkbox labelText={'Check'} onChange={toggleAllEntries} checked={isAllEntriesSelected} />,
          value: ({ entry }) => (
            <Checkbox
              labelText={'Selected'}
              onChange={event => {
                event.stopPropagation();
                toggleSelectedEntryId(entry.sys.id);
              }}
              checked={isEntrySelected(entry.sys.id)}
            />
          ),
        },
        ...columnDefinitions,
      ]}
      additionalHeader={0 < selectedEntries.length ? renderSelectionControl({ selectedEntries }) : undefined}
    />
  );
}
