import { Option, Select } from '@contentful/forma-36-react-components';
import { useErrorReporter } from '@mdlinx/frontend-shared';
import { User } from 'contentful-ui-extensions-sdk';
import { useSidebarExtensionSDK } from 'lib/contentful-extension';
import React, { useEffect, useState } from 'react';

export const AssigneeField = () => {
  const sdk = useSidebarExtensionSDK();
  const errorReporter = useErrorReporter();
  const [users, setUsers] = useState([] as User[]);
  const [selectedUserId, setSelectedUserId] = useState('' as User['sys']['id']);

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk]);

  useEffect(() => {
    sdk.space.getUsers().then(({ items }) => setUsers(items as User[]), errorReporter.report);
  }, [sdk, errorReporter]);

  useEffect(() => {
    const cleanup = (sdk.entry.fields.assigneeUserId.onValueChanged as any)((userId: string | undefined) => {
      setSelectedUserId(userId ?? '');
    });
    return () => cleanup();
  }, [sdk, errorReporter]);

  const handleSelectUser = (event: any) => {
    sdk.entry.fields.assigneeUserId.setValue(event.target.value);
  };

  return (
    <Select onChange={handleSelectUser} value={selectedUserId}>
      <Option value=""> No User Assigned</Option>
      {users.map(user => (
        <Option key={user.sys.id} value={user.sys.id}>{`${user.firstName} ${user.lastName}`}</Option>
      ))}
    </Select>
  );
};
