import {
  Button,
  Note,
  Option,
  Select,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextLink,
} from '@contentful/forma-36-react-components';
import Workbench from '@contentful/forma-36-react-components/dist/components/Workbench/Workbench';
import { extractDataFromResponse, useErrorReporter } from '@mdlinx/frontend-shared';
import {
  CustomStaffRole,
  SlackUser,
  StaffMembersQuery,
  UpdateStaffMemberResult,
  useDeleteStaffMemberMutation,
  useSlackUsersQuery,
  useStaffMembersQuery,
  useUpdateStaffMemberMutation,
} from 'generated/graphql-client-query';
import { usePageExtensionSDK } from 'lib/contentful-extension';
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Box } from 'rebass';

type StaffMembers = StaffMembersQuery['staffMembers'];
type Staff = StaffMembers extends Array<infer U> ? U : never;

const Loading: React.FC = () => <Spinner />;

interface StaffMembersContext {
  list: StaffMembers;
  loading: boolean;

  reload(): Promise<void>;
}
const StaffMembersContext = createContext<StaffMembersContext>({
  list: [],
  loading: false,

  async reload() {
    // Do nothing by default.
  },
});

function useStaffMembersContext(): StaffMembersContext {
  return useContext(StaffMembersContext);
}

const StaffMembersContextProvider = ({ children }: PropsWithChildren) => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const { data, loading, error, refetch } = useStaffMembersQuery();

  useEffect(() => {
    if (error) {
      errorReporter.reportApolloError(error);
      sdk.notifier.error(error.message);
    }
  }, [error, errorReporter, sdk]);

  const context = useMemo<StaffMembersContext>(() => {
    return {
      list: (data && data.staffMembers) || [],
      loading: loading,
      async reload() {
        await refetch();
      },
    };
  }, [data, loading, refetch]);

  return <StaffMembersContext.Provider value={context}>{children}</StaffMembersContext.Provider>;
};

interface SlackUsersContext {
  list: SlackUser[];
  loading: boolean;
}
const SlackUsersContext = createContext<SlackUsersContext>({
  list: [],
  loading: false,
});

const SlackUsersContextProvider = ({ children }: PropsWithChildren) => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const { data, loading, error } = useSlackUsersQuery();

  useEffect(() => {
    if (error) {
      errorReporter.reportApolloError(error);
      sdk.notifier.error(error.message);
    }
  }, [error, errorReporter, sdk]);

  const context = useMemo<SlackUsersContext>(() => {
    return {
      list: (data && data.slackUsers) || [],
      loading: loading,
    };
  }, [data, loading]);

  return <SlackUsersContext.Provider value={context}>{children}</SlackUsersContext.Provider>;
};

function useSlackUsersContext(): SlackUsersContext {
  return useContext(SlackUsersContext);
}

export const StaffMembers: React.FC = () => {
  return (
    <StaffMembersContextProvider>
      <SlackUsersContextProvider>
        <Workbench>
          <Workbench.Header title="Staff Members and Roles" />
          <Workbench.Content>
            <Box mb={3}>
              <RoleAndPermissionNote />
            </Box>
            <StaffMembersTableContainer />
          </Workbench.Content>
        </Workbench>
      </SlackUsersContextProvider>
    </StaffMembersContextProvider>
  );
};

const RoleAndPermissionNote: React.FC = () => (
  <Note>
    See{' '}
    <TextLink
      href="https://confluence.mdlinx.com/pages/viewpage.action?pageId=54035419"
      {...{ rel: 'noopener norefererer', target: '_blank' }}
    >
      User Roles and Permissions
    </TextLink>{' '}
    for more details about roles.
  </Note>
);

const StaffMembersTableContainer: React.FC = () => {
  const staffMembersContext = useStaffMembersContext();

  return staffMembersContext.list.length > 0 ? (
    <StaffMembersTable staffs={staffMembersContext.list} />
  ) : staffMembersContext.loading ? (
    <Loading />
  ) : (
    <StaffMembersTable staffs={[]} />
  );
};

const StaffMembersTable: React.FC<{
  staffs: StaffMembers;
}> = ({ staffs }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Custom Role</TableCell>
          <TableCell>Slack User</TableCell>
          <TableCell>UID</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {staffs.map(staff => (
          <StaffRow staff={staff} key={staff.uid} />
        ))}
      </TableBody>
    </Table>
  );
};

const customRoles: CustomStaffRole[] = [
  CustomStaffRole.Admin,
  CustomStaffRole.Taxonomist,
  CustomStaffRole.Editor,
  CustomStaffRole.IndiaTeamWriter,
  CustomStaffRole.Guest,
];

const StaffRow: React.FC<{
  staff: Staff;
}> = ({ staff }) => {
  const [editing, setEditing] = useState<boolean>(false);
  return editing ? (
    <EditingStaffRow staff={staff} onFinish={() => setEditing(false)} />
  ) : (
    <ReadonlyStaffRow staff={staff} onEdit={() => setEditing(true)} />
  );
};

const ReadonlyStaffRow: React.FC<{ staff: Staff; onEdit: () => void }> = ({ staff, onEdit }) => {
  const sdk = usePageExtensionSDK();
  const [deleteStaff] = useDeleteStaffMemberMutation();
  const staffMembersContext = useContext(StaffMembersContext);

  const handleDelete = async () => {
    try {
      const result = await sdk.dialogs.openConfirm({
        title: 'Are you sure?',
        message: `Do you really want to delete entry for ${staff.firstName} ${staff.lastName}?`,
      });
      if (!result) {
        return;
      }
      await deleteStaff({ variables: { uid: staff.uid } }).then(extractDataFromResponse);
      await staffMembersContext.reload();
    } catch (e) {
      sdk.notifier.error('System error. Try again later.');
    }
  };

  return (
    <TableRow key={staff.uid}>
      <TableCell>
        {staff.firstName} {staff.lastName}
      </TableCell>
      <TableCell>{staff.email}</TableCell>
      <TableCell>{staff.role}</TableCell>
      <TableCell>{staff.customRole}</TableCell>
      <TableCell>{staff.slackUser && staff.slackUser.name}</TableCell>
      <TableCell>{staff.uid}</TableCell>
      <TableCell>
        <Button onClick={onEdit} size="small" buttonType="muted">
          Edit
        </Button>

        <Button size="small" buttonType="negative" onClick={handleDelete}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

const EditingStaffRow: React.FC<{ staff: Staff; onFinish: () => void }> = ({ staff, onFinish }) => {
  const sdk = usePageExtensionSDK();
  const [editingMember, setEditingMember] = useState(staff);
  const [saving, setSaving] = useState<boolean>(false);
  const [updateStaffMember] = useUpdateStaffMemberMutation();
  const staffMembersContext = useContext(StaffMembersContext);
  const slackUsersContext = useSlackUsersContext();

  const handleUpdateStaff = async () => {
    try {
      setSaving(true);
      const result = await updateStaffMember({
        variables: {
          input: {
            uid: editingMember.uid,
            customRole: editingMember.customRole,
            slackUserId: editingMember.slackUser ? editingMember.slackUser.id : null,
          },
        },
      }).then(extractDataFromResponse);

      switch (result.updateStaffMember) {
        case UpdateStaffMemberResult.Succeeded: {
          await staffMembersContext.reload();
          sdk.notifier.success(`Staff is successfully updated.`);
          onFinish();
          return;
        }
        case UpdateStaffMemberResult.StaffMemberNotFound: {
          sdk.notifier.error(`Staff cannot be found.`);
          return;
        }
      }
    } catch (e) {
      sdk.notifier.error(`System error. Try again later.`);
    } finally {
      setSaving(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        {editingMember.firstName} {editingMember.lastName}
      </TableCell>
      <TableCell>{editingMember.email}</TableCell>
      <TableCell>{editingMember.role}</TableCell>
      <TableCell>
        <Select
          value={editingMember.customRole}
          onChange={e => {
            setEditingMember({
              ...editingMember,
              customRole: (e.target as any).value,
            });
          }}
        >
          {customRoles.map(role => (
            <Option key={role} value={role}>
              {role}
            </Option>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          value={editingMember.slackUser ? editingMember.slackUser.id : undefined}
          onChange={e => {
            const selectedId = (e.target as any).value;
            const slackUser = slackUsersContext.list.find(slackUser => slackUser.id === selectedId) || null;
            setEditingMember({
              ...editingMember,
              slackUser,
            });
          }}
        >
          <Option value={''}>&nbsp;</Option>
          {slackUsersContext.list.map(slackUser => (
            <Option key={slackUser.id} value={slackUser.id}>
              {slackUser.name}
            </Option>
          ))}
        </Select>
      </TableCell>
      <TableCell>{editingMember.uid}</TableCell>
      <TableCell>
        <Button size="small" buttonType="positive" onClick={handleUpdateStaff} disabled={saving}>
          Save
        </Button>

        <Button onClick={onFinish} size="small" buttonType="muted" disabled={saving}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};
