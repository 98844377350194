import { useErrorReporter } from '@mdlinx/frontend-shared';
import { ContentfulEntry } from 'contentful-management';
import { findAllEntries, findAllPublishedEntries, usePageExtensionSDK } from 'lib/contentful-extension';
import { SpecialtyDef } from 'mdlinx-contentful';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

type SpecialtyEntry = ContentfulEntry<SpecialtyDef>;

const SpecialtiesContext = createContext<SpecialtyEntry[]>([]);

function isSubSpecialty(specialty: SpecialtyEntry): boolean {
  return !!specialty.fields.parent;
}

export const SpecialtiesProvider = ({ children }: PropsWithChildren) => {
  const sdk = usePageExtensionSDK();
  const errorReporter = useErrorReporter();
  const [specialties, setSpecialties] = useState<SpecialtyEntry[]>([]);
  useEffect(() => {
    findAllPublishedEntries<SpecialtyEntry>(sdk, { content_type: 'specialty' })
      .then(specialties => {
        const rootSpecialties = specialties.filter(specialty => !isSubSpecialty(specialty));
        setSpecialties(rootSpecialties);
      })
      .catch(errorReporter.report);
  }, [sdk, errorReporter]);

  return <SpecialtiesContext.Provider value={specialties}>{children}</SpecialtiesContext.Provider>;
};

export function useSpecialties(): SpecialtyEntry[] {
  return useContext(SpecialtiesContext);
}
