import { TextLink } from '@contentful/forma-36-react-components';
import React from 'react';

export const EntryPriorityIcon: React.FC<{
  isHighPriority: boolean;
  disabled: boolean;
  onToggle: () => void;
}> = ({ isHighPriority, onToggle, disabled }) => {
  return (
    <>
      <TextLink
        linkType={isHighPriority ? 'positive' : 'muted'}
        onClick={onToggle}
        disabled={disabled}
        text={isHighPriority ? '★' : '☆'}
        {...{
          style: {
            fontSize: '1.1rem',
            textDecoration: 'none',
          },
        }}
      />
    </>
  );
};
