import { ContentfulEntry } from 'contentful-management';
import { DateTime } from 'luxon';

export function hasPendingChanges(sys: ContentfulEntry['sys']): boolean {
  if (!sys.publishedAt) {
    return false;
  }

  // when publishing or unpublishing the onSysChanged callback timestamps are off by a few milliseconds
  // so we need to tolerate milliseconds diff.
  const publishedAt = DateTime.fromISO(sys.publishedAt);
  const updatedAt = DateTime.fromISO(sys.updatedAt);
  return updatedAt > publishedAt.plus({ second: 1 });
}

export const DATE_TIME_FORMAT_FOR_TABLE_COLUMN = 'D hh:mm';
